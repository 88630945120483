import appInfo from '../../../package.json'
import { migrations } from './migrations'
import compareVersions from 'compare-versions'
import { WorkspaceData } from '../redux/slice/workspaceSlice'

export class Migrate {
  static appVersion() {
    // return '0.1.6'
    return appInfo.version
  }

  static dataVersion(data: WorkspaceData) {
    return data.appVersion || '0'
  }

  static getAppVersionChange(data: WorkspaceData) {
    const currentVersion = this.appVersion()
    const dataVersion = this.dataVersion(data)
    const change = compareVersions(currentVersion, dataVersion)
    return change
  }

  static pendingMigrations(data: any) {
    const migrationKeys = Object.keys(migrations) as Array<keyof typeof migrations>
    return migrationKeys.filter(migrationVersion => {
      const dataVersion = this.dataVersion(data)
      const change = compareVersions(dataVersion, migrationVersion)
      const migrate = change < 0
      if (migrate) console.info('pending migration', { from: dataVersion, to: migrationVersion })
      return migrate
    })
  }

  static migrateData(data: any) {
    const migrationKeys = this.pendingMigrations(data)
    let data2 = data
    migrationKeys.forEach(migrationVersion => {
      data2 = migrations[migrationVersion](data2)
    })
    return data2
  }
}