import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../store"
import { saveLocal } from "./workspaceSlice"
import { ItemKey, moveNode } from "./troveSlice"
import { ItemType } from "../types/ItemType"
import { ItemDropResult } from "../../types/ItemDropResult"
import { PlanKey } from "../types/PlanType"
import { movePlanItemsSave, moveItemSave, setActivePlan } from "./planSlice"
import { batch } from "react-redux"
import ReactGA from 'react-ga'

type ItemsObject = { [key: string]: ItemType | undefined }

const initialState: {
  items: ItemsObject
} = {
  items: {},
}

type ItemUpdates = {
  text?: ItemType['text']
  // troved?: ItemType['troved']
}

type UpdateItemProps = {
  key: ItemKey,
  updates: ItemUpdates,
  attributes: (keyof ItemType)[],
}
type SetItemsProps = { items?: ItemsObject }
type AddItemsProps = { items?: ItemsObject, force?: boolean }
type DeleteItemProps = { key: ItemKey, force?: boolean }

const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setItems(state, action: PayloadAction<SetItemsProps>) {
      state.items = action.payload.items || {}
    },
    // GOOD
    updateItem(state, action: PayloadAction<UpdateItemProps>) {
      const { key, updates, attributes } = action.payload
      const { text } = updates
      const item: ItemType = state.items[key] || {
        key,
        text: '',
        createdAt: Date.now(),
      }
      // if (!item) return
      if (attributes.includes('text')) item.text = (text || '').trim()
      // if (attributes.includes('troved')) item.troved = troved
      const updatedAt = Date.now()
      item.updatedAt = updatedAt
      state.items[key] = item
    },
    deleteItem(state, action: PayloadAction<DeleteItemProps>) {
      const { key } = action.payload
      const item = state.items[key]
      if (!item) return
      item.deletedAt = Date.now()
      item.updatedAt = item.deletedAt
      state.items[key] = item
    },
    addItems(state, action: PayloadAction<AddItemsProps>) {
      const { items = {}, force } = action.payload
      const itemKeys = Object.keys(items) as Array<keyof typeof items>
      const itemsIntersections = getItemIntersections(state.items, items)
      if (force || !itemsIntersections.length) {
        itemKeys.forEach(key => {
          state.items[key] = items[key]
        })
      } else {
        throw Error('Duplicate items found, cannot add items. Maybe you are trying to add a document that already exists in your trove.')
      }
    }
  }
})

export const {
  setItems,
  addItems,
} = itemSlice.actions

export default itemSlice.reducer

// Save wrappers

export const updateItemSave = (props: UpdateItemProps): AppThunk => (dispatch, state) => {
  dispatch(itemSlice.actions.updateItem(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Item',
    action: 'updateItem'
  })
}

export const deleteItemSave = (props: DeleteItemProps): AppThunk => (dispatch, state) => {
  dispatch(itemSlice.actions.deleteItem(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Item',
    action: 'deleteItem'
  })
}

export const dropItem = ({
  dropResult, source, planKey
}: {
  dropResult: ItemDropResult
  source: ItemKey
  planKey?: PlanKey
}): AppThunk => (dispatch) => {
  if (dropResult.toPlanKey) {
    // dropping on a plan
    const from = dropResult.dropEffect !== 'copy'
      ? dropResult.fromPlanKey
      : undefined
    const to = dropResult.toPlanKey
    batch(() => {
      dispatch(movePlanItemsSave({ from, to, itemKeys: [source] }))
      dispatch(setActivePlan({ key: to, itemKey: source }))
    })
  } else {
    // dropping on anoter item
    const target = dropResult.target
    if (!target) return
    if (dropResult.area === 'trove') {
      // dropping in a trove
      const isDropChild = dropResult.isDropChild
      dispatch(moveNode(source, target, { child: isDropChild }))
    } else if (dropResult.area === 'plan' && planKey) {
      // dropping on an item in same checklist, move it
      dispatch(moveItemSave({ key: planKey, target, source }))
    }
  }
}

export const getItemIntersections = (items: ItemsObject, newItems: ItemsObject) => {
  const itemKeys = Object.keys(items) as Array<keyof typeof items>
  const newItemKeys = Object.keys(newItems) as Array<keyof typeof newItems>
  const itemsIntersections = itemKeys.filter(key => newItemKeys.includes(key))
  return itemsIntersections
}


