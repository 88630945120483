import classnames from 'classnames'
import moment from 'moment'
import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconProps, List, Ref } from 'semantic-ui-react'
import { TROVE_FILTER_COLORS, TROVE_FILTER_ICONS } from './data'
import { getNodeByKey, selectTree } from './redux/selector/troveSelector'
import { scopeParentNode } from './redux/slice/troveSlice'
import { ItemType } from './redux/types/ItemType'
import TroveItemContent from './TroveItemContent'
import { useDrag } from 'react-dnd'
import { TroveItemDragType } from './types/TroveItemDragType'
import { ItemDropResult } from './types/ItemDropResult'
import { dropItem } from './redux/slice/itemSlice'

type Props = {
  item: ItemType
}

const ItemRow = ({ item }: Props) => {
  const dispatch = useDispatch()
  const tree = useSelector(selectTree)
  const troveNode = useMemo(() =>
    getNodeByKey(tree, item.key),
    [tree, item.key])
  const troved = useMemo(() => !!troveNode, [troveNode])
  const ref = useRef<HTMLElement>(null)

  const dragItem: TroveItemDragType = useMemo(() => ({
    type: 'TROVE_ITEM',
    key: item.key,
  }), [item.key])

  // drag drop stuff
  const [{ dragging }, drag] = useDrag({
    item: dragItem,
    end: (item: TroveItemDragType | undefined, monitor) => {
      if (!item) return
      const dropResult: ItemDropResult | undefined = monitor.getDropResult()
      if (!dropResult) return
      dispatch(dropItem({
        dropResult,
        source: item.key,
      }))
    },
    collect: monitor => ({
      dragging: !!monitor.isDragging(),
      // dragOffset: monitor.getDifferenceFromInitialOffset()
    }),
  })

  drag(ref)
  // const parents = useMemo(() =>
  //   getParentNodes(tree, item.key)?.reverse(),
  //   [tree, item.key])


  const handleScope = useCallback((event?: React.MouseEvent) => {
    event?.stopPropagation()
    dispatch(scopeParentNode(item.key))
  }, [dispatch, item.key])


  return (
    <Ref innerRef={ref}>
      <List.Item
        active={dragging}
        className={classnames('float-item', {
          'troved': troved,
          'dragging': dragging,
        })}
        onClick={handleScope}
      >
        <List.Content>
          <List.Header>
            {troveNode?.funnel !== undefined || troveNode?.archived
              ? <Icon
                name={troveNode.archived
                  ? 'archive'
                  : TROVE_FILTER_ICONS[troveNode.funnel || 0]}
                color={troveNode.archived
                  ? 'grey'
                  : TROVE_FILTER_COLORS[troveNode.funnel || 0] as IconProps['color']}
              /> : undefined
            }
            <TroveItemContent
              item={item}
              level={0}
              archived={troveNode?.archived}
            />
          </List.Header>
          {item.updatedAt ? <List.Description>
            {moment(item.updatedAt).fromNow()}
          </List.Description> : undefined}
          {/* {parents?.length
            ? <List.Description style={{ marginTop: '0.4em' }}>
              <TroveItemCrumbs parents={parents} />
            </List.Description>
            : !troved
              ? <List.Description style={{ marginTop: '0.4em' }}>
                Not Troved
              </List.Description>
              : undefined} */}
        </List.Content>
      </List.Item>
    </Ref>
  )
}

export default ItemRow