import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ButtonProps, Icon, IconProps } from 'semantic-ui-react'
import { TROVE_FILTER_ICONS } from './data'
import { incrementFunnelForItem, setActiveNode } from './redux/slice/troveSlice'
import { TroveType } from "./redux/types/TroveType"


type Props = {
  node: TroveType
  // basic?: boolean
  // active?: boolean
  buttons?: boolean
  funnelColor?: ButtonProps['color']
}

const TroveItemFunnelButton = React.memo(({ node, funnelColor }: Props) => {
  const dispatch = useDispatch()

  const handleFunnel = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(setActiveNode(node.key))
    dispatch(incrementFunnelForItem(node.key))
  }, [dispatch, node.key])

  const handleClear = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(incrementFunnelForItem(node.key, { index: -1 }))
  }, [dispatch, node.key])

  return <>
    <Button.Group
      size='tiny'
      compact
      basic
    // color={'teal'}
    >
      <Button
        title='Remove Filter'
        compact
        icon='times'
        onClick={handleClear}
      />
      <Button
        title='Increase Filter'
        active={node.funnel !== undefined}
        compact
        icon
        onClick={handleFunnel}
      >
        <Icon
          name={node.funnel !== undefined ? TROVE_FILTER_ICONS[node.funnel] || 'star outline' : 'star outline'}
          color={funnelColor as IconProps['color']}
        // color={tree.funnel !== undefined ? 'yellow' : undefined}
        />
      </Button>
    </Button.Group>
  </>
})

export default TroveItemFunnelButton