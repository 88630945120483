import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDispatch, useSelector } from 'react-redux'
import { selectActiveArea } from './redux/selector/workspaceSelector'
import { addNodeToActive, archiveActiveNode, deleteActiveItem, foldActiveNode, incrementFunnelActiveItem, scopeActiveNode, setActiveNode, setNextNode, setNextNodeFunnel, toggleEditing, setFilterFunnel } from './redux/slice/troveSlice'
import { setActiveArea, setModal } from './redux/slice/workspaceSlice'
import { KEY_MAP } from './shortcuts'
import { confirmDialog } from './util'
// https://github.com/JohannesKlauss/react-hotkeys-hook#readme

const ShortcutsWrapper = React.memo(() => {
  const dispatch = useDispatch()
  const activeArea = useSelector(selectActiveArea)

  const handleAdd = (keyEvent: KeyboardEvent, { child = false } = {}) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    switch (activeArea) {
      case 'trove':
        dispatch(addNodeToActive({ child }))
        break
    }
  }

  const handleMoveActive = (
    keyEvent: KeyboardEvent,
    { direction }: { direction: -1 | 1 } = { direction: 1 },
  ) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    if (!activeArea || activeArea === 'trove') {
      dispatch(setNextNode(direction))
    } else if (activeArea === 'funnel') {
      dispatch(setNextNodeFunnel(direction))
    }
  }

  const handleLateral = (
    keyEvent?: KeyboardEvent,
    { fold }: { fold?: boolean } = {}
  ) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    if (activeArea === 'trove') {
      dispatch(foldActiveNode({ fold }))
    }
  }

  const handleFold = (keyEvent?: KeyboardEvent) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    const recursive = keyEvent.altKey
    if (activeArea === 'trove') {
      dispatch(foldActiveNode({ recursive }))
    }
  }

  const handleEdit = (keyEvent?: KeyboardEvent) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    switch (activeArea) {
      case 'trove':
        dispatch(toggleEditing(true))
        break
      case 'funnel':
        dispatch(scopeActiveNode({ parent: true }))
        dispatch(setFilterFunnel(undefined))
        break
    }
  }

  const handleDelete = (keyEvent?: KeyboardEvent) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    confirmDialog({
      message: 'Are you sure you want to delete this item?\n\nWarning: All items and archived items under this item will also be deleted. You cannot undo this.',
      onConfirm: () => {
        switch (activeArea) {
          case 'trove':
            dispatch(deleteActiveItem())
            break
        }
      },
    })
  }

  const handleArchive = (keyEvent?: KeyboardEvent) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    if (activeArea === 'trove' || activeArea === 'funnel') {
      dispatch(archiveActiveNode({ recursive: true }))
    }
  }

  const handleFunnel = (
    keyEvent?: KeyboardEvent,
    // { recursive }: { recursive: boolean } = { recursive: false }
  ) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    const recursive = keyEvent.altKey
    if (activeArea === 'trove' || activeArea === 'funnel') {
      const index = keyEvent.code === 'Digit0' ? -1
        : keyEvent.code === 'Digit1' ? 0
          : keyEvent.code === 'Digit2' ? 1
            : keyEvent.code === 'Digit3' ? 2
              : undefined
      dispatch(incrementFunnelActiveItem({ recursive, index }))
    }
  }

  const handleScope = (
    keyEvent?: KeyboardEvent,
    { parent = false } = {}
  ) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    if (activeArea === 'trove' || activeArea === 'funnel') {
      const scoped = activeArea === 'trove' && parent
      dispatch(scopeActiveNode({ parent, scoped }))
      dispatch(setFilterFunnel(undefined))
    }
  }

  const handleFind = (keyEvent?: KeyboardEvent) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    dispatch(setActiveArea())
    dispatch(setActiveArea('find'))
  }

  const handleEscape = (keyEvent?: KeyboardEvent) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    if (activeArea === 'trove' || activeArea === 'funnel') {
      dispatch(setActiveNode())
    }
  }

  const handleHelp = (keyEvent?: KeyboardEvent) => {
    if (!keyEvent) return
    keyEvent.preventDefault()
    dispatch(setModal('shortcuts'))
  }

  useHotkeys(KEY_MAP.ADD, e => handleAdd(e))
  useHotkeys(KEY_MAP.ADD_CHILD, e => handleAdd(e, { child: true }))
  useHotkeys(KEY_MAP.NAV_DOWN, e => handleMoveActive(e))
  useHotkeys(KEY_MAP.NAV_UP, e => handleMoveActive(e, { direction: -1 }))
  useHotkeys(KEY_MAP.FOLD, e => handleLateral(e, { fold: true }))
  useHotkeys(KEY_MAP.UNFOLD, e => handleLateral(e, { fold: false }))
  useHotkeys(KEY_MAP.FOLD_TOGGLE, e => handleFold(e))
  useHotkeys(KEY_MAP.EDIT, e => handleEdit(e))
  useHotkeys(KEY_MAP.DELETE, e => handleDelete(e))
  useHotkeys(KEY_MAP.ARCHIVE, e => handleArchive(e))
  useHotkeys(KEY_MAP.FUNNEL, e => handleFunnel(e))
  useHotkeys(KEY_MAP.SCOPE, e => handleScope(e))
  useHotkeys(KEY_MAP.SCOPE_PARENT, e => handleScope(e, { parent: true }))
  useHotkeys(KEY_MAP.FIND, e => handleFind(e))
  useHotkeys(KEY_MAP.ESCAPE, e => handleEscape(e))
  useHotkeys(KEY_MAP.HELP, e => handleHelp(e))
  // useHotkeys(KEY_MAP._IGNORE, () => {})

  return null
  // return <>
  //   <Dimmer active>Hi</Dimmer>
  // </>
})

export default ShortcutsWrapper