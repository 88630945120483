import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { TROVE_FILTER_TYPES } from "../../data"
import { Migrate } from "../../migrate/migrate"
import { selectActiveItemKey, getNodeByKey, getParentFromKey, getFlatTree, selectFunneledTree, selectScopedTree, selectShowArchived, getSubCount, selectFilterFunnel, selectScopeKey, selectTree } from "../selector/troveSelector"
import { AppThunk } from "../store"
import { ItemType } from "../types/ItemType"
import { TroveType } from "../types/TroveType"
import { addItems, updateItemSave, deleteItemSave } from "./itemSlice"
import { saveLocal, setActiveArea, setSaved, WorkspaceData } from "./workspaceSlice"
import { toast } from "react-toastify"
import { selectItems } from "../selector/itemSelector"
import { getKey } from "../../util"
import ReactGA from 'react-ga'

export type ItemKey = string

const initialState: {
  activeItemKey?: ItemKey // scoped by workspace
  scopeKey?: ItemKey // scoped by workspace
  sideKey?: ItemKey
  tree: TroveType // scoped by workspace
  editing: boolean
  showArchived: boolean
  filterFunnel?: number
  searchTerm?: string
  quickSearchTerm?: string,
} = {
  activeItemKey: undefined,
  scopeKey: undefined,
  sideKey: undefined,
  tree: { key: getKey(), sub: [] },
  // items: {},
  editing: false,
  showArchived: false,
}

type SetActiveItemProps = ItemKey | undefined
type ToggleFoldProps = { itemKeys: ItemKey[], fold?: boolean }
type ToggleArchiveProps = { itemKeys: ItemKey[], archive?: boolean }
type IncrementFunnelProps = { itemKeys: ItemKey[], funnel?: number }
type DeleteItemProps = { itemKeys: ItemKey[] }
type AddChildItemProps = { parentKey: ItemKey, node: TroveType }
type MoveItemProps = { source: ItemKey, target: ItemKey, child?: boolean }

const troveSlice = createSlice({
  name: 'trove',
  initialState,
  reducers: {
    setTrove(state, action: PayloadAction<{
      tree?: TroveType,
    }>) {
      state.tree = action.payload.tree || initialState.tree
    },
    setActiveNode(state, action: PayloadAction<SetActiveItemProps>) {
      state.activeItemKey = action.payload === state.tree.key ? undefined : action.payload
    },
    clearWorkspace(state, action: PayloadAction) {
      state.activeItemKey = initialState.activeItemKey
      state.scopeKey = initialState.scopeKey
      state.sideKey = initialState.sideKey
      state.filterFunnel = initialState.filterFunnel
      state.editing = initialState.editing
    },
    setScopeKey(state, action: PayloadAction<ItemKey | undefined>) {
      state.scopeKey = action.payload
      if (action.payload) state.activeItemKey = action.payload
      state.filterFunnel = undefined
    },
    setSideKey(state, action: PayloadAction<ItemKey | undefined>) {
      state.sideKey = action.payload
    },
    // GOOD
    toggleFold(state, action: PayloadAction<ToggleFoldProps>) {
      const { itemKeys, fold } = action.payload
      itemKeys.forEach(itemKey => {
        const item = getNodeByKey(state.tree, itemKey)
        if (!item) return
        item.folded = fold === undefined ? !item.folded : fold
      })
    },
    // GOOD
    toggleArchive(state, action: PayloadAction<ToggleArchiveProps>) {
      const { itemKeys, archive } = action.payload
      itemKeys.forEach(itemKey => {
        const node = getNodeByKey(state.tree, itemKey)
        if (!node) return
        node.archived = archive === undefined ? !node.archived : archive
        node.updatedAt = Date.now()
      })
    },
    // GOOD
    incrementFunnel(state, action: PayloadAction<IncrementFunnelProps>) {
      const { itemKeys, funnel } = action.payload
      itemKeys.forEach(itemKey => {
        const node = getNodeByKey(state.tree, itemKey)
        if (!node) return
        if (node.archived) return
        node.funnel = funnel
        node.updatedAt = Date.now()
      })
    },
    // GOOD
    deleteNode(state, action: PayloadAction<DeleteItemProps>) {
      const { itemKeys } = action.payload
      itemKeys.forEach(itemKey => deleteNodeHelper(state.tree, itemKey))
    },
    // GOOD
    addChildNode(state, action: PayloadAction<AddChildItemProps>) {
      const { parentKey, node } = action.payload
      const treeIntersections = getTreeIntersections(state.tree, node)
      if (treeIntersections.length) {
        throw Error('Duplicate troves found. Maybe you are trying to add a document that already exists in your trove.')
      } else {
        const parent = getNodeByKey(state.tree, parentKey)
        if (!parent) return
        node.createdAt = Date.now()
        node.updatedAt = node.createdAt
        if (!parent.sub) parent.sub = []
        parent.sub.push(node)
      }
    },
    // GOOD
    moveNode(state, action: PayloadAction<MoveItemProps>) {
      const { source, target, child = false } = action.payload
      const targetParent = child
        ? getNodeByKey(state.tree, target)
        : getParentFromKey(state.tree, target)
      if (!targetParent) return
      if (!targetParent.sub) targetParent.sub = []
      let sourceItem = getNodeByKey(state.tree, source)
      if (sourceItem) {
        deleteNodeHelper(state.tree, source)
      } else {
        sourceItem = { key: source }
      }
      if (child) {
        targetParent.sub.push(sourceItem)
      } else {
        const targetIndex = targetParent.sub.findIndex(i => i.key === target)
        targetParent.sub.splice(targetIndex, 0, sourceItem)
      }
    },
    toggleEditing(state, action: PayloadAction<boolean | undefined>) {
      state.editing = action.payload === undefined ? !state.editing : action.payload
    },
    toggleShowArchived(state, action: PayloadAction) {
      state.showArchived = !state.showArchived
    },
    setFilterFunnel(state, action: PayloadAction<number | undefined>) {
      state.filterFunnel = action.payload
    },
    setSearch(state, action: PayloadAction<string | undefined>) {
      state.searchTerm = action.payload
    },
    setQuickSearch(state, action: PayloadAction<string | undefined>) {
      state.quickSearchTerm = action.payload
    },
  }
})

// HELPERS
function deleteNodeHelper(data: TroveType, itemKey: ItemKey) {
  const parent = getParentFromKey(data, itemKey)
  if (!parent?.sub) return
  const index = parent.sub.findIndex(i => i.key === itemKey)
  if (index > -1) parent.sub.splice(index, 1)
}

export const {
  clearWorkspace,
  setScopeKey,
  setSearch,
  setQuickSearch,
  setTrove,
  setSideKey,
  toggleEditing,
  toggleShowArchived,
  setFilterFunnel,
} = troveSlice.actions

export default troveSlice.reducer

// THUNK

// Save wrappers

const toggleFoldSave = (props: ToggleFoldProps): AppThunk => (dispatch, state) => {
  dispatch(troveSlice.actions.toggleFold(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Trove',
    action: 'toggleFold'
  })
}

const toggleArchiveSave = (props: ToggleArchiveProps): AppThunk => (dispatch, state) => {
  dispatch(troveSlice.actions.toggleArchive(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Trove',
    action: 'toggleArchive'
  })
}

const incrementFunnelSave = (props: IncrementFunnelProps): AppThunk => (dispatch, state) => {
  dispatch(troveSlice.actions.incrementFunnel(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Trove',
    action: 'incrementFunnel'
  })
}

const deleteNodeSave = (props: DeleteItemProps): AppThunk => (dispatch, state) => {
  dispatch(troveSlice.actions.deleteNode(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Trove',
    action: 'deleteNode'
  })
}

const addChildNodeSave = (props: AddChildItemProps): AppThunk => (dispatch, state) => {
  dispatch(troveSlice.actions.addChildNode(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Trove',
    action: 'addNode'
  })
}

const moveNodeSave = (props: MoveItemProps): AppThunk => (dispatch, state) => {
  dispatch(troveSlice.actions.moveNode(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Trove',
    action: 'moveNode'
  })
}

// GOOD
export const addChildNode = (itemKey: string, { text }: {
  text?: ItemType['text']
} = {}): AppThunk => (dispatch, getState) => {
  const filterFunnel = selectFilterFunnel(getState())
  const key = getKey()
  const newNode: TroveType = { key, funnel: filterFunnel }
  dispatch(addChildNodeSave({ parentKey: itemKey, node: newNode }))
  dispatch(foldNode(itemKey, { fold: false }))
  if (text) {
    dispatch(updateItemSave({
      key,
      updates: { text },
      attributes: ['text']
    }))
  } else {
    dispatch(setActiveNode(key))
    dispatch(toggleEditing(true))
  }
  // TODO next line should be 'temporary' fold in selector, instead of hard
}

// GOOD
export const addNodeToActive = (
  { child = false } = {}
): AppThunk => (dispatch, getState) => {
  const scopedTree = selectScopedTree(getState())
  const scopeKey = selectScopeKey(getState())
  let itemKey = selectActiveItemKey(getState())
  // check if active item is in scope, otherwise take root
  if (!itemKey || !getNodeByKey(scopedTree, itemKey) || itemKey === scopeKey) {
    itemKey = scopeKey || scopedTree.key
    child = true
  }
  if (!itemKey) return
  if (child) {
    dispatch(addChildNode(itemKey))
  } else {
    const parent = getParentFromKey(scopedTree, itemKey)
    if (!parent) return
    dispatch(addChildNode(parent.key))
  }
}

// GOOD
export const deleteNode = (itemKey: string): AppThunk => (dispatch, getState) => {
  dispatch(setNextNode(-1)) // dies this work?
  dispatch(deleteNodeSave({ itemKeys: [itemKey] }))
  dispatch(deleteItemSave({ key: itemKey }))
  dispatch(toggleEditing(false))
  const deletes = true
  const item = selectItems(getState())[itemKey]
  toast.info(`${deletes ? 'Deleted' : 'Restored'}: ${item ? item?.text : '?'}`, {
    className: 'ui message negative',
  })
}

// GOOD
export const moveNode = (
  sourceKey: ItemKey,
  targetKey: ItemKey,
  { child = false } = {},
): AppThunk => (dispatch) => {
  if (sourceKey === targetKey) return
  dispatch(moveNodeSave({ source: sourceKey, target: targetKey, child }))
  // mark item as troved if it comes from somewhere else
  // dispatch(updateItemSave({ key: sourceKey, updates: { troved: true }, attributes: ['troved'] }))
  // TODO this may be gone once temporary folding is done
  if (child) dispatch(foldNode(targetKey, { fold: false }))
  dispatch(setActiveNode(sourceKey))
  dispatch(toggleEditing(false))
}

// GOOD
export const scopeActiveNode = ({
  parent = false,
  scoped = false,
} = {}): AppThunk => (dispatch, getState) => {
  const tree = selectTree(getState())
  let itemKey = scoped
    ? selectScopeKey(getState())
    : selectActiveItemKey(getState())
  if (!itemKey) return
  if (parent) {
    const parent = getParentFromKey(tree, itemKey)
    itemKey = parent?.key || itemKey
  }
  dispatch(troveSlice.actions.setScopeKey(itemKey))
}

// GOOD
export const scopeParentNode = (itemKey: ItemKey): AppThunk => (dispatch, getState) => {
  if (!itemKey) return
  const tree = selectTree(getState())
  const parent = getParentFromKey(tree, itemKey)
  if (!parent) return
  dispatch(troveSlice.actions.setScopeKey(parent.key))
  dispatch(setActiveNode(itemKey))
}

// GOOD
export const deleteActiveItem = (): AppThunk => (dispatch, getState) => {
  const itemKey = selectActiveItemKey(getState())
  if (!itemKey) return
  dispatch(deleteNode(itemKey))
}

// GOOD
export const foldActiveNode = (
  options: { fold?: boolean, recursive?: boolean } = {}
): AppThunk => (dispatch, getState) => {
  const itemKey = selectActiveItemKey(getState())
  if (!itemKey) return
  dispatch(foldNode(itemKey, options))
}

export const foldSubNodes = (
  { fold }: { fold?: boolean } = {}
): AppThunk => (dispatch, getState) => {
  const scopedTree = selectScopedTree(getState())
  const itemKeys = scopedTree.sub?.map(sub => sub.key) || []
  dispatch(toggleEditing(false))
  dispatch(toggleFoldSave({ itemKeys, fold }))
}

// GOOD
export const foldNode = (
  itemKey: ItemKey,
  { fold, recursive = false }: { fold?: boolean, recursive?: boolean } = {}
): AppThunk => (dispatch, getState) => {
  const showArchived = selectShowArchived(getState())
  const scopedTree = selectScopedTree(getState())
  const node = getNodeByKey(scopedTree, itemKey)
  if (!node) return
  const subCount = getSubCount({ node, showArchived })
  if (fold === true && (!subCount || node.folded)) {
    dispatch(setParentNode())
    return
  } else if (fold === false && !node.folded) {
    dispatch(setFirstChildNode())
    return
  }
  if (!subCount) return
  fold = fold !== undefined ? fold : !node.folded
  const itemKeys = recursive
    ? getFlatTree(node).map(n => n.key)
    : [itemKey]
  dispatch(toggleEditing(false))
  dispatch(toggleFoldSave({ itemKeys, fold }))
}

// GOOD
export const archiveActiveNode = (
  options: { archive?: boolean, recursive?: boolean } = {}
): AppThunk => (dispatch, getState) => {
  const itemKey = selectActiveItemKey(getState())
  if (!itemKey) return
  dispatch(archiveItem(itemKey, options))
}

// GOOD
export const archiveItem = (
  itemKey: ItemKey,
  { archive, recursive = false }: { archive?: boolean, recursive?: boolean } = {}
): AppThunk => (dispatch, getState) => {
  const scopedTree = selectScopedTree(getState())
  const node = getNodeByKey(scopedTree, itemKey)
  if (!node) return
  if (archive === undefined) archive = !node.archived
  const itemKeys = recursive
    ? getFlatTree(node).map(n => n.key)
    : [itemKey]
  dispatch(toggleEditing(false))
  dispatch(toggleArchiveSave({ itemKeys, archive }))
  const item = selectItems(getState())[itemKey]
  toast.info(`${archive ? 'Archived' : 'Unarchived'}: ${item ? item?.text : '?'}`, {
    className: 'ui message info',
  })
}

type IncrementFunnelOptionsType = { recursive?: boolean, index?: number }
// GOOD
export const incrementFunnelActiveItem = (
  options: IncrementFunnelOptionsType = {}
): AppThunk => (dispatch, getState) => {
  const itemKey = selectActiveItemKey(getState())
  if (!itemKey) return
  dispatch(incrementFunnelForItem(itemKey, options))
}

export const incrementFunnelForItem = (
  itemKey: ItemKey,
  { recursive = false, index }: IncrementFunnelOptionsType = {}
): AppThunk => (dispatch, getState) => {
  const scopedTree = selectScopedTree(getState())
  const node = getNodeByKey(scopedTree, itemKey)
  if (!node) return
  let funnel: TroveType['funnel'] = index !== undefined ? index
    : node.funnel === undefined ? 0 : node.funnel + 1
  // funnel = funnel >= TROVE_FILTER_TYPES.length ? filterFunnel : funnel
  funnel = funnel >= TROVE_FILTER_TYPES.length ? undefined : funnel
  funnel = funnel === -1 ? undefined : funnel
  const itemKeys = recursive
    ? getFlatTree(node).map(n => n.key)
    : [itemKey]
  dispatch(incrementFunnelSave({ itemKeys, funnel }))
}

export const setActiveNode = (itemKey?: string): AppThunk => (dispatch) => {
  dispatch(troveSlice.actions.setActiveNode(itemKey))
  dispatch(troveSlice.actions.toggleEditing(false))
  if (itemKey) dispatch(setActiveArea('trove'))
}

export const setParentNode = (): AppThunk => (dispatch, getState) => {
  const { trove: { activeItemKey: itemKey } } = getState()
  if (!itemKey) return
  const scopedTree = selectScopedTree(getState())
  const parent = getParentFromKey(scopedTree, itemKey)
  // do not select parent if it's the current root
  if (scopedTree.key === parent?.key) return
  dispatch(setActiveNode(parent?.key || undefined))
}

export const setFirstChildNode = (): AppThunk => (dispatch, getState) => {
  const itemKey = selectActiveItemKey(getState())
  if (!itemKey) return
  const scopedTree = selectScopedTree(getState())
  const showArchived = selectShowArchived(getState())
  const node = getNodeByKey(scopedTree, itemKey)
  // do not select children
  const condition = (node: TroveType) => {
    return showArchived || !node.archived
  }
  const child = node?.sub?.find(condition)
  if (!child) return
  dispatch(setActiveNode(child.key))
}

export const setNextNode = (move: -1 | 1): AppThunk => (dispatch, getState) => {
  const itemKey = selectActiveItemKey(getState())
  const scopedTree = selectScopedTree(getState())
  const showArchived = selectShowArchived(getState())
  const condition = (node: TroveType, parent?: TroveType) => {
    return (showArchived || !node.archived || (node.archived && node.key === itemKey))
      && (parent?.key === scopedTree.key || !parent?.folded)
  }
  const flat = getFlatTree(scopedTree, [], condition, false)
  if (move === -1) flat.reverse()
  const currentIndex = flat.findIndex(node => node.key === itemKey)
  const nextIndex = flat.findIndex((node, index) => {
    return node.key !== scopedTree.key && index > currentIndex
  })
  const nextKey = flat[nextIndex]?.key
  dispatch(setActiveNode(nextKey))
}

export const setNextNodeFunnel = (move: -1 | 1): AppThunk => (dispatch, getState) => {
  const itemKey = selectActiveItemKey(getState())
  const nodes = selectFunneledTree(getState())
  if (move === -1) nodes.reverse()
  const currentIndex = nodes.findIndex(node => node.key === itemKey)
  const nextIndex = nodes.findIndex((node, index) => {
    return index > currentIndex
  })
  const nextKey = nodes[nextIndex]?.key
  dispatch(setActiveNode(nextKey))
}

// for importing purposes
export const getTreeIntersections = (
  tree: TroveType,
  newTree: TroveType
) => {
  function getItemKeys(node: TroveType, itemKeys: string[] = []) {
    itemKeys.push(node.key)
    node.sub?.forEach(child => getItemKeys(child, itemKeys))
    return itemKeys
  }
  const treeKeys = getItemKeys(tree)
  const newTreeKeys = getItemKeys(newTree)
  const treeIntersections = treeKeys.filter(key => newTreeKeys.includes(key))
  return treeIntersections
}

export const importTrove = (
  file: string,
  { forceItems }: { forceItems?: boolean } = {}
): AppThunk => (dispatch, getState) => {
  let data = file ? JSON.parse(file) as WorkspaceData : undefined
  if (!data) {
    throw Error('No suitable data file.')
  }
  const versionChange = Migrate.getAppVersionChange(data)
  if (versionChange === -1) {
    throw Error('The data is created with a newer version of the app. Upgrade your app first to be able to import it.')
  } else if (versionChange === 1) {
    const hasPendingMigrations = Migrate.pendingMigrations(data).length
    if (hasPendingMigrations) {
      console.info('Migrating data')
      data = Migrate.migrateData(data)
      // throw Error('The data you are trying to import is created with an older version and needs to be migrated.')
    }
  }
  const newItems = data?.items
  const newNode = data?.tree
  if (!newNode || !newItems) {
    throw Error('No suitable data file.')
  }
  const tree = selectScopedTree(getState())
  // add the item to the root
  dispatch(troveSlice.actions.addChildNode({ parentKey: tree.key, node: newNode }))
  dispatch(addItems({ items: newItems, force: forceItems }))
  dispatch(setSaved(false))
}
