import { useState, useEffect } from 'react';

const useInterval = (seconds = 60) => {
  const [timestamp, setTimestamp] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(Date.now())
    }, seconds * 1000)
    return () => clearInterval(interval)
  }, [seconds])

  return timestamp
}

export default useInterval