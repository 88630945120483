// import { KeyMap, KeySequence } from "react-hotkeys"

import { TROVE_FILTER_TYPES } from "./data"

export const SHORTCUTS: {
  [key: string]: {
    display?: string[][]
    keyMap: string[]
    help?: string
  }
} = {
  NAV_UP: {
    display: [['↑']],
    keyMap: ['up'],
    help: 'Navigate to previous item',
  },
  NAV_DOWN: {
    display: [['↓']],
    keyMap: ['down'],
    help: 'Navigate to next item',
  },
  FOLD: {
    display: [['←']],
    keyMap: ['left'],
    help: 'Navigate up the tree, fold selected',
  },
  UNFOLD: {
    display: [['→']],
    keyMap: ['right'],
    help: 'Navigate down the tree, unfold selected',
  },
  // c is compose in gmail
  ADD: {
    // keyMap: ['c', 'alt+down', 'alt+return'],
    // keyMap: ['command+enter', 'control+enter'],
    // add c ?
    display: [['⌘', '↓'], ['⌘', 'enter']],
    keyMap: ['command+down', 'control+down', 'command+enter'],
    help: 'Add item at same level',
  },
  // 'alt+c', 
  ADD_CHILD: {
    display: [['⌘', '→']],
    // keyMap: ['command+right', 'control+right'],
    keyMap: ['command+right', 'control+right'],
    help: 'Add item as child',
  },
  EDIT: {
    display: [['enter']],
    keyMap: ['enter'],
    help: 'Edit selected item',
  },
  // star in gmail is s
  // also should repond to - and +/= for upping and downing
  // 0, 1, 2, 3, 4, 5
  FUNNEL: {
    display: [['s'], ['1'], ['2'], ['3']],
    keyMap: ['s', 'alt+s', '0', '1', '2', '3', 'alt+0', 'alt+1', 'alt+2', 'alt+3'],
    help: `Increase priority (${TROVE_FILTER_TYPES.join(', ')}) – hold "alt" for recursive`,
  },
  // e is archive in gmail
  ARCHIVE: {
    display: [['e']],
    keyMap: ['e'],
    help: 'Archive/Unarchive selected item',
  },
  DELETE: {
    display: [['del']],
    keyMap: ['del', 'backspace'],
    help: 'Delete selected item',
  },
  // m stands for mute
  // DO THIS FOR MAIN MENU
  // and alt click for recursive folding
  FOLD_TOGGLE: {
    display: [['m'], ['⌥', 'm']],
    keyMap: ['m', 'alt+m'],
    help: 'Toggle folding – hold "alt" for recursive',
  },
  // select conversation
  // o is open
  SCOPE: {
    display: [['o'], ['⇧', 'enter']],
    keyMap: ['o', 'shift+enter'],
    help: 'Scope on current item',
  },
  // u is back to threadlist in gmail
  SCOPE_PARENT: {
    display: [['u']],
    keyMap: ['u'],
    help: 'Scope back to parent item',
  },
  // / is find in gmail
  FIND: {
    display: [['/'], ['⌘', 'F']],
    keyMap: ['/', 'command+f', 'control+f'],
    help: 'Find item',
  },
  ESCAPE: {
    display: [['esc']],
    keyMap: ['escape'],
    help: 'Exit/cancel',
  },
  HELP: {
    // display: ['⌘⇧N'],
    display: [['⌘', '?'], ['⇧', '?']],
    keyMap: ['?', 'command+/', 'shift+/'],
    help: 'Help',
  },
  // shortcuts that are system
  // _IGNORE: {
  //   keyMap: ['s', 'a', 'h'].map(k => `command+${k}`),
  // },
}

const keyMap: {
  [key: string]: string
} = {}
Object.keys(SHORTCUTS).forEach((key) => {
  const keys = SHORTCUTS[key].keyMap.join(', ')
  keyMap[key] = keys
})

export const KEY_MAP = keyMap
