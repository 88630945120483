import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './react/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './react/redux/store'
import 'semantic-ui-css/semantic.min.css'
import ReactGA from 'react-ga'
import { GA_ID } from './react/data';

ReactGA.initialize(GA_ID, {
  debug: false
})

ReactDOM.render(
  // <React.StrictMode>
  // </React.StrictMode>,
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
