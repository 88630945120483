import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { batch } from "react-redux"
import { toast } from "react-toastify"
import { selectActivePlanKey, selectPlans } from "../selector/planSelector"
import { AppThunk } from "../store"
import { PlanItemType } from "../types/PlanItemType"
import { PlanKey, PlanType } from "../types/PlanType"
import { updateItemSave } from "./itemSlice"
import { ItemKey } from "./troveSlice"
import { saveLocal, setActiveArea } from "./workspaceSlice"
import { getKey } from "../../util"
import { selectItems } from "../selector/itemSelector"
import ReactGA from 'react-ga'

const initialState: {
  plans: { [key: string]: PlanType | undefined }
  active?: PlanKey
  // selectedItems: ItemKey[]
  activeItem?: ItemKey
  showArchived: boolean
  showItemsArchived: boolean
  editing: boolean
} = {
  editing: false,
  showArchived: false,
  showItemsArchived: false,
  // selectedItems: [],
  // active: 'plan1',
  plans: {
    // plan1: {
    //   key: 'plan1',
    //   name: 'Test Plan',
    //   items: [],
    // }
  }
}

export type PlanUpdates = {
  name?: PlanType['name'],
  startDate?: PlanType['startDate'],
  endDate?: PlanType['endDate'],
}

type UpdatePlanProps = {
  key: PlanKey,
  updates: PlanUpdates,
  attributes: (keyof PlanType)[],
}
type SetPlansProps = { plans?: { [key: string]: PlanType | undefined } }
type SetActiveProps = { itemKey?: ItemKey, key?: PlanKey }
// type SelectItemProps = { itemKey?: ItemKey, key?: PlanKey }
type MovePlanItemsProps = { itemKeys: ItemKey[], from?: PlanKey, to: PlanKey }
type AddPlanProps = { plan: PlanType }
type AddItemProps = { itemKeys: ItemKey[], key: PlanKey }
type RemoveItemProps = { itemKeys: ItemKey[], key: PlanKey }
type ToggleArchivePlanProps = { key: PlanKey, archive?: boolean }
type CompleteItemProps = { itemKey: ItemKey, key: PlanKey, complete?: boolean, archive?: boolean }
type ToggleShowArchivedProps = { items?: boolean, plans?: boolean, archived?: boolean } | undefined
type ToggleEditingProps = boolean | undefined
type MoveItemProps = { key: PlanKey, source: ItemKey, target: ItemKey }

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setPlans(state, action: PayloadAction<SetPlansProps>) {
      state.plans = action.payload.plans || {}
    },
    setActive(state, action: PayloadAction<SetActiveProps>) {
      const { key, itemKey } = action.payload
      state.active = key
      state.activeItem = key ? itemKey : undefined
    },
    // selectPlanItem(state, action: PayloadAction<SelectItemProps>) {
    //   const { key, itemKey } = action.payload
    //   if (!key || !itemKey) state.selectedItems = []
    //   else {
    //     const index = state.selectedItems.findIndex(i => i === itemKey)
    //     if (index > -1) {
    //       state.selectedItems.splice(index, 1)
    //     } else {
    //       state.selectedItems.push(itemKey)
    //     }
    //   }
    // },
    toggleEditing(state, action: PayloadAction<ToggleEditingProps>) {
      state.editing = action.payload === undefined ? !state.editing : action.payload
    },
    addPlan(state, action: PayloadAction<AddPlanProps>) {
      const { plan } = action.payload
      const createdAt = Date.now()
      plan.createdAt = createdAt
      plan.updatedAt = createdAt
      state.plans[plan.key] = plan
    },
    updatePlan(state, action: PayloadAction<UpdatePlanProps>) {
      const { key, updates, attributes } = action.payload
      const { name, startDate, endDate } = updates
      const plan = state.plans[key]
      if (!plan) return
      if (attributes.includes('name')) plan.name = name || ''
      if (attributes.includes('startDate')) plan.startDate = startDate
      if (attributes.includes('endDate')) plan.endDate = endDate
      const updatedAt = Date.now()
      plan.updatedAt = updatedAt
      state.plans[key] = plan
    },
    toggleArchivePlan(state, action: PayloadAction<ToggleArchivePlanProps>) {
      const { key, archive } = action.payload
      const plan = state.plans[key]
      if (!plan) return
      const archive2 = archive === undefined ? !plan.archivedAt : archive
      const archivedAt = Date.now()
      plan.updatedAt = archivedAt
      plan.archivedAt = archive2 ? archivedAt : undefined
    },
    addItem(state, action: PayloadAction<AddItemProps>) {
      const { key, itemKeys } = action.payload
      const plan = state.plans[key]
      if (!plan) return
      itemKeys.forEach(itemKey => {
        if (plan.items.findIndex(i => i.key === itemKey) > -1) return
        const newPlanItem: PlanItemType = { key: itemKey }
        plan.items.unshift(newPlanItem)
      })
      plan.completedAt = undefined
      plan.updatedAt = Date.now()
    },
    removeItem(state, action: PayloadAction<RemoveItemProps>) {
      const { key, itemKeys } = action.payload
      const plan = state.plans[key]
      if (!plan) return
      itemKeys.forEach(itemKey => {
        const index = plan.items.findIndex(i => i.key === itemKey)
        if (index > -1) plan.items.splice(index, 1)
      })
      const completed = plan.items.findIndex(i => !i.archivedAt && !i.completedAt) === -1
      plan.completedAt = completed ? Date.now() : undefined
      plan.updatedAt = Date.now()
    },
    moveItem(state, action: PayloadAction<MoveItemProps>) {
      const { key, source, target } = action.payload
      const plan = state.plans[key]
      if (!plan) return
      const sourceIndex = plan.items.findIndex(i => i.key === source)
      const targetIndex = plan.items.findIndex(i => i.key === target)
      plan.items.splice(targetIndex, 0, plan.items.splice(sourceIndex, 1)[0])
      plan.updatedAt = Date.now()
    },
    completeItem(state, action: PayloadAction<CompleteItemProps>) {
      const { key, itemKey, complete, archive } = action.payload
      const plan = state.plans[key]
      if (!plan) return
      const item = plan.items.find(i => i.key === itemKey)
      if (!item) return
      if (complete !== undefined) {
        item.completedAt = complete ? Date.now() : undefined
        item.archivedAt = undefined
      } else if (archive !== undefined) {
        item.archivedAt = archive ? Date.now() : undefined
        item.completedAt = undefined
      }
      const completed = plan.items.findIndex(i => !i.archivedAt && !i.completedAt) === -1
      plan.completedAt = completed ? Date.now() : undefined
      plan.updatedAt = Date.now()
    },
    toggleShowArchived(state, action: PayloadAction<ToggleShowArchivedProps>) {
      const { items, plans, archived } = action.payload || {}
      if (items) {
        state.showItemsArchived = archived !== undefined ? archived : !state.showItemsArchived
      }
      if (plans) {
        state.showArchived = archived !== undefined ? archived : !state.showArchived
      }
    },
  },
})

export const {
  setPlans,
  // selectPlanItem,
  toggleEditing,
  toggleShowArchived,
} = planSlice.actions

export default planSlice.reducer

const addPlanSave = (props: AddPlanProps): AppThunk => (dispatch) => {
  dispatch(planSlice.actions.addPlan(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Plan',
    action: 'addPlan'
  })
}

export const updatePlanSave = (props: UpdatePlanProps): AppThunk => (dispatch) => {
  dispatch(planSlice.actions.updatePlan(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Plan',
    action: 'updatePlan'
  })
}

const toggleArchivePlanSave = (props: ToggleArchivePlanProps): AppThunk => (dispatch) => {
  dispatch(planSlice.actions.toggleArchivePlan(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Plan',
    action: 'toggleArchivePlan'
  })
}

const addItemSave = (props: AddItemProps): AppThunk => (dispatch) => {
  dispatch(planSlice.actions.addItem(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Plan',
    action: 'addItem'
  })
}

const removeItemSave = (props: RemoveItemProps): AppThunk => (dispatch) => {
  dispatch(planSlice.actions.removeItem(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Plan',
    action: 'removeItem'
  })
}

export const moveItemSave = (props: MoveItemProps): AppThunk => (dispatch) => {
  dispatch(planSlice.actions.moveItem(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Plan',
    action: 'moveItem'
  })
}

const completeItemSave = (props: CompleteItemProps): AppThunk => (dispatch) => {
  dispatch(planSlice.actions.completeItem(props))
  dispatch(saveLocal())
  ReactGA.event({
    category: 'Plan',
    action: 'completeItem'
  })
}

export const movePlanItemsSave = (props: MovePlanItemsProps): AppThunk => (dispatch, getState) => {
  const { itemKeys, from, to } = props
  if (from === to || !itemKeys.length) return
  const plans = selectPlans(getState())
  batch(() => {
    // itemKeys.forEach(itemKey => {
    //   const planItem = plans[from]?.items.find(i => itemKey === i.key)
    //   if (!planItem) return
    // })
    dispatch(planSlice.actions.addItem({ key: to, itemKeys }))
    if (from) dispatch(planSlice.actions.removeItem({ key: from, itemKeys }))
  })
  dispatch(saveLocal())
  toast.info(`${itemKeys.length} item ${from ? 'moved' : 'copied'} to ${plans[to]?.name || '?'}`, {
    className: 'ui message info'
  })
  ReactGA.event({
    category: 'Plan',
    action: 'movePlanItems'
  })
}

// const movePlanItemsSave = (props: MovePlanItemsProps): AppThunk => (dispatch, getState) => {
//   const { itemKeys, from, to } = props
//   const plans = selectPlans(getState())
//   batch(() => {
//     itemKeys.forEach(itemKey => {
//       const planItem = plans[from]?.items.find(i => itemKey === i.key)
//       if (!planItem) return
//       dispatch(planSlice.actions.addItem({ key: to, item: planItem }))
//     })
//     dispatch(planSlice.actions.removeItem({ key: from, itemKeys }))
//   })
//   dispatch(saveLocal())
// }

// export const moveSelectedPlanItems = ({ from, to }: {
//   from: PlanKey,
//   to: PlanKey,
// }): AppThunk => (dispatch, getState) => {
//   if (from === to) return
//   const selectedItems = selectSelectedPlanItems(getState())
//   batch(() => {
//     dispatch(movePlanItemsSave({ from, to, itemKeys: selectedItems }))
//     dispatch(selectPlanItem({}))
//   })
// }

export const setActivePlan = ({ key, itemKey }: {
  key?: PlanKey,
  itemKey?: ItemKey
} = {}): AppThunk => (dispatch, getState) => {
  // const activePlan = selectActivePlanKey(getState())
  batch(() => {
    // if (key !== activePlan) dispatch(selectPlanItem({}))
    dispatch(planSlice.actions.setActive({ key, itemKey }))
    if (key || itemKey) dispatch(setActiveArea('plan'))
  })
}

export const toggleArchivePlan = (key: PlanKey, archive?: boolean): AppThunk => (dispatch, getState) => {
  const plan = selectPlans(getState())[key]
  dispatch(toggleArchivePlanSave({ key, archive }))
  if (archive || !plan?.archivedAt) {
    toast.info(`Checked Off: ${plan ? plan?.name : '?'}`, {
      className: 'ui message positive',
    })
  }
}

export const addPlan = ({ updates }: {
  updates: PlanUpdates,
  // from?: PlanKey
}): AppThunk => (dispatch, state) => {
  let items: PlanItemType[] = []
  // not in use right now...
  // if (from) {
  //   const plan = state().plan.plans[from]
  //   if (plan) {
  //     items = plan.items.filter(item => !item.completedAt && !item.archivedAt) || items
  //     dispatch(toggleArchivePlan(from, true))
  //   }
  // }
  const newPlan: PlanType = {
    key: getKey(),
    name: updates.name || '',
    startDate: updates.startDate,
    endDate: updates.endDate,
    items,
  }
  batch(() => {
    dispatch(addPlanSave({ plan: newPlan }))
    dispatch(setActivePlan({ key: newPlan.key }))
  })
}

export const addActivePlanItem = ({ key, text }: {
  key?: ItemKey,
  text?: string
}): AppThunk => (dispatch, getState) => {
  const active = selectActivePlanKey(getState())
  if (!active) return
  dispatch(addPlanItem({ planKey: active, key, text }))
}

export const addPlanItem = ({ planKey, key, text }: {
  planKey: PlanKey,
  key?: ItemKey,
  text?: string
}): AppThunk => (dispatch, getState) => {
  // const active = selectActivePlanKey(getState())
  batch(() => {
    // if (!active) return
    key = key || getKey()
    // const newPlanItem: PlanItemType = { key }
    if (text) dispatch(updateItemSave({ key, updates: { text }, attributes: ['text'] }))
    dispatch(addItemSave({ key: planKey, itemKeys: [key] }))
    dispatch(setActivePlan({ key: planKey, itemKey: key }))
  })
}

export const removePlanItem = ({
  key,
  itemKeys
}: {
  key: PlanKey,
  itemKeys: ItemKey[]
}): AppThunk => (dispatch) => {
  dispatch(removeItemSave({ key, itemKeys }))
}

export const completePlanItem = (
  { key, itemKey, complete, archive }: {
    key: PlanKey,
    itemKey: ItemKey,
    complete?: boolean,
    archive?: boolean
  }
): AppThunk => (dispatch, getState) => {
  batch(() => {
    dispatch(setActivePlan({ key, itemKey }))
    dispatch(completeItemSave({ key, itemKey, complete, archive }))
  })
  if (complete || archive) {
    const item = selectItems(getState())[itemKey]
    toast.info(`Checked Off: ${item ? item?.text : '?'}`, {
      className: 'ui message positive',
    })
  }
}
