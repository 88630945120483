import React from 'react'
import Emoji from 'react-emoji-render'
import { Header } from 'semantic-ui-react'

type Props = {
  style: React.CSSProperties
  shield: string
}

const EmojiShield = ({ style, shield }: Props) => {
  // const text = 'Onno=🦁+🚀+☀️+🔨'
  // const text = 'Onno=:lion:+:rocket:+:sun:+:hammer:'
  const [name, emojisString] = shield.split('=')
  if (!name || !emojisString) return null
  const emojis = emojisString.split('+')
  if (emojis.length !== 4) return null
  const emojis2 = [emojis.slice(0, 2), emojis.slice(2, 4)]
  // const name = 'Onno'
  // const emojis = [['lion', 'rocket'], ['sun', 'hammer']]
  return (
    <table style={style} className='emoji-shield'>
      <tbody>
        <tr>
          <td align='center'>
            <Header>{name.toUpperCase()}</Header>
          </td>
        </tr>
        <tr>
          <td align='center'>
            <table>
              <tbody>
                {emojis2.map((row, index) => <tr
                  className='emoji-shield-row'
                  key={`row${index}`}>
                  {row.map((emoji, index2) => <td
                    className='emoji-shield-col'
                    // textAlign={index2 ? 'left' : 'right'}
                    key={`col${index2}`}>
                    <Emoji text={`${emoji}`} />
                  </td>
                  )}
                </tr>)}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default EmojiShield