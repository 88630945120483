import { createSelector } from '@reduxjs/toolkit'
import { TROVE_FILTER_TYPES } from '../../data'
import { notUndefined } from '../../util'
import { RootState } from '../reducers'
import { ItemKey } from '../slice/troveSlice'
import { SearchResultType } from "../types/SearchResultType"
import { TroveType } from "../types/TroveType"
import { isItemMatch, selectItems } from './itemSelector'

type ConditionFunction = (node: TroveType, parent?: TroveType) => boolean

const selectSlice = (state: RootState) => state.trove

export const selectTree = createSelector(selectSlice, trove => trove.tree)
export const selectActiveItemKey = createSelector(selectSlice, trove => trove.activeItemKey)
export const selectShowArchived = createSelector(selectSlice, trove => trove.showArchived)
export const selectFilterFunnel = createSelector(selectSlice, trove => trove.filterFunnel)
export const selectSearchTerm = createSelector(selectSlice, trove => trove.searchTerm)
export const selectQuickSearchTerm = createSelector(selectSlice, trove => trove.quickSearchTerm)
export const selectScopeKey = createSelector(selectSlice, trove => trove.scopeKey)
export const selectSideKey = createSelector(selectSlice, trove => trove.sideKey)
export const selectEditing = createSelector(selectSlice, trove => trove.editing)
export const selectRootKey = createSelector(selectTree, tree => tree.key)

export const selectScopedTree = createSelector(
  [selectTree, selectScopeKey],
  (tree, scopeKey) => {
    const treeScoped = scopeKey ? getNodeByKey(tree, scopeKey) : undefined
    return treeScoped || tree
  }
)

export const selectSideTree = createSelector(
  [selectTree, selectSideKey],
  (tree, key) => {
    const sideTree = key ? getNodeByKey(tree, key) : undefined
    return sideTree
  }
)

export const selectSideItem = createSelector(
  [selectItems, selectSideKey],
  (items, key) => key ? items[key] : undefined
)

export const selectFunneledTree = createSelector(
  [selectScopedTree, selectFilterFunnel, selectShowArchived, selectActiveItemKey],
  (scopedTree, filterFunnel, showArchived, activeItemKey) => {
    if (filterFunnel === undefined) return []
    const match = (node: TroveType) => {
      return (node.funnel !== undefined && node.funnel >= filterFunnel)
    }
    const condition: ConditionFunction = (node: TroveType) => {
      const isRoot = scopedTree.key === node.key
      const active = node.key === activeItemKey
      return (active || (!isRoot && match(node))) && (showArchived || !node.archived)
    }
    const flat = getFlatTree(scopedTree, [], condition)
    return flat
  }
)

export const selectSearchResult = createSelector(
  [
    selectScopedTree,
    selectSearchTerm,
    selectShowArchived,
    selectItems,
  ],
  (tree, searchTerm, showArchived, items) => {
    if (!searchTerm) return
    const condition: ConditionFunction = (node) => {
      const item = items[node.key]
      if (!item) return false
      return (showArchived || !node.archived) && isItemMatch(item, searchTerm)
    }
    return getFlatTree(tree, [], condition)
  }
)

export const selectQuickSearchResult = createSelector(
  [
    selectTree,
    selectQuickSearchTerm,
    selectShowArchived,
    selectItems
  ],
  (tree, quickSearchTerm, showArchived, items) => {
    if (!quickSearchTerm) return []
    const condition: ConditionFunction = (node: TroveType) => {
      const item = items[node.key]
      if (!item) return false
      return (showArchived || !node.archived) && isItemMatch(item, quickSearchTerm)
    }
    const searchResults: SearchResultType[] = getFlatTree(tree, [], condition)
      .map(node => ({
        key: node.key,
        title: items[node.key]?.text || '?',
        description: [
          node.funnel !== undefined ? TROVE_FILTER_TYPES[node.funnel] : undefined,
          node.archived ? 'Archived' : undefined,
        ].filter(notUndefined).join(', '),
        // item: items[t.key],
      })).filter(notUndefined)
    return searchResults
  }
)

export const selectScopedItem = createSelector(
  [selectScopeKey, selectItems],
  (scopeKey, items) => scopeKey ? items[scopeKey] : undefined
)

// Get parents from scoped tree (outside of current scope)
export const selectScopedParents = createSelector(
  [selectTree, selectScopedTree],
  (tree, treeScoped) => {
    const parents = getParentNodes(tree, treeScoped.key)?.reverse()
    return parents
  }
)

export const getParentNodes = (tree: TroveType, scopeKey: ItemKey, parents: TroveType[] = []) => {
  const parent = getParentFromKey(tree, scopeKey)
  if (!parent) return
  parents.push(parent)
  getParentNodes(tree, parent.key, parents)
  return parents
}

export const getNodeByKey = (
  node: TroveType,
  key: ItemKey,
  parent = false,
): TroveType | undefined => {
  const stack = []
  stack.push(node)
  while (stack.length > 0) {
    const node = stack.pop()
    if (
      parent
        ? node?.sub && node.sub.findIndex(n => n.key === key) > -1
        : node?.key === key
    ) {
      return node
    } else {
      node?.sub?.forEach(n => stack.push(n))
    }
  }
}

export const getParentFromKey = (
  node: TroveType,
  key: ItemKey,
): TroveType | undefined => {
  return getNodeByKey(node, key, true)
}

export const getSubCount = ({
  node,
  showArchived,
}: {
  node: TroveType,
  showArchived: boolean,
}) => {
  const subCount = showArchived
    ? node.sub?.length
    : node.sub?.filter(child => !child.archived).length
  return subCount || 0
}

export function getFlatTree(
  node: TroveType,
  nodes: TroveType[] = [],
  condition?: ConditionFunction,
  recursive = true,
  parent?: TroveType
) {
  const include = !condition || condition(node, parent)
  if (include) {
    nodes.push(node)
  }
  if (recursive || include) {
    node.sub?.forEach(child => getFlatTree(child, nodes, condition, recursive, node))
  }
  return nodes
}
