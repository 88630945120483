import { RootState } from "../reducers"
import { createSelector } from "@reduxjs/toolkit"
import { ItemKey } from "../slice/troveSlice"
import { ItemType } from "../types/ItemType"
import { notUndefined } from "../../util"

const selectSlice = (state: RootState) => state.item

export const selectItems = createSelector(selectSlice, item => item.items)

export const selectRecentItems = createSelector(
  [selectItems],
  (items) => {
    const limit = 20
    return Object.keys(items)
      .map(key => items[key])
      .sort((a, b) => (b?.updatedAt || 0) - (a?.updatedAt || 0))
      .filter(notUndefined)
      .slice(0, limit)
  }
)

// TODO this isn't memoizing at all
export const selectItem = (key: ItemKey) => createSelector(selectItems, items => items[key])

function escapeRegExp(string: string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const isItemMatch = (item: ItemType, searchTerm: string) => {
  return !!item?.text.toLowerCase().match(escapeRegExp(searchTerm).toLowerCase())
}