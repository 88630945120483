import React from 'react'
import { useSelector } from 'react-redux'
import { List, Message, Segment } from 'semantic-ui-react'
import { selectSearchResult } from './redux/selector/troveSelector'
import TroveItemSearch from './TroveItemSearch'

const TroveListSearch = React.memo(() => {
  const nodes = useSelector(selectSearchResult)

  return <>
    {!nodes?.length
      ? <Message attached='bottom' info>
        No results found.
      </Message>
      : <Segment attached='bottom' style={{ padding: 0 }}>
        <List
          // size='large'
          divided
          relaxed
          selection
        >
          {nodes.map(tree => <TroveItemSearch
            key={tree.key}
            node={tree}
          />)}
        </List>
      </Segment>
    }
  </>
})

export default TroveListSearch