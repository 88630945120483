import React from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { Label } from 'semantic-ui-react'
import LinkifyWithDecorator from './LinkifyWithDecorator'
import { selectSearchTerm } from './redux/selector/troveSelector'
import { ItemType } from './redux/types/ItemType'
import Wrap from './Wrap'


type TroveItemContentPropsI = {
  item?: ItemType
  level?: number
  archived?: boolean
  completed?: boolean
}

const TroveItemContent = React.memo(({ item, level, archived, completed }: TroveItemContentPropsI) => {
  const searchTerm = useSelector(selectSearchTerm)

  return <span className='item-content'>
    <Wrap active={archived || completed} element='s'>
      <Wrap active={level === 0} element='strong'>
        {item?.text
          ? <LinkifyWithDecorator>
            {searchTerm ? <Highlighter
              textToHighlight={item?.text || ''}
              searchWords={[searchTerm]}
              autoEscape
            /> : item.text}
          </LinkifyWithDecorator>
          : <Label color='yellow' size='tiny'>Empty</Label>
        }
      </Wrap>
    </Wrap>
  </span>
})

export default TroveItemContent