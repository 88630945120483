import React from 'react'


type Props = {
  count: number
}

const SubcountView = React.memo(({ count }: Props) => {
  return (
    <span
      style={{
        paddingLeft: '0.5em',
        color: 'rgba(0, 0, 0, 0.5)'
      }}
    >
      {count}
    </span>
  )
})

export default SubcountView