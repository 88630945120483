import React, { useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Placeholder, Segment } from 'semantic-ui-react'
import FunnelMenu from './FunnelMenu'
import Messages from './Messages'
import { selectFilterFunnel, selectSearchTerm, selectActiveItemKey } from './redux/selector/troveSelector'
import { selectActiveWorkspace } from './redux/selector/workspaceSelector'
import { setActiveArea } from './redux/slice/workspaceSlice'
import ScopeMenu from './ScopeMenu'
import TroveListFunnel from './TroveListFunnel'
import TroveListMain from './TroveListMain'
import TroveListSearch from './TroveListSearch'
import TroveMessage from './TroveMessage'
import { toggleEditing } from './redux/slice/troveSlice'

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

const Trove = React.memo(({ className }: Props) => {
  const searchTerm = useSelector(selectSearchTerm)
  const filterFunnel = useSelector(selectFilterFunnel)
  const activeWorkspace = useSelector(selectActiveWorkspace)
  const dispatch = useDispatch()
  const activeItemKey = useSelector(selectActiveItemKey)

  useEffect(() => {
    // fire when filterFunnel changes
    dispatch(setActiveArea(filterFunnel === undefined ? 'trove' : 'funnel'))
  }, [dispatch, filterFunnel, activeItemKey])

  const ref = useRef<HTMLDivElement>(null)

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    dispatch(setActiveArea('trove'))
    dispatch(toggleEditing(false))
  }, [dispatch])

  return (
    <div onClick={handleClick} id="trove" tabIndex={-1} ref={ref} className={className}>
      <Messages />
      <FunnelMenu />
      <TroveMessage />
      <ScopeMenu />
      {activeWorkspace ? <>
        {searchTerm ? <TroveListSearch />
          : filterFunnel !== undefined ? <TroveListFunnel />
            : <TroveListMain />
        }
      </> : <>
          <Segment>
            <Placeholder>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
          </Segment>
        </>}
    </div>
  )
})

export default Trove
