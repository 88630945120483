import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import diff from 'deep-diff'
import { defaultWorkspaceData } from "../../data"
import { Migrate } from "../../migrate/migrate"
import { RootState } from "../reducers"
import { selectActiveWorkspace, selectWorkspaces, selectSettings } from "../selector/workspaceSelector"
import { AppThunk } from "../store"
import { ItemType } from "../types/ItemType"
import { PlanType } from "../types/PlanType"
import { TroveType } from "../types/TroveType"
import { setItems } from "./itemSlice"
import { setPlans } from "./planSlice"
import { clearWorkspace, setTrove } from "./troveSlice"
import { selectScopedTree } from "../selector/troveSelector"
import { selectItems } from "../selector/itemSelector"
import { getKey } from "../../util"

// export type TroveSliceProps = {
//   key: string,
//   value: any,
// }

export type Workspace = { key: string, name: string }

export type WorkspaceData = {
  tree?: TroveType
  items?: { [key: string]: ItemType | undefined }
  plans?: { [key: string]: PlanType | undefined }
  appVersion?: string
}

export type Area = 'trove' | 'find' | 'funnel' | 'plan' | 'sideTrove' | 'recentItems' | 'planning'
export type Modal = 'shortcuts' | 'changelog'

const initialState: {
  activeWorkspace?: string
  isDebug?: boolean
  dataVersion?: string,
  versionChange?: -1 | 0 | 1
  saved: boolean
  workspaces: Workspace[]
  activeArea?: Area
  sideMenu?: Area
  modal?: Modal
  settings: {
    shield?: string | null
    background?: string | null
  }
} = {
  // sideMenu: 'planning',
  saved: true,
  activeWorkspace: undefined,
  isDebug: process.env.NODE_ENV === 'development',
  workspaces: [{ key: 'data', name: 'My Trove' }],
  settings: {},
}

export type HGSettings = {
  workspaces?: RootState['workspace']['workspaces']
  activeWorkspace?: RootState['workspace']['activeWorkspace']
  shield?: string | null
  background?: string | null
}

type SetActiveAreaProps = Area | undefined
type ToggleSideMenuProps = { area?: Area, active?: boolean }

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaceData(state, action: PayloadAction<{
      versionChange: -1 | 0 | 1 | undefined,
    }>) {
      state.versionChange = action.payload.versionChange
    },
    setDataVersion(state, action: PayloadAction<string | undefined>) {
      state.dataVersion = action.payload
    },
    saveMigrated(state, action: PayloadAction) {
      if (state.activeWorkspace) {
        state.versionChange = 0
      }
    },
    setSaved(state, action: PayloadAction<boolean>) {
      if (state.activeWorkspace) {
        state.saved = action.payload
      }
    },
    setModal(state, action: PayloadAction<Modal | undefined>) {
      state.modal = action.payload
    },
    setSettings(state, action: PayloadAction<HGSettings>) {
      const settings = action.payload
      if (settings.activeWorkspace) state.activeWorkspace = settings.activeWorkspace
      if (settings.workspaces) state.workspaces = settings.workspaces
      if (settings.shield !== undefined) state.settings.shield = settings.shield
      if (settings.background !== undefined) state.settings.background = settings.background
    },
    setActiveArea(state, action: PayloadAction<SetActiveAreaProps>) {
      state.activeArea = action.payload
    },
    toggleSideMenu(state, action: PayloadAction<ToggleSideMenuProps>) {
      const { area, active } = action.payload
      const active2 = active === undefined ? state.sideMenu !== area : active
      state.sideMenu = active2 ? area : undefined
      state.activeArea = active2 ? area : 'trove'
    },
  }
})

export const {
  setActiveArea,
  toggleSideMenu,
  setSaved,
  setModal,
  // saveMigrated,
  // setSettings,
  // setWorkspaceData,
} = workspaceSlice.actions

export default workspaceSlice.reducer

// THUNK

export const saveLocal = ({ migrate = false } = {}): AppThunk => (dispatch, getState) => {
  const { item, workspace, trove, plan } = getState()
  const { activeWorkspace } = workspace
  if (!activeWorkspace) return
  if (!migrate && workspace.versionChange === 1) return // migration in process
  const current: WorkspaceData = {
    tree: trove.tree,
    items: item.items,
    plans: plan.plans,
    appVersion: Migrate.appVersion(),
  }
  if (workspace.isDebug) {
    const previous = JSON.parse(window.localStorage.getItem(activeWorkspace) || '{}')
    console.info(diff.diff(previous, current))
  }
  window.localStorage.setItem(activeWorkspace, JSON.stringify(current))
  dispatch(workspaceSlice.actions.setDataVersion(current.appVersion))
  dispatch(setSaved(true))
  if (migrate) {
    dispatch(workspaceSlice.actions.saveMigrated())
  }
}

export const loadSettings = (): AppThunk => (dispatch, getState) => {
  const workspaces = selectWorkspaces(getState())
  const settingsString = window.localStorage.getItem('settings')
  const settings: HGSettings = settingsString ? JSON.parse(settingsString) : {}
  const defaultWorkspace = workspaces[0].key
  if (!settings.activeWorkspace) settings.activeWorkspace = defaultWorkspace
  dispatch(workspaceSlice.actions.setSettings(settings))
}

export const saveSettings = (): AppThunk => (dispatch, getState) => {
  const workspaces = selectWorkspaces(getState())
  const activeWorkspace = selectActiveWorkspace(getState())
  const _settings = selectSettings(getState())
  const settings = { ..._settings, workspaces, activeWorkspace }
  window.localStorage.setItem('settings', JSON.stringify(settings))
}

export const setSettings = (props: HGSettings): AppThunk => (dispatch, getState) => {
  dispatch(workspaceSlice.actions.setSettings(props))
  dispatch(saveSettings())
}

export const switchWorkspace = (key: string): AppThunk => (dispatch, state) => {
  dispatch(workspaceSlice.actions.setSettings({ activeWorkspace: key }))
  dispatch(saveSettings())
}

export const deleteWorkspace = (key: string): AppThunk => (dispatch, getState) => {
  const workspaces = selectWorkspaces(getState())
  const defaultWorkspace = workspaces[0].key
  if (key === defaultWorkspace) return // don't delete the default workspace
  // remove the workspace from the workspaces array and clear data
  const workspaces2 = [...workspaces]
  workspaces2.splice(workspaces.findIndex(w => w.key === key), 1)
  window.localStorage.removeItem(key)
  dispatch(workspaceSlice.actions.setSettings({ activeWorkspace: defaultWorkspace, workspaces: workspaces2 }))
  dispatch(saveSettings())
}

export const getWorkspaceData = (key: string) => {
  const dataLocal = window.localStorage.getItem(key) || defaultWorkspaceData
  return dataLocal ? JSON.parse(dataLocal) as WorkspaceData : undefined
}

export const setWorkspace = (key: string): AppThunk => (dispatch, state) => {
  dispatch(clearWorkspace())
  let data = getWorkspaceData(key)
  let versionChange: RootState['workspace']['versionChange']
  if (data) {
    versionChange = Migrate.getAppVersionChange(data)
    if (versionChange === 1) {
      const hasPendingMigrations = Migrate.pendingMigrations(data).length
      if (hasPendingMigrations) {
        console.info('Migrating data for workspace')
        data = Migrate.migrateData(data)
        dispatch(setSaved(false))
      } else {
        versionChange = undefined
      }
    }
  }
  const tree = (data ? data.tree : undefined)
  const items = (data ? data.items : undefined)
  const plans = (data ? data.plans : undefined)
  dispatch(workspaceSlice.actions.setWorkspaceData({ versionChange }))
  dispatch(workspaceSlice.actions.setDataVersion(data?.appVersion))
  dispatch(setTrove({ tree }))
  dispatch(setItems({ items }))
  dispatch(setPlans({ plans }))
}

export const createWorkspace = (name: string): AppThunk => (dispatch, getState) => {
  const workspaces = selectWorkspaces(getState())
  const key = getKey()
  dispatch(workspaceSlice.actions.setSettings({ workspaces: [...workspaces, { key, name }] }))
  dispatch(saveSettings())
  dispatch(switchWorkspace(key))
}

// Workspace data, for downloading
export const getFilteredWorkspaceData = (): AppThunk => (dispatch, getState) => {
  const items = selectItems(getState())
  const treeScoped = selectScopedTree(getState())
  function getItemsClone(t: TroveType, itemsClone: WorkspaceData['items'] = {}) {
    itemsClone[t.key] = items[t.key]
    t.sub?.forEach(t => getItemsClone(t, itemsClone))
    return itemsClone
  }
  const workspaceData: WorkspaceData = {
    tree: treeScoped,
    items: getItemsClone(treeScoped),
    appVersion: Migrate.appVersion()
  }
  return workspaceData
}
