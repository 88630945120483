import React from 'react'
import { useSelector } from 'react-redux'
import { List, Message, Segment } from 'semantic-ui-react'
import { selectFunneledTree } from './redux/selector/troveSelector'
import TroveItemFunnel from './TroveItemFunnel'

const TroveListFunnel = React.memo(() => {
  const nodes = useSelector(selectFunneledTree)

  return !nodes?.length
    ? <Message attached='bottom' info>
      No results found.
      </Message>
    : <Segment attached='bottom' style={{ padding: 0 }}>
      <List
        // size='large'
        divided
        relaxed
        selection
      >
        {nodes.map(node => <TroveItemFunnel key={node.key} node={node} />)}
      </List>
    </Segment>
})

export default TroveListFunnel