import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List, Ref, Segment } from 'semantic-ui-react'
import ConditionalWrapper from './ConditionalWrapper'
import PlanItem from './PlanItem'
import PlanItemAdd from './PlanItemAdd'
import { RootState } from './redux/reducers'
import { selectActivePlanKey, selectShowItemsArchived } from './redux/selector/planSelector'
import { addPlanItem } from './redux/slice/planSlice'
import { PlanType } from './redux/types/PlanType'

type Props = {
  plan: PlanType
  calendar?: boolean
}
const PlanItemList = ({ plan, calendar }: Props) => {
  const dispatch = useDispatch()
  const planActive = useSelector((state: RootState) =>
    selectActivePlanKey(state) === plan.key)
  const showArchived = useSelector(selectShowItemsArchived)
  const [newItem, setNewItem] = useState(false)
  const archived = !!plan.archivedAt

  const ref = useRef<HTMLElement>(null)

  const handleAdd = useCallback(({ key, text }: { key?: string, text?: string }) => {
    dispatch(addPlanItem({ planKey: plan.key, key, text }))
  }, [dispatch, plan.key])


  return <>
    <Ref innerRef={ref}>
      <ConditionalWrapper
        condition={!calendar}
        wrap={(children) => <Segment style={{ padding: 0 }}>
          <List
            // relaxed='very'
            divided
            selection>
            {children}
          </List>
        </Segment>}
        wrapAlt={(children) => <List
          // relaxed='very'
          style={{
            borderTop: '1px solid rgba(34,36,38,.15)',
            marginLeft: '-1em',
            marginRight: '-1em',
          }}
          divided
          selection>
          {children}
        </List>}
      >
        <>
          {planActive && !archived
            ? <List.Item
              style={{ padding: 0 }}
              active={newItem}>
              <List.Content>
                <PlanItemAdd
                  active={newItem}
                  setActive={setNewItem}
                  onSelect={({ key, text }) => handleAdd({ key, text })}
                />
              </List.Content>
            </List.Item>
            : undefined
          }
          {plan.items
            .filter(item => showArchived || (!item.archivedAt && !item.completedAt))
            .sort((a, b) => (a.archivedAt || a.completedAt || 0) - (b.archivedAt || b.completedAt || 0))
            .map(planItem => <PlanItem
              key={planItem.key}
              plan={plan}
              planItem={planItem}
              calendar={calendar}
            />)}
        </>
      </ConditionalWrapper>
    </Ref>
  </>
}

export default PlanItemList