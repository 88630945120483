import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Label, Menu, Icon } from 'semantic-ui-react'
import { selectActivePlansCount } from './redux/selector/planSelector'
import { getSubCount, selectScopeKey, selectShowArchived, selectTree } from './redux/selector/troveSelector'
import { selectSideMenu, selectSettings } from './redux/selector/workspaceSelector'
import { setScopeKey } from './redux/slice/troveSlice'
import { Area, toggleSideMenu } from './redux/slice/workspaceSlice'
import TroveMenuItem from './TroveMenuItem'
import EmojiShield from './EmojiShield'

const TroveMenu = React.memo(() => {
  const dispatch = useDispatch()
  const sideMenu = useSelector(selectSideMenu)
  const tree = useSelector(selectTree)
  const scopeKey = useSelector(selectScopeKey)
  const plansCount = useSelector(selectActivePlansCount)
  const showArchived = useSelector(selectShowArchived)
  const { shield } = useSelector(selectSettings)

  const subCount = useMemo(() =>
    getSubCount({ node: tree, showArchived }),
    [tree, showArchived])

  const handleClick = useCallback((itemKey?: string) => {
    dispatch(setScopeKey(itemKey))
  }, [dispatch])

  const handleSidebarToggle = useCallback((area: Area) => {
    dispatch(toggleSideMenu({ area }))
  }, [dispatch])

  return <>
    {shield ? <>
      <EmojiShield shield={shield} style={{ margin: 'auto' }} />
      <Divider />
    </> : undefined}
    <Menu borderless secondary compact fluid vertical>
      <Menu.Item
        onClick={() => handleSidebarToggle('recentItems')}
        active={sideMenu === 'recentItems'}
        icon>
        <Icon color='grey' name='clock' />
        Recents
      </Menu.Item>
      {plansCount ? <>
        {/* <Menu.Item header content='EXPERIMENTAL' /> */}
        <Menu.Item
          active={sideMenu === 'plan'}
          onClick={() => handleSidebarToggle('plan')}
          icon
        >
          {plansCount
            ? <Label
              size='tiny'
              content={plansCount}
            />
            : <Icon color='grey' name='clipboard list' />
          }
          Checklists
        </Menu.Item>
        <Menu.Item
          active={sideMenu === 'planning'}
          onClick={() => handleSidebarToggle('planning')}
          icon
        >
          <Icon color='grey' name='calendar' />
          Planning
        </Menu.Item>
      </> : undefined}
    </Menu>
    <Divider />
    <Menu borderless secondary compact fluid vertical>
      <Menu.Item header content='TROVES' />
      <Menu.Item onClick={() => handleClick()} active={scopeKey === undefined}>
        All
        {!scopeKey ? <Label
          // circular
          // color='yellow'
          size='tiny'
          content={subCount}
        /> : undefined}
      </Menu.Item>
      {tree.sub?.filter(t => !t.archived).map(t => <TroveMenuItem
        key={t.key}
        node={t}
        onClick={() => handleClick(t.key)}
      />)}
    </Menu>
  </>
})

export default TroveMenu