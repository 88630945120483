import React, { useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, Message } from 'semantic-ui-react'
import { selectWorkspace } from './redux/selector/workspaceSelector'
import { importTrove } from './redux/slice/troveSlice'

type Props = {
  onClose?: () => void
}

const LoadJson = React.memo(({ onClose }: Props) => {
  const dispatch = useDispatch()
  const workspace = useSelector(selectWorkspace)

  const fileReader = useRef<FileReader>()
  const [content, setContent] = useState<string>()
  const [reset, setReset] = useState(false)
  const [error, setError] = useState<string>()

  const handleFileRead = (event: ProgressEvent<FileReader>) => {
    setContent(fileReader.current?.result?.toString())
  }

  const handleFileChosen = useCallback((files: FileList | null) => {
    if (!files) return
    const file = files[0]
    fileReader.current = new FileReader()
    fileReader.current.onloadend = handleFileRead
    fileReader.current.readAsText(file)
  }, [])

  const handleToggleReset = useCallback(() => setReset(!reset), [setReset, reset])

  const handleSubmit = useCallback(() => {
    if (content === undefined || !workspace?.key) return
    if (reset) {
      window.localStorage.setItem(workspace.key, content)
      window.location.reload()
    } else {
      try {
        dispatch(importTrove(content, { forceItems: true }))
      } catch (error) {
        if (error instanceof Error) setError(error.message)
        return
      }
      if (onClose) onClose()
    }
  }, [content, dispatch, onClose, reset, workspace])

  return <>
    {error ? <Message negative>
      {error}
    </Message> : undefined}
    <Message warning={reset} info>
      <p>Importing into <strong>{workspace?.name}</strong>. {reset ? 'All existing data will be overwritten!' : 'The data will be added to your existing trove.'}</p>
      <p>Creating a backup before proceeding is highly recommended.</p>
    </Message>
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <label>Local Backup File</label>
        <input accept='json' type='file' onChange={e => handleFileChosen(e.target.files)} />
      </Form.Field>
      <Form.Checkbox toggle onChange={handleToggleReset} checked={reset} label='Reset All Existing Data' />
      <Button primary disabled={content === undefined} type='submit'>Import</Button>
    </Form>
  </>
})

export default LoadJson
