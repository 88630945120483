import classnames from 'classnames'
import moment from 'moment'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import Emoji from 'react-emoji-render'
import { useDispatch } from 'react-redux'
import { Button, Divider, Grid, Header, Ref, Segment } from 'semantic-ui-react'
import { momentCalendarFormatDate2 } from './data'
import ModalPrompt from './ModalPrompt'
import PlanList from './PlanList'
import { addPlan, PlanUpdates } from './redux/slice/planSlice'
import { PlanningDropResult } from './types/PlanningDropResult'
import { PlanDragType } from './types/PlayDragType'
import { rangeOverlaps } from './util'

type Props = {
  startDate?: Date
  endDate?: Date
  mode: 'week' | 'day' | 'unscheduled'
}

const PlanCalendarDay = ({ startDate, endDate, mode }: Props) => {
  const dispatch = useDispatch()
  const [modal, showModal] = useState<'newPlan'>()
  const ref = useRef<HTMLElement>()

  const current = useMemo(() => {
    if (!startDate || !endDate) return false
    return rangeOverlaps(new Date(), new Date(), startDate, endDate, 'overlaps')
  }, [endDate, startDate])

  const name = useMemo(() => {
    if (mode === 'week') {
      return moment(startDate).format('[Week] ww')
    } else if (mode === 'unscheduled') {
      return 'Unscheduled'
    } else if (mode === 'day') {
      return moment(startDate).calendar(null, momentCalendarFormatDate2)
    }
  }, [startDate, mode])

  const handleAddPlan = useCallback((name: string) => {
    const updates: PlanUpdates = {
      name,
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
      endDate: startDate ?
        moment(mode === 'week' ? endDate : startDate).format('YYYY-MM-DD')
        : undefined,
    }
    dispatch(addPlan({ updates }))
    showModal(undefined)
  }, [dispatch, endDate, startDate, mode])


  const [{ dropping }, drop] = useDrop({
    accept: ['PLAN'],
    drop: (_item: PlanDragType): PlanningDropResult => ({
      startDate,
      endDate: mode === 'week' ? endDate : startDate,
    }),
    collect: monitor => ({
      dropping: !!monitor.isOver() && monitor.canDrop(),
    })
  })

  drop(ref)

  // dropping in week
  return <>
    <Ref innerRef={ref}>
      <Grid.Column
        stretched
        className={classnames({
          'dropping': dropping
        })}
        style={{
          paddingLeft: '0.5em',
          paddingRight: '0.5em',
          paddingTop: '0.5em',
          paddingBottom: '1em',
          backgroundColor: current ? 'rgba(242, 113, 29, 0.05)' : undefined,
        }}
      >
        <div>
          <Header
            // as='h4'
            className={mode !== 'day' ? 'muted' : undefined}
            style={{ marginBottom: 0 }}
            color={current ? 'orange' : undefined}
            content={name}
          />
          <p className='muted'>
            {mode !== 'unscheduled'
              ? <>
                {moment(startDate).format('L')}
                {mode === 'week' ?
                  <>
                    &nbsp;–&nbsp;
                    {moment(endDate).format('L')}
                  </>
                  : undefined
                }
              </>
              : '-'
            }
          </p>
        </div>
        <Divider />
        <Button
          title="Add Checklist"
          icon='plus'
          compact
          basic
          size='tiny'
          onClick={() => showModal('newPlan')}
        />
        <PlanList
          startDate={startDate}
          endDate={endDate}
          calendar
          empty={<Segment textAlign='center' basic>
            <Header size='large'>
              <Emoji text=':sun:' />
            </Header>
          </Segment>}
          mode={mode === 'unscheduled' ? 'unscheduled' : mode === 'week' ? 'overlaps' : 'contains'}
          excludeDayRange={mode === 'week'}
        />
      </Grid.Column>
    </Ref >
    <ModalPrompt
      open={modal === 'newPlan'}
      name='Checklist'
      label='Name'
      icon='clipboard'
      content={
        `
          Give your new checklist a good name.
          ${mode === 'day' || mode === 'week' ? `What do you want to call this ${mode}?` : ``}`
      }
      initialValue={mode === 'day' && startDate ? moment(startDate).format('L') : name}
      onSubmit={handleAddPlan}
      onClose={() => showModal(undefined)}
    />
  </>
}

export default PlanCalendarDay