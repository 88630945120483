import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ButtonProps, Icon, IconProps } from 'semantic-ui-react'
import { TROVE_FILTER_ICONS } from './data'
import { addChildNode, foldNode, incrementFunnelForItem } from './redux/slice/troveSlice'
import { TroveType } from "./redux/types/TroveType"


type TroveItemFoldButtonPropsI = {
  node: TroveType
  buttons?: boolean
  funnelColor?: ButtonProps['color']
  subCount?: number
}

const TroveItemFoldButton = React.memo(({ node, buttons, funnelColor, subCount }: TroveItemFoldButtonPropsI) => {
  const dispatch = useDispatch()

  const handleFold = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(foldNode(node.key, { recursive: event.altKey }))
  }, [dispatch, node.key])

  const handleAdd = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    const key = node.key
    if (!key) return
    dispatch(addChildNode(key))
  }, [dispatch, node.key])

  const handleNodeButton = useCallback((event: React.MouseEvent) => {
    if (subCount) {
      handleFold(event)
    } else {
      handleAdd(event)
    }
  }, [handleAdd, handleFold, subCount])

  const handleFunnel = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(incrementFunnelForItem(node.key, { recursive: event.altKey }))
  }, [dispatch, node.key])

  return <>
    <Button.Group
      size='tiny'
      compact
      basic
    // color={'teal'}
    >
      <Button
        // style={{ backgroundColor: 'white' }}
        onClick={handleNodeButton}
        active={node.folded}
        disabled={node.archived}
        icon
        compact
      // labelPosition='left'
      >
        <Icon
          color={!subCount ? 'grey' : 'black'}
          name={subCount ? (node.folded ? 'chevron right' : 'chevron down') : buttons ? 'plus circle' : 'circle'}
        />
      </Button>
      <Button
        // style={{ backgroundColor: 'white' }}
        active={node.funnel !== undefined}
        // disabled={isArchived}
        // size='tiny'
        compact
        // color={funnelColor}
        // color='grey'
        icon
        // labelPosition={tree.funnel !== undefined ? 'left' : undefined}
        onClick={handleFunnel}
      >
        <Icon
          name={node.funnel !== undefined ? TROVE_FILTER_ICONS[node.funnel] || 'star outline' : 'star outline'}
          color={funnelColor as IconProps['color']}
        // color={tree.funnel !== undefined ? 'yellow' : undefined}
        />
      </Button>
    </Button.Group>
  </>
})

export default TroveItemFoldButton