import { Dialog } from 'electron'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import randomstring from 'randomstring'
import { Workspace } from './redux/slice/workspaceSlice'
const moment = extendMoment(Moment);

declare global {
  interface Window {
    require: (module: 'electron') => {
      dialog: Dialog
    }
  }
}

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

export async function confirmDialog({ message, onConfirm }: { message: string, onConfirm: () => void }) {
  if (process.versions.hasOwnProperty('electron')) {
    const { dialog } = window.require('electron')
    let options = {
      buttons: ['Yes', 'Cancel'],
      message
    }
    const confirm = await dialog.showMessageBox(options)
    if (confirm.response === 0) onConfirm()
  } else {
    const confirm = window.confirm(message)
    if (confirm) onConfirm()
  }
}

const isInViewport = (
  ref: React.RefObject<HTMLElement>,
  { offset = 0 }
) => {
  const top = ref.current?.getBoundingClientRect().top
  if (top === undefined) return {}
  const below = !((top - offset) <= window.innerHeight)
  const above = !((top + offset) >= 0)
  const inViewport = !below && !above
  return { inViewport, above, below }
}

export const executeScroll = (
  ref: React.RefObject<HTMLElement>,
  { offset = 0 },
) => {
  if (!ref.current) return
  const { inViewport, above, below } = isInViewport(ref, { offset: -(offset) })
  if (inViewport) return
  const troveElement = document.getElementById('trove')
  const parentOffsetTop = ref.current.offsetParent?.getBoundingClientRect().top || 0
  const parentScrollTop = troveElement?.scrollTop || 0
  const offsetTop = ref.current.offsetTop + parentOffsetTop + parentScrollTop
  let scroll
  if (below) scroll = offsetTop - window.innerHeight + offset
  if (above) scroll = offsetTop - offset
  if (scroll === undefined) return
  troveElement?.scrollTo(0, scroll)
}

export function getKey() {
  const key = randomstring.generate({
    length: 12,
    charset: 'alphabetic',
  })
  return key
}

export function download(data: any, workspace: Workspace, name = 'all') {
  if (!data) return
  const element = document.createElement("a")
  const file = new Blob([data], { type: 'text/plain' })
  element.href = URL.createObjectURL(file)
  element.download = `troves-backup-${workspace.name.toLowerCase().replace(/\s/, '')}-${name}-${Date.now()}.json`
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}


export function rangeOverlaps(
  refStart: Date,
  refEnd: Date,
  planStart: Date | string,
  planEnd: Date | string,
  mode?: 'overlaps' | 'contains'
) {
  planStart = typeof planStart === 'string' ? moment(planStart).toDate() : planStart
  planEnd = typeof planEnd === 'string' ? moment(planEnd).add(1, 'day').toDate() : planEnd
  const refRange = moment.range(refStart, refEnd)
  const planRange = moment.range(planStart, planEnd)
  return mode === "overlaps"
    ? refRange.overlaps(planRange)
    : refRange.contains(planRange)
}
