import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './redux/reducers'
import { selectActiveItemKey, selectFilterFunnel } from './redux/selector/troveSelector'
import { selectActiveArea } from './redux/selector/workspaceSelector'
import { scopeParentNode, setActiveNode } from './redux/slice/troveSlice'
import { TroveType } from './redux/types/TroveType'
import TroveItemFlat from './TroveItemFlat'

type Props = {
  node: TroveType
}

const TroveItemFunnel = React.memo(({ node }: Props) => {
  const dispatch = useDispatch()
  const filterFunnel = useSelector(selectFilterFunnel)
  const active = useSelector((state: RootState) =>
    selectActiveArea(state) === 'funnel'
    && selectActiveItemKey(state) === node.key
  )

  const handleScope = useCallback((tree: TroveType) => {
    dispatch(scopeParentNode(tree.key))
  }, [dispatch])

  const handleClick = useCallback((event: React.MouseEvent, node: TroveType) => {
    if (!node.key) return
    event.stopPropagation()
    if (active) {
      handleScope(node)
    } else {
      dispatch(setActiveNode(node.key))
    }
  }, [dispatch, active, handleScope])

  return <TroveItemFlat
    key={node.key}
    node={node}
    funnel
    onClick={e => handleClick(e, node)}
    active={active}
    ghost={node.funnel === undefined || node.funnel < (filterFunnel || 0)}
  />
})

export default TroveItemFunnel