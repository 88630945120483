import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, StrictButtonGroupProps } from 'semantic-ui-react'
import { setActivePlan, toggleShowArchived } from './redux/slice/planSlice'
import { PlanType } from './redux/types/PlanType'
import { selectShowItemsArchived } from './redux/selector/planSelector'

interface Props {
  buttonGroupProps?: StrictButtonGroupProps
  plan: PlanType
  onEdit?: () => void
  onArchive?: (event?: React.MouseEvent) => void
  items: ('close' | 'archive' | 'edit' | 'done')[]
  doneCount?: number
}

const PlanButtons: React.FunctionComponent<Props> = ({
  buttonGroupProps, plan, onArchive, onEdit, items, doneCount
}) => {
  const dispatch = useDispatch()
  const showArchived = useSelector(selectShowItemsArchived)

  const archived = !!plan.archivedAt
  const completed = !!plan.completedAt
  const done = archived || completed

  const handleToggleShowArchived = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(toggleShowArchived({ items: true }))
  }, [dispatch])

  const handleDone = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(setActivePlan())
  }, [dispatch])

  return <>
    {items.includes('done') && doneCount ? <Button
      active={showArchived}
      color='green'
      compact
      content={doneCount}
      icon='check'
      onClick={handleToggleShowArchived}
      size='tiny'
    /> : undefined}
    <Button.Group {...buttonGroupProps}>
      {items.includes('edit') ? <Button
        compact
        icon='edit'
        onClick={onEdit}
        title='Edit'
      /> : undefined}
      {items.includes('archive') && (!done || archived) ? <Button
        active={archived}
        compact
        disabled={completed}
        icon='archive'
        onClick={onArchive}
        title={archived ? 'Unarchive' : 'Archive'}
      /> : undefined}
      {items.includes('close') ? <Button
        compact
        icon='close'
        onClick={handleDone}
        title='Close'
      /> : undefined}
    </Button.Group>
  </>
}

export default PlanButtons