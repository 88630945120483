import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Label, LabelProps, Menu, Ref } from 'semantic-ui-react'
import { TROVE_FILTER_COLORS } from './data'
import { selectItem } from './redux/selector/itemSelector'
import { getSubCount, selectScopeKey, selectShowArchived } from './redux/selector/troveSelector'
import { setSideKey } from './redux/slice/troveSlice'
import { toggleSideMenu } from './redux/slice/workspaceSlice'
import { TroveType } from "./redux/types/TroveType"
import { useDrop } from 'react-dnd'
import { ItemDropResult } from './types/ItemDropResult'
import { TroveItemDragType } from './types/TroveItemDragType'
import { PlanItemDragType } from './types/PlanItemDragType'

type Props = {
  node: TroveType
  onClick: () => void
}

const TroveMenuItem = React.memo(({ node, onClick }: Props) => {
  const dispatch = useDispatch()
  const item = useSelector(selectItem(node.key))
  const showArchived = useSelector(selectShowArchived)
  const scopeKey = useSelector(selectScopeKey)
  const ref = useRef<HTMLElement>(null)

  const [{ dropping }, drop] = useDrop({
    accept: ['TROVE_ITEM', 'PLAN_ITEM'],
    canDrop: (_item, monitor) => {
      const item = monitor.getItem() as TroveItemDragType | PlanItemDragType
      return item.key !== node.key
    },
    drop: (): ItemDropResult => ({
      target: node.key,
      area: 'trove',
      isDropChild: true,
    }),
    collect: monitor => ({
      dropping: !!monitor.isOver() && monitor.canDrop(),
    }),
  })


  drop(ref)

  const subCount = useMemo(() =>
    getSubCount({ node, showArchived }),
    [node, showArchived])

  const active = useMemo(() =>
    scopeKey === node.key,
    [scopeKey, node.key])

  const handleSideMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(setSideKey(node.key))
    dispatch(toggleSideMenu({ area: 'sideTrove', active: true }))
  }, [dispatch, node.key])

  return (
    <Ref innerRef={ref}>
      <Menu.Item
        className='overflow-trimmed'
        onDoubleClick={handleSideMenu}
        onClick={onClick}
        active={active || dropping}
      >
        {active ? <Label
          // circular
          size='tiny'
          color={node.funnel !== undefined ? TROVE_FILTER_COLORS[node.funnel] as LabelProps['color'] : undefined}
        >
          {subCount}
        </Label> : undefined}
        {item?.text || 'Untitled'}
      </Menu.Item>
    </Ref>
  )
})

export default TroveMenuItem