import React, { useEffect, useRef, useState, useCallback } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Input, InputOnChangeData } from 'semantic-ui-react'
import { selectSlice as getWorkspaceSlice } from './redux/selector/workspaceSelector'
import { setSearch } from './redux/slice/troveSlice'
import { setActiveArea } from './redux/slice/workspaceSlice'
import { SEARCH_DELAY } from './data'

const GlobalSearchInput = React.memo(() => {
  const dispatch = useDispatch()
  const { activeArea } = useSelector(getWorkspaceSlice)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const inputRef = useRef<any>(null)
  const timerRef = useRef<number>()

  useEffect(() => {
    if (activeArea === 'find') {
      inputRef.current?.inputRef?.current?.select()
    }
  }, [activeArea])

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    event.preventDefault()
    setSearchTerm(data.value)
    setLoading(true)
  }, [])

  const handleFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (!searchTerm) return
    batch(() => {
      dispatch(setActiveArea('find'))
      dispatch(setSearch(searchTerm))
    })
  }, [dispatch, searchTerm])

  const handleEscape = useCallback((event: React.KeyboardEvent) => {
    if (event.keyCode === 27) {
      if (searchTerm) {
        setSearchTerm('')
        dispatch(setSearch())
        setLoading(false)
      } else {
        inputRef.current?.inputRef?.current?.blur()
        dispatch(setActiveArea('trove'))
      }
    }
  }, [dispatch, searchTerm])

  useEffect(() => {
    window.clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(() => {
      setLoading(false)
      dispatch(setSearch(searchTerm))
    }, SEARCH_DELAY)
    return () => window.clearTimeout(timerRef.current)
  }, [searchTerm, dispatch])

  return (
    <Input
      loading={loading}
      ref={inputRef}
      icon='search'
      value={searchTerm}
      placeholder='Search...'
      onChange={handleSearch}
      onFocus={handleFocus}
      onKeyDown={(event: React.KeyboardEvent) => handleEscape(event)}
    />

  )
})

export default GlobalSearchInput