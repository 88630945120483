import classnames from 'classnames'
import 'emoji-mart/css/emoji-mart.css'
import React, { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Container } from 'semantic-ui-react'
import './App.scss'
import Dimmers from './Dimmers'
import ItemListRecent from './ItemListRecent'
import { Migrate } from './migrate/migrate'
import PlanCalendar from './PlanCalendar'
import PlanSideMenu from './PlanSideMenu'
import { selectActiveArea, selectActiveWorkspace, selectSettings, selectSideMenu } from './redux/selector/workspaceSelector'
import { loadSettings, setActiveArea, setWorkspace } from './redux/slice/workspaceSlice'
import ShortcutsWrapper from './ShortcutsMapper'
import TopMenu from './TopMenu'
import Trove from './Trove'
import TroveListSide from './TroveListSide'
import TroveMenu from './TroveMenu'
import ImportDrop from './ImportDrop'

function App() {
  const dispatch = useDispatch()
  const activeWorkspace = useSelector(selectActiveWorkspace)
  const activeArea = useSelector(selectActiveArea)
  const sideMenu = useSelector(selectSideMenu)
  const { background } = useSelector(selectSettings)

  useEffect(() => {
    ReactGA.set({ dimension1: Migrate.appVersion() })
  }, [])

  useEffect(() => {
    ReactGA.pageview(['sideMenu', sideMenu].join('/'))
  }, [sideMenu])

  useEffect(() => {
    if (!window.electron) return
    window.electron.receive('update-checker', (event, argument: 'checking' | 'available' | 'not-available') => {
      console.log('received update-checker', event, argument)
      let message: string | undefined
      if (argument === 'checking') {
        message = 'Checking for updates...'
      } else if (argument === 'available') {
        message = 'Update available, downloading...'
      } else if (argument === 'not-available') {
        message = 'No updates available right now'
      }
      if (!message) return
      if (!document.hasFocus()) return
      toast.info(message, {
        className: 'ui message info',
        pauseOnFocusLoss: false,
      })
    })

    window.electron.receive('pong', event => {
      toast.info('Pong', {
        className: 'ui message info',
        pauseOnFocusLoss: false,
      })
    })
  }, [])

  useEffect(() => {
    if (background) {
      console.log(background)
      document.body.classList.add('background-image')
      if (background.startsWith('http')) {
        document.body.style.backgroundImage = `url(${background})`
        document.body.style.backgroundColor = ''
      } else {
        document.body.style.backgroundColor = background
        document.body.style.backgroundImage = ''
      }
    } else {
      document.body.classList.remove('background-image')
      document.body.style.backgroundColor = ''
      document.body.style.backgroundImage = ''
    }
  }, [background])

  useEffect(() => {
    dispatch(loadSettings())
  }, [dispatch])

  useEffect(() => {
    // fire when workspace changes
    if (activeWorkspace) {
      dispatch(setWorkspace(activeWorkspace))
      dispatch(setActiveArea('trove'))
    }
  }, [dispatch, activeWorkspace])

  return <>
    <ToastContainer
      toastClassName='ui message'
      hideProgressBar
      pauseOnHover
      pauseOnFocusLoss
      closeOnClick
    // className: applied to the container
    // toastClassName: applied on the toast wrapper
    // bodyClassName: applied on the toast body
    // progressClassName: applied on the progress bar
    // style: inline style applied to the container
    />
    <DndProvider backend={Backend}>
      <Dimmers />
      {/* hack, rerender this component when the activearea changes... */}
      <ShortcutsWrapper key={activeArea} />
      <ImportDrop>
        <Container
          id='main'
          className={classnames({ 'side-menu-visible': !!sideMenu })}
          fluid
        >
          <TopMenu />
          <div className='trove-menu'>
            <TroveMenu />
          </div>
          <Trove className='main' />
          {sideMenu === 'sideTrove' ? <TroveListSide className='side-menu' /> : undefined}
          {sideMenu === 'plan' ? <PlanSideMenu className='side-menu' /> : undefined}
          {sideMenu === 'planning' ? <PlanCalendar open={sideMenu === 'planning'} /> : undefined}
          {sideMenu === 'recentItems' ? <ItemListRecent className='side-menu' /> : undefined}
        </Container>
        {/* </Dimmer.Dimmable> */}
      </ImportDrop>
    </DndProvider>
  </>
}

export default App
