import React, { useCallback } from 'react'
import { Button, StrictButtonGroupProps } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { scopeParentNode } from './redux/slice/troveSlice'
import { confirmDialog } from './util'
import { removePlanItem, completePlanItem } from './redux/slice/planSlice'
import { PlanType } from './redux/types/PlanType'
import { PlanItemType } from './redux/types/PlanItemType'

interface Props {
  buttonGroupProps?: StrictButtonGroupProps
  troved?: boolean
  plan: PlanType
  planItem: PlanItemType
  onScope?: () => void
  items: ('scope' | 'archive' | 'complete' | 'delete')[]
}

const PlanItemButtons: React.FunctionComponent<Props> = ({ onScope, buttonGroupProps, troved, plan, planItem, items }) => {
  const dispatch = useDispatch()

  const completed = !!planItem.completedAt
  const archived = !!planItem.archivedAt || (!!plan.archivedAt && !completed)

  const handleScope = useCallback((event?: React.MouseEvent) => {
    event?.stopPropagation()
    dispatch(scopeParentNode(planItem.key))
    if (onScope) onScope()
  }, [dispatch, onScope, planItem.key])

  const handleRemove = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    confirmDialog({
      message: 'Are you sure?',
      onConfirm: () => {
        dispatch(removePlanItem({ key: plan.key, itemKeys: [planItem.key] }))
      }
    })
  }, [dispatch, plan.key, planItem.key])

  const handleDone = useCallback((event: React.MouseEvent, { archive, complete }:
    { archive?: boolean, complete?: boolean } = {}) => {
    event.stopPropagation()
    if (archive !== undefined) {
      dispatch(completePlanItem({ key: plan.key, itemKey: planItem.key, archive }))
    } else if (complete !== undefined) {
      dispatch(completePlanItem({ key: plan.key, itemKey: planItem.key, complete }))
    }
  }, [dispatch, plan.key, planItem.key])

  return <Button.Group {...buttonGroupProps}>
    {items.includes('scope') && troved ? <Button
      compact
      icon='bullseye'
      onClick={handleScope}
      title='Navigate to Trove'
    /> : undefined}
    {items.includes('delete') ? <Button
      compact
      icon='trash'
      onClick={handleRemove}
      title='Delete'
    /> : undefined}
    {items.includes('archive') ? <Button
      active={archived}
      color={archived ? 'green' : undefined}
      compact
      icon='times'
      onClick={e => handleDone(e, { archive: !archived })}
      positive={archived}
      title='Cross Out'
    /> : undefined}
    {items.includes('complete') ? <Button
      active={completed}
      color={completed ? 'green' : undefined}
      compact
      icon='check'
      onClick={e => handleDone(e, { complete: !completed })}
      positive={completed}
      title='Check Off'
    /> : undefined}
  </Button.Group>
}

export default PlanItemButtons