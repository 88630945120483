import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, MenuProps, Step } from 'semantic-ui-react'
import { TROVE_FILTER_COLORS, TROVE_FILTER_ICONS, TROVE_FILTER_TYPES } from './data'
import { selectFilterFunnel } from './redux/selector/troveSelector'
import { setSearch, setFilterFunnel } from './redux/slice/troveSlice'

const FunnelMenu = React.memo(() => {
  const dispatch = useDispatch()
  const filterFunnel = useSelector(selectFilterFunnel)

  const handleShowFunnel = useCallback((funnel?: number) => {
    dispatch(setFilterFunnel(funnel))
    dispatch(setSearch(undefined))
    // if (funnel === undefined) dispatch(setScopeItem(undefined))
  }, [dispatch])

  return <div style={{ marginTop: '1.5em', marginBottom: '1.5em' }}>
    <Step.Group size='small'>
      <Step
        active={true}
        onClick={() => handleShowFunnel()}
      >
        <Step.Content>
          <Step.Title>
            All
          </Step.Title>
        </Step.Content>
      </Step>
      {TROVE_FILTER_TYPES.map((key, index) => (
        <Step
          key={key}
          name={key}
          active={filterFunnel !== undefined && index <= filterFunnel}
          onClick={() => handleShowFunnel(index)}
        // icon='eye'
        >
          {TROVE_FILTER_ICONS[index] ? <Icon
            // size='small'
            color={index === filterFunnel ? TROVE_FILTER_COLORS[index] as MenuProps['color'] : 'grey'}
            name={TROVE_FILTER_ICONS[index]}
          /> : undefined}
          <Step.Content>
            <Step.Title>
              {key}
            </Step.Title>
          </Step.Content>
        </Step>
      ))}
      {/* <Menu.Menu position='right'>
        <Step>
          <Input
            ref={inputRef}
            icon='search'
            value={searchTerm}
            placeholder='Search...'
            onChange={handleSearch}
            onFocus={handleFocus}
          />
        </Step>
      </Menu.Menu> */}
    </Step.Group>
  </div>
})

export default FunnelMenu
