import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../reducers'


export const selectSlice = (state: RootState) => state.workspace
export const selectWorkspaces = createSelector(selectSlice, workspace => workspace.workspaces)
export const selectActiveWorkspace = createSelector(selectSlice, workspace => workspace.activeWorkspace)
export const selectActiveArea = createSelector(selectSlice, workspace => workspace.activeArea)
export const selectSideMenu = createSelector(selectSlice, workspace => workspace.sideMenu)
export const selectVersionChange = createSelector(selectSlice, workspace => workspace.versionChange)
export const selectIsDebug = createSelector(selectSlice, workspace => workspace.isDebug)
export const selectModal = createSelector(selectSlice, workspace => workspace.modal)
export const selectDataVersion = createSelector(selectSlice, workspace => workspace.dataVersion)
export const selectSettings = createSelector(selectSlice, workspace => workspace.settings)

export const selectWorkspace = createSelector(
  [selectWorkspaces, selectActiveWorkspace],
  (workspaces, activeWorkspace) => {
    const workspace = workspaces.find(w => w.key === activeWorkspace)
    return workspace
  }
)
