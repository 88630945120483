import React from 'react'
import { TroveType } from "./redux/types/TroveType"
import TroveItem from './TroveItem'

type TrovePropsI = {
  level: number
  parent: TroveType
  disabled?: boolean
  minimal?: boolean
}

const TroveList = React.memo(({ disabled, parent, level, minimal }: TrovePropsI) => {
  return <>
    {parent.sub?.map(node => <TroveItem
      key={node.key}
      node={node}
      level={level}
      parent={parent}
      disabled={disabled}
      minimal={minimal}
    />)}
  </>
})

export default TroveList