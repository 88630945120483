import { createSelector } from '@reduxjs/toolkit'
import { notUndefined } from '../../util'
import { RootState } from '../reducers'

export const selectSlice = (state: RootState) => state.plan
export const selectPlans = createSelector(selectSlice, plan => plan.plans)
export const selectActivePlanKey = createSelector(selectSlice, plan => plan.active)
export const selectActivePlanItem = createSelector(selectSlice, plan => plan.activeItem)
// export const selectSelectedPlanItems = createSelector(selectSlice, plan => plan.selectedItems)
export const selectShowArchived = createSelector(selectSlice, plan => plan.showArchived)
export const selectShowItemsArchived = createSelector(selectSlice, plan => plan.showItemsArchived)
export const selectEditing = createSelector(selectSlice, plan => plan.editing)

export const showAnyArchived = createSelector(
  [selectShowArchived, selectShowItemsArchived],
  (showArchived, showItemsArchived) => {
    return showArchived || showItemsArchived
  }
)

export const selectActivePlan = createSelector(
  [selectPlans, selectActivePlanKey],
  (plans, active) => {
    if (!active) return
    return plans[active]
  }
)

export const selectFilteredPlans = createSelector(
  [selectPlans, selectShowArchived, selectActivePlanKey],
  (plans, showArchived, active) => {
    const planKeys = Object.keys(plans) as Array<keyof typeof plans>
    return planKeys
      .filter(k => showArchived
        || active === k
        || (!plans[k]?.archivedAt && !plans[k]?.completedAt)
      )
      .map(k => plans[k])
      .filter(notUndefined)
      .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0))
  }
)

export const selectActivePlansCount = createSelector(
  [selectPlans],
  (plans) => {
    const planKeys = Object.keys(plans) as Array<keyof typeof plans>
    return planKeys
      .filter(k => (!plans[k]?.archivedAt && !plans[k]?.completedAt))
      .length
  }
)
