import { Picker } from 'emoji-mart'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, List, Popup } from 'semantic-ui-react'
import { selectItem } from './redux/selector/itemSelector'
import { updateItemSave } from './redux/slice/itemSlice'
import { ItemKey } from './redux/slice/troveSlice'
import { confirmDialog } from './util'
import { setSettings } from './redux/slice/workspaceSlice'

type Props = {
  itemKey: ItemKey
  level?: number
  onComplete: () => void
}
const ItemForm = React.memo(({ itemKey, level = 0, onComplete }: Props) => {
  const dispatch = useDispatch()
  const item = useSelector(selectItem(itemKey))
  const [caret, setCaret] = useState<number>()
  const [showEmoji, setShowEmoji] = useState(false)
  const inputRef = useRef<HTMLInputElement>()
  const saved = useRef<boolean>(false)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select()
      inputRef.current.value = item?.text || ''
    }
  }, [item])

  const handleCancel = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = item?.text || ''
    }
    onComplete()
  }, [item, onComplete])

  const handleCommand = useCallback((text: string) => {
    // fun experimental
    const [, command, commandValue] = text.match(/^\/(\w+)\s(.+)/) || []
    const settingValue = commandValue && commandValue.trim() !== 'clear' ? commandValue.trim() : null
    if (command === 'shield') {
      dispatch(setSettings({ shield: settingValue }))
    } else if (command === 'background') {
      dispatch(setSettings({ background: settingValue }))
    }
  }, [dispatch])

  const handleSave = useCallback(() => {
    if (saved.current) return
    if (!inputRef.current) return
    const text = inputRef.current.value || ''
    if (text === (item?.text || '')) return
    dispatch(updateItemSave({ key: itemKey, updates: { text }, attributes: ['text'] }))
    if (text.match(/^\/\w/)) handleCommand(text)
    saved.current = true
    onComplete()
  }, [dispatch, handleCommand, item, itemKey, onComplete])

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 27) {
      const text = inputRef.current?.value || ''
      if (text === (item?.text || '')) handleCancel()
      else {
        confirmDialog({
          message: 'Do you want to cancel your edits?',
          onConfirm: handleCancel
        })
      }
    }
  }, [handleCancel, item])

  const handleCaret = useCallback(() => {
    let caret = inputRef.current?.selectionStart
    caret = caret === null ? undefined : caret
    setCaret(caret)
  }, [])

  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onComplete()
  }, [onComplete])

  const handleEmojiSelect = useCallback((emoji: any, event?: React.MouseEvent) => {
    event?.stopPropagation()
    event?.preventDefault()
    if (!inputRef.current) return
    const text = inputRef.current.value
    if (caret !== undefined) {
      inputRef.current.value = [text.slice(0, caret), emoji.native, text.slice(caret)].join('')
    } else {
      inputRef.current.value = text + emoji.native
    }
    setShowEmoji(false)
    inputRef.current?.focus()
    if (caret !== undefined) {
      setImmediate(() => {
        const caret2 = caret + emoji.native.length
        setCaret(caret2)
        inputRef.current?.setSelectionRange(caret2, caret2)
      })
    }
  }, [caret])

  useEffect(() => {
    return () => handleSave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <List.Item
      style={{ paddingLeft: level > 0 ? `${(level * 1.2) + 0.6}em` : undefined }}
      colSpan={2}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Input
          input={{ ref: inputRef }}
          required={true}
          error={false}
          onKeyDown={handleKeyDown}
          onKeyUp={handleCaret}
          onClick={handleCaret}
          type='text'
          // actionPosition='left'
          action={(
            <>
              <Popup
                on='click'
                open={showEmoji}
                trigger={
                  <Button
                    // compact
                    color='blue'
                    type='button'
                    onClick={() => setShowEmoji(!showEmoji)}
                    icon='smile'
                  />
                }
              >
                <Picker
                  // onClick={handleEmojiClick}
                  onSelect={handleEmojiSelect}
                  autoFocus
                  emoji='trophy'
                  // showPreview={false}
                  // showSkinTones={false}
                  // emojiTooltip
                  title='Pick your emoji…'
                />
              </Popup>
              <Button
                color='blue'
                icon='check'
                onClick={handleSave}
              />
            </>
          )}
        />
      </Form>
    </List.Item>
  )
})

export default ItemForm
