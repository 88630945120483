import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import ModalPrompt from './ModalPrompt'
import { selectActivePlanKey as getActivePlan } from './redux/selector/planSelector'
import { selectSideMenu } from './redux/selector/workspaceSelector'
import { addActivePlanItem } from './redux/slice/planSlice'
import { addChildNode, archiveItem, setActiveNode, setScopeKey, setSideKey, toggleEditing } from './redux/slice/troveSlice'
import { toggleSideMenu } from './redux/slice/workspaceSlice'
import { TroveType } from "./redux/types/TroveType"
import { confirmDialog, notUndefined } from './util'

type TroveItemButtonsPropsI = {
  node: TroveType
  add?: boolean
  archive?: boolean
}

const TroveItemButtons = React.memo(({ node, add, archive }: TroveItemButtonsPropsI) => {
  const dispatch = useDispatch()
  const activePlan = useSelector(getActivePlan)
  const sideMenu = useSelector(selectSideMenu)

  const [modal, showModal] = useState<'import'>()

  const handleArchive = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    batch(() => {
      dispatch(archiveItem(node.key, { recursive: true }))
      dispatch(setActiveNode(node.key))
    })
  }, [dispatch, node.key])

  const showSideMenu = useCallback(() => {
    batch(() => {
      dispatch(setSideKey(node.key))
      dispatch(toggleSideMenu({ area: 'sideTrove', active: true }))
    })
  }, [dispatch, node.key])

  const handleScope = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    if (event.altKey) {
      showSideMenu()
    } else {
      dispatch(setScopeKey(node.key))
    }
  }, [dispatch, node.key, showSideMenu])

  const handleAdd = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    if (event.altKey) {
      showModal('import')
    } else {
      dispatch(addChildNode(node.key))
    }
  }, [dispatch, node.key])

  const handleEdit = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    batch(() => {
      dispatch(setActiveNode(node.key))
      dispatch(toggleEditing(true))
    })
  }, [dispatch, node.key])

  const handleImport = useCallback((value: string) => {
    const lines = value
      .split("\n")
      .filter(line => line.trim())
      .filter(notUndefined)
    showModal(undefined)
    if (!lines.length) return
    confirmDialog({
      message: `Are you sure you want to add ${lines.length} items?`,
      onConfirm: () => {
        lines.forEach(line => {
          dispatch(addChildNode(node.key, { text: line }))
        })
      }
    })
  }, [dispatch, node.key])

  const handleAddPlan = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(addActivePlanItem({ key: node.key }))
  }, [dispatch, node.key])

  return <>
    {modal === 'import' ? <ModalPrompt
      open={modal === 'import'}
      onClose={() => showModal(undefined)}
      onSubmit={handleImport}
      name='Trove Items'
      label='Text'
      icon='clipboard list'
      content='Copy Paste your text in the field below, with every new Item on a new line. For each line, a new Item will be created.'
      textarea
    /> : undefined}
    <Button.Group
      compact
      basic
      size='tiny'
    >
      {archive ? <Button
        onClick={handleArchive}
        icon
        compact
        title={node.archived ? 'Unarchive' : 'Archive'}
        active={node.archived}
      >
        <Icon name='archive' />
      </Button> : undefined}
      {!node.archived ? <Button
        title='Scope'
        disabled={node.archived}
        icon
        compact
        onClick={handleScope}
      >
        <Icon name='bullseye' />
      </Button> : undefined}
      {add && !node.archived ? <Button
        disabled={node.archived}
        title='Edit'
        icon='edit'
        compact
        onClick={handleEdit}
      /> : undefined}
      {add && !node.archived ? <Button
        disabled={node.archived}
        title='Add Child'
        icon
        compact
        onClick={handleAdd}
      >
        <Icon name='add' />
      </Button> : undefined}
      {!!activePlan && sideMenu === 'plan' && !node.archived ? <Button
        title='Add to Checklist'
        disabled={node.archived}
        icon
        compact
        onClick={handleAddPlan}
      >
        <Icon name='clipboard check' />
      </Button> : undefined}
    </Button.Group>
  </>
})

// const ModalNote: React.FunctionComponent = ({ children }) => {  
//   return (
//     <Modal trigger={children} size='small'>
//       <Header icon='sticky note' content='Note' />
//       <Modal.Content>

//       </Modal.Content>
//     </Modal>
//   )
// }

export default TroveItemButtons