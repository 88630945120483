import React from 'react'
import { Header, Modal } from 'semantic-ui-react'
import LoadJson from './LoadJson'

export type Props = {
  open: boolean
  onClose?: () => void
}

const ModalImport = ({ open, onClose }: Props) => (
  <Modal onClose={onClose} open={open} size='small'>
    <Header icon='upload' content='Import Backup' />
    <Modal.Content>
      <LoadJson onClose={onClose} />
    </Modal.Content>
  </Modal>
)

export default ModalImport