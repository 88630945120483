import React, { useCallback, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { Dimmer, Header, Icon } from 'semantic-ui-react'
import { importTrove } from './redux/slice/troveSlice'

const ImportDrop: React.FunctionComponent = React.memo(({ children }) => {
  const dispatch = useDispatch()
  const fileReader = useRef<FileReader>()

  const handleFileRead = useCallback((event: ProgressEvent<FileReader>) => {
    const content = fileReader.current?.result?.toString()
    if (!content) return
    try {
      dispatch(importTrove(content, { forceItems: true }))
    } catch (error) {
      if (error instanceof Error) window.alert(error.message)
      return
    }
  }, [dispatch])

  const handleFileChosen = useCallback((files: FileList | null) => {
    if (!files) return
    const file = files[0]
    if (!file) return
    fileReader.current = new FileReader()
    fileReader.current.onloadend = handleFileRead
    fileReader.current.readAsText(file)
  }, [handleFileRead])

  const onDrop = useCallback(acceptedFiles => {
    handleFileChosen(acceptedFiles)
    // Do something with the files
  }, [handleFileChosen])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'application/json',
    // multiple: false,
    noKeyboard: true,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Dimmer.Dimmable dimmed={isDragActive}>
        {children}
        <Dimmer style={{ position: 'fixed' }} active={isDragActive}>
          <Header as='h2' icon inverted>
            <Icon name='upload' />
            Drop to Import File
          </Header>
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  )
})

export default ImportDrop
