import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IconProps, Label, List } from 'semantic-ui-react'
import { TROVE_FILTER_COLORS, TROVE_FILTER_ICONS } from './data'
import { selectItem } from './redux/selector/itemSelector'
import { selectIsDebug } from './redux/selector/workspaceSelector'
import { TroveType } from "./redux/types/TroveType"
import SubcountView from './SubcountView'
import TroveItemButtons from './TroveItemButtons'
import TroveItemContent from './TroveItemContent'
import TroveItemFoldButton from './TroveItemFoldButton'


type TroveItemRowPropsI = {
  node: TroveType
  parent?: TroveType
  basic?: boolean
  level: number
  buttons?: boolean
  // active?: boolean
  minimal?: boolean
  subCount?: number
}

const TroveItemRow = React.memo(({ node, parent, level, minimal, buttons, subCount }: TroveItemRowPropsI) => {
  const item = useSelector(selectItem(node.key))
  const isDebug = useSelector(selectIsDebug)

  const funnelColor = useMemo(() => {
    return node.funnel !== undefined && !node.archived ? TROVE_FILTER_COLORS[node.funnel] : undefined
  }, [node])

  return <>
    {!minimal && buttons ? <List.Content
      className='floated-absolute'
      verticalAlign='top'
      floated='right'>
      <TroveItemButtons
        node={node}
        archive={!parent?.archived}
        add={!parent?.archived}
      />
    </List.Content> : undefined}
    {minimal
      ? <List.Icon
        name={node.funnel !== undefined ? TROVE_FILTER_ICONS[node.funnel] : 'circle'}
        color={node.funnel !== undefined ? TROVE_FILTER_COLORS[node.funnel] as IconProps['color'] : undefined}
        verticalAlign='top'
      />
      : <List.Icon
        verticalAlign='top'
        style={{ paddingLeft: level > 0 ? `${(level * 1.2) + 0.6}em` : undefined }}
      >
        <TroveItemFoldButton
          node={node}
          subCount={subCount}
          // basic={basic}
          // active={active}
          buttons={buttons}
          funnelColor={funnelColor}
        />
      </List.Icon>
    }
    <List.Content verticalAlign='middle'>
      <TroveItemContent
        item={item}
        level={level}
        archived={node.archived}
      />
      {subCount && (node.folded || minimal) ? <SubcountView
        count={subCount + 1}
      /> : undefined}
      {isDebug ? <Label size='tiny'>{node.key}</Label> : undefined}
    </List.Content>
  </>
})

export default TroveItemRow