import React, { useState, useCallback, useEffect } from 'react'
import { Modal, Form, Message, Icon, Button } from "semantic-ui-react"
import axios from 'axios'

interface Props {
  open?: boolean
  onClose?: () => void
}

const SendFeedbackModal: React.FunctionComponent<Props> = ({ open, onClose }) => {
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<'email' | 'subject' | 'message'>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEmail(window.localStorage.getItem('email') || '')
  }, [])

  const handleSubmit = useCallback(async () => {
    setLoading(true)
    try {
      await axios.post('https://api.holygrailapp.com/v1/sendFeedback', {
        from: email, subject, text: message
      })
      setLoading(false)
      setError(undefined)
      setSuccess(true)
      setSubject('')
      setMessage('')
      window.localStorage.setItem('email', email)
    } catch (error) {
      setError('email')
      setLoading(false)
      console.error(error)
    }
  }, [email, subject, message])

  return (
    <Modal size='small' open={open} onClose={onClose}>
      <Modal.Header>
        Send Feedback
    </Modal.Header>
      <Modal.Content>
        <p>Send us a message, we'll reply to you by email. Thanks!</p>
        <Form onSubmit={handleSubmit} noValidate error={error === 'email'} success={success}>
          <Message success>
            <Icon name='check' />
            Your message is sent, we delivered a copy of it to your inbox as well.
          </Message>
          <Message error>
            Something went wrong, make sure you entered your email correctly or <a href='mailto:info@holygrailapp.com'>contact us</a>.
          </Message>
          <Form.Input
            required
            type='email'
            placeholder='Your Email'
            onChange={e => {
              setEmail(e.target.value)
              setError(undefined)
            }}
            error={error === 'email'}
            value={email}
          />
          <Form.Input
            required
            type='text'
            placeholder='Bug, Idea, Question, etc.'
            onChange={e => {
              setSubject(e.target.value)
              setError(undefined)
            }}
            error={error === 'subject'}
            value={subject}
          />
          <Form.TextArea
            required
            type='message'
            placeholder='Your message'
            onChange={(e, { value }) => {
              setMessage(value?.toString() || '')
              setError(undefined)
            }}
            error={error === 'message'}
            value={message}
          />
          <Button
            primary
            type='submit'
            loading={loading}
            disabled={!email || !subject || !message}
            onClick={handleSubmit}
            icon='envelope'
            content='Send'
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default SendFeedbackModal