import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Search, SearchProps, SearchResultsProps } from 'semantic-ui-react'
import { selectQuickSearchResult } from './redux/selector/troveSelector'
import { setQuickSearch } from './redux/slice/troveSlice'
import { SearchResultType } from './redux/types/SearchResultType'
import { confirmDialog } from './util'
import { SEARCH_DELAY } from './data'

type Props = {
  onSelect: ({ key, text }: { key?: string, text?: string }) => void
  setActive: (active: boolean) => void
  active?: boolean
}

const PlanItemAdd = ({ onSelect, active, setActive }: Props) => {
  const dispatch = useDispatch()
  const searchResult = useSelector(selectQuickSearchResult)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const inputRef = useRef<HTMLInputElement>()
  const timerRef = useRef<number>()

  const results: SearchResultType[] = useMemo(() => {
    const addResult: SearchResultType = {
      title: searchTerm,
      description: 'Add New Item',
    }
    return [addResult].concat(searchResult)
  }, [searchTerm, searchResult])

  const handleSearchChange = useCallback((data: SearchProps) => {
    setSearchTerm(data.value || '')
    setLoading(true)
  }, [])

  useEffect(() => {
    window.clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(() => {
      setLoading(false)
      // if ((searchTerm?.length || 0) >= 2) 
      dispatch(setQuickSearch(searchTerm))
    }, SEARCH_DELAY)
    return () => window.clearTimeout(timerRef.current)
  }, [searchTerm, dispatch])

  const onClear = useCallback(() => {
    dispatch(setQuickSearch())
    setSearchTerm('')
  }, [dispatch])

  const handleSearchSelect = useCallback((data: SearchResultsProps) => {
    const result = data.result as SearchResultType
    onSelect(result.key ? { key: result.key } : { text: searchTerm })
    onClear()
  }, [onSelect, onClear, searchTerm])

  const handleEscape = useCallback((event: React.KeyboardEvent) => {
    if (event.keyCode === 27) {
      if (searchTerm) {
        confirmDialog({
          onConfirm: () => {
            onClear()
            inputRef.current?.blur()
          },
          message: 'Discard new item?'
        })
      } else {
        setActive(false)
      }
    }
  }, [onClear, searchTerm, setActive])

  useEffect(() => {
    if (active) inputRef.current?.focus()
    else inputRef.current?.blur()
  }, [active])

  return <>
    <Search
      selectFirstResult
      fluid
      loading={loading}
      icon={!active ? 'plus' : undefined}
      placeholder='Add...'
      input={{
        fluid: true,
        input: {
          style: {
            border: 0,
          },
          ref: inputRef,
          onKeyDown: (event: React.KeyboardEvent) => handleEscape(event),
          onFocus: () => setActive(true),
          onBlur: () => setActive(false),
        },
      }}
      onResultSelect={(event, data) => handleSearchSelect(data)}
      onSearchChange={(event, data) => handleSearchChange(data)}
      results={results}
      value={searchTerm}
    />
  </>
}

export default PlanItemAdd