
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb, Segment } from 'semantic-ui-react'
import { RootState } from './redux/reducers'
import { selectItem, selectItems } from './redux/selector/itemSelector'
import { selectActiveItemKey, selectRootKey, selectScopedParents, selectScopedTree } from './redux/selector/troveSelector'
import { selectWorkspace } from './redux/selector/workspaceSelector'
import { ItemKey, setScopeKey } from './redux/slice/troveSlice'
import { ItemType } from "./redux/types/ItemType"
import { TroveType } from "./redux/types/TroveType"
import Wrap from './Wrap'

const ScopeMenu = React.memo(() => {
  const dispatch = useDispatch()
  const scopedTree = useSelector(selectScopedTree)
  const workspace = useSelector(selectWorkspace)
  const parents = useSelector(selectScopedParents)
  const rootKey = useSelector(selectRootKey)
  const active = useSelector((state: RootState) =>
    scopedTree.key === selectActiveItemKey(state)
  )
  const items = useSelector(selectItems)
  const item = useSelector(selectItem(scopedTree.key))

  const handleScope = useCallback((itemKey: ItemKey) => {
    dispatch(setScopeKey(itemKey))
  }, [dispatch])

  const getName = useCallback((t: TroveType, i?: ItemType) => {
    return (t.key === rootKey ? workspace?.name : i?.text) || '?'
  }, [workspace, rootKey])

  return (
    <Segment attached='top' style={{ marginTop: '0.5em' }} >
      {/* <Button.Group size='tiny'
        compact
        floated='right'
      >
        <Button
          size='tiny'
          compact
          icon='plus'
          content='New Trove'
        // onClick={handleToggleShowArchived}
        />
      </Button.Group> */}
      <Breadcrumb size='huge'>
        {parents?.map(parent => {
          const item2 = items[parent.key]
          return <React.Fragment key={parent.key}>
            <Breadcrumb.Section
              className='overflow-trimmed'
              onClick={() => handleScope(parent.key)}
              link
            >
              {getName(parent, item2)}
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon='right chevron' />
          </React.Fragment>
        })}
        <Breadcrumb.Section className='overflow-trimmed' active>
          <Wrap element='u' active={active}>{getName(scopedTree, item)}</Wrap>
        </Breadcrumb.Section>
      </Breadcrumb>
      {/* <Divider /> */}
    </Segment>
  )
})


export default ScopeMenu