import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Button, Form, Header, IconProps, Message, Modal } from 'semantic-ui-react'

type Props = {
  open: boolean
  onClose?: () => void
  onSubmit: (name: string) => void
  name: string
  label: string
  content?: string
  icon: IconProps['name']
  textarea?: boolean
  initialValue?: string
  edit?: boolean
}

const ModalPrompt = React.memo(({
  open,
  onClose,
  onSubmit,
  name,
  label,
  icon,
  content,
  textarea,
  initialValue,
  edit
}: Props) => {
  const [inputValue, setInputValue] = useState(initialValue || '')
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (open) {
      inputRef.current?.select()
      setInputValue(initialValue || '')
    }
  }, [open, initialValue])

  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault()
    onSubmit(inputValue)
  }, [onSubmit, inputValue])

  const handleCreate = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    onSubmit(inputValue)
  }, [onSubmit, inputValue])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputValue(event.target.value)
  }, [])

  const handleClose = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    setInputValue('')
    if (onClose) onClose()
  }, [onClose])

  return (
    <Modal onClose={onClose} open={open} size='small'>
      <Header icon={icon} content={`${edit ? 'Edit' : 'New'} ${name}`} />
      <Modal.Content>
        {content ? <Message info>{content}</Message> : undefined}
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>{label}</label>
            {textarea
              ? <textarea
                ref={inputRef}
                value={inputValue}
                onChange={handleChange}
              />
              : <input
                ref={inputRef}
                type='text'
                value={inputValue}
                onChange={handleChange}
              />
            }
          </Form.Field>
          <Button type='button' onClick={handleClose}>
            Cancel
          </Button>
          <Button type='submit' onClick={handleCreate} disabled={!inputValue} primary>
            {edit ? 'Update' : 'Create'} {name}
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  )
})

export default ModalPrompt
