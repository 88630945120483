import { WorkspaceData } from "../redux/slice/workspaceSlice"
import { ItemType } from "../redux/types/ItemType"

type WorkspaceData_0_1_6 = {
  key: string
  parent?: string
  text: string
  body?: string
  // parent: string
  archived?: boolean
  sub?: WorkspaceData_0_1_6[]
  subCount?: number
  // filter?: TroveFilterType[]
  funnel?: number
  folded?: boolean
  updatedAt?: number
  createdAt?: number
  show?: boolean
}

export const migrations = {
  // the version number indicates the version of the app the migration is required for
  // '0.1.4': (data: WorkspaceData_0_1_5) => {
  //   data.sub?.forEach(d => d.funnel = 2)
  //   return data
  // },
  '0.1.6': (data: WorkspaceData_0_1_6) => {
    // moved items out of the tree
    function getItems(
      data: WorkspaceData_0_1_6,
      items: WorkspaceData['items'] = {}
    ) {
      const item: ItemType = {
        key: data.key,
        text: data.text,
        updatedAt: data.updatedAt,
        createdAt: data.createdAt,
      }
      items[data.key] = item
      data.sub?.forEach(d => {
        getItems(d, items)
      })
      return items
    }
    function getTree(
      data: WorkspaceData_0_1_6,
      tree: WorkspaceData['tree'] = { key: '' },
    ) {
      tree.key = data.key
      // tree.parent = data.parent || null
      tree.funnel = data.funnel
      tree.folded = data.folded
      tree.archived = data.archived
      tree.updatedAt = data.updatedAt
      tree.createdAt = data.createdAt
      data.sub?.forEach(d => {
        if (!tree.sub) tree.sub = []
        tree.sub.push(getTree(d))
      })
      return tree
    }
    const tree = getTree(data)
    const items = getItems(data)
    const newData: WorkspaceData = { tree, items }
    return newData
  },
  // '0.1.7': (data: WorkspaceData) => {
  //   return data
  // },
}
