import React, { FunctionComponent } from 'react'
import ReactLinkify from 'react-linkify'

const LinkifyWithDecorator: FunctionComponent = ({ children }) => {
  const handleClick = (e: React.MouseEvent) => e.stopPropagation()
  const decorator = (href: string, text: string, key: number) => (
    <a
      onClick={handleClick}
      href={href}
      key={key}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  )
  return (
    <ReactLinkify componentDecorator={decorator}>
      {children}
    </ReactLinkify>
  )
}

export default LinkifyWithDecorator