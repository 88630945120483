import React, { useCallback, useMemo, useRef } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Header, List, Segment, Icon } from 'semantic-ui-react'
import { getSubCount, selectShowArchived, selectSideItem, selectSideTree } from './redux/selector/troveSelector'
import { setScopeKey, setSideKey } from './redux/slice/troveSlice'
import { toggleSideMenu } from './redux/slice/workspaceSlice'
import TroveList from './TroveList'

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

const TroveListSide = React.memo(({ className }: Props) => {
  const dispatch = useDispatch()
  const sideTree = useSelector(selectSideTree)
  const showArchived = useSelector(selectShowArchived)
  const sideItem = useSelector(selectSideItem)
  const ref = useRef<HTMLDivElement>(null)

  const subCount = useMemo(() =>
    sideTree ? getSubCount({ node: sideTree, showArchived }) : undefined,
    [sideTree, showArchived])

  const handleClose = useCallback(() => {
    batch(() => {
      dispatch(setSideKey())
      dispatch(toggleSideMenu({}))
    })
  }, [dispatch])

  const handleScope = useCallback(() => {
    dispatch(setScopeKey(sideTree?.key))
  }, [dispatch, sideTree])

  return <div ref={ref} tabIndex={-1} className={className}>
    <Header>
      <span>
        <Icon color='grey' name='bullseye' />
        {sideItem?.text || '?'}
      </span>
      <Button.Group size='tiny' compact floated='right'>
        <Button
          size='tiny'
          icon='bullseye'
          content='Scope'
          onClick={handleScope}
          compact
        />
        <Button
          size='tiny'
          icon='times'
          // content='Close'
          onClick={handleClose}
          compact
        />
      </Button.Group>
    </Header>
    <Divider />
    {
      !sideTree || !subCount
        ? <Segment>
          <p>No items here.</p>
        </Segment>
        : <Segment style={{ padding: 0 }}>
          <List
            // size='large'
            divided
            relaxed
            selection
          >
            <TroveList parent={sideTree} level={0} minimal />
          </List>
        </Segment>
    }
  </div >
})

export default TroveListSide