// https://www.mattbutton.com/redux-made-easy-with-redux-toolkit-and-typescript/
import { combineReducers } from "@reduxjs/toolkit"
import trove from './slice/troveSlice'
import item from './slice/itemSlice'
import workspace from './slice/workspaceSlice'
import plan from './slice/planSlice'

const rootReducer = combineReducers({ trove, item, workspace, plan })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
