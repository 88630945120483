import React, { useCallback, useRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Header, Icon, Message } from 'semantic-ui-react'
import PlanForm from './PlanForm'
import PlanList from './PlanList'
import { selectShowArchived } from './redux/selector/planSelector'
import { toggleEditing, toggleShowArchived } from './redux/slice/planSlice'
import { setActiveArea, toggleSideMenu } from './redux/slice/workspaceSlice'
import moment from 'moment'
import { momentCalendarFormatDate } from './data'
import useInterval from './useInterval'

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

const PlanSideMenu = ({ className }: Props) => {
  const dispatch = useDispatch()
  const showArchived = useSelector(selectShowArchived)
  const timestamp = useInterval()

  const [modal, showModal] = useState<'newPlan'>()
  const [mode, setMode] = useState<'day' | 'week' | 'unscheduled' | 'all'>('day')
  const [offset, setOffset] = useState(0)

  const handleDone = useCallback(() => {
    dispatch(toggleSideMenu({ area: 'plan' }))
  }, [dispatch])

  const handleToggleShowArchived = useCallback((event: React.MouseEvent) => {
    dispatch(toggleShowArchived({ plans: true }))
    showModal(undefined)
  }, [dispatch])

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    dispatch(setActiveArea('plan'))
    dispatch(toggleEditing(false))
  }, [dispatch])

  const [startDate, endDate] = useMemo(() => {
    if (mode === 'day') {
      return [
        moment(timestamp).startOf('day').add(offset, 'day').toDate(),
        moment(timestamp).startOf('day').add(1 + offset, 'day').toDate()
      ]
    } else if (mode === 'week') {
      return [
        moment(timestamp).startOf('week').add(offset, 'week').toDate(),
        moment(timestamp).endOf('week').add(offset, 'week').toDate()
      ]
    } else {
      return []
    }
  }, [mode, offset, timestamp])


  const ref = useRef<HTMLDivElement>(null)

  return <div onClick={handleClick} ref={ref} tabIndex={-1} className={className}>
    <Header>
      <span>
        <Icon color='grey' name='clipboard list' />
        Checklists
      </span>
      <Button.Group size='tiny'
        compact
        floated='right'
      >
        <Button
          icon='plus'
          size='tiny'
          compact
          onClick={() => showModal('newPlan')}
          title='New Checklist'
        />
        <Button
          size='tiny'
          active={showArchived}
          compact
          icon={showArchived ? 'eye' : 'eye slash'}
          title='Show/Hide Completed Checklists'
          onClick={handleToggleShowArchived}
        />
        <Button
          size='tiny'
          compact
          icon='close'
          content='Close'
          onClick={handleDone}
        // title='Close'
        >
        </Button>
      </Button.Group>
    </Header>
    <Divider />
    <Button.Group
      fluid
      size='tiny'
      compact
      // basic
    >
      <Button
        icon='chevron left'
        onClick={() => setOffset(offset - 1)}
        compact
        disabled={mode === 'unscheduled' || mode === 'all'}
      />
      <Button
        content={mode === 'day' ? moment(startDate).calendar(null, momentCalendarFormatDate) : 'Day'}
        active={mode === 'day'}
        color={mode === 'day' && offset === 0 ? 'orange' : undefined}
        onClick={() => {
          setMode('day')
          setOffset(0)
        }}
      />
      <Button
        content={mode === 'week' ? moment(startDate).format('[Week] ww') : 'Week'}
        active={mode === 'week'}
        color={mode === 'week' && offset === 0 ? 'orange' : undefined}
        onClick={() => {
          setMode('week')
          setOffset(0)
        }}
      />
      <Button
        content='Unscheduled'
        active={mode === 'unscheduled'}
        onClick={() => {
          setMode('unscheduled')
          setOffset(0)
        }}
      />
      <Button
        content='All'
        active={mode === 'all'}
        onClick={() => {
          setMode('all')
          setOffset(0)
        }}
      />
      <Button
        icon='chevron right'
        onClick={() => setOffset(offset + 1)}
        compact
        disabled={mode === 'unscheduled' || mode === 'all'}
      />
    </Button.Group>
    <PlanList
      startDate={startDate}
      endDate={endDate}
      mode={mode === 'all' ? undefined : mode === 'unscheduled' ? 'unscheduled' : mode === 'week' ? 'overlaps' : 'contains'}
      // excludeDayRange={mode === 'week'}
      empty={<Message info>
        <p>No Uncompleted Checklists</p>
        <Button
          compact
          size='tiny'
          color='blue'
          icon='plus'
          content='New Checklist'
          onClick={() => showModal('newPlan')}
        />
      </Message>}
    />
    <PlanForm
      open={modal === 'newPlan'}
      onClose={() => showModal(undefined)}
      startDate={mode === 'day' || mode === 'week' ? moment(startDate).format('YYYY-MM-DD') : undefined}
      endDate={mode === 'week' ? moment(endDate).format('YYYY-MM-DD') : undefined}
    />
  </div>
}


export default PlanSideMenu