import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Button, Header, Icon, Message } from 'semantic-ui-react'
import { Migrate } from './migrate/migrate'
import { selectActivePlanItem as getActivePlanItem, selectActivePlanKey as getActivePlan } from './redux/selector/planSelector'
import { selectActiveItemKey } from './redux/selector/troveSelector'
import { selectIsDebug, selectSideMenu, selectSlice as selectWorkspaceSlice, selectWorkspace } from './redux/selector/workspaceSelector'
import { saveLocal } from './redux/slice/workspaceSlice'
import { download } from './util'


const Messages = React.memo(() => {
  const activeItemTrove = useSelector(selectActiveItemKey)
  const { activeArea, activeWorkspace, versionChange, saved } = useSelector(selectWorkspaceSlice)
  const isDebug = useSelector(selectIsDebug)
  const workspace = useSelector(selectWorkspace)
  const activePlanItem = useSelector(getActivePlanItem)
  const activePlan = useSelector(getActivePlan)
  const sideMenu = useSelector(selectSideMenu)
  const dispatch = useDispatch()

  const handleSave = useCallback(() => {
    dispatch(saveLocal({ migrate: true }))
    window.location.reload() // maybe not strictly needed
  }, [dispatch])

  const handleBackup = useCallback(() => {
    if (!workspace) return
    const data = window.localStorage.getItem(workspace.key)
    download(data, workspace)
  }, [workspace])

  useEffect(() => {
    toast.info(<span>
      Press <kbd>⌘</kbd><kbd>?</kbd> to view the keyboard shortcuts.
    </span>, {
      className: 'ui message info',
    })
  }, [])

  const migration = versionChange === 1
  return <>
    {isDebug ? <Message negative>
      <Header>Debug Inspector</Header>
      <p>Workspace: {activeWorkspace}</p>
      <p>Active trove: {activeItemTrove}</p>
      <p>Active plan: {activePlan} / {activePlanItem}</p>
      <p>Area: {activeArea}</p>
      <p>Side: {sideMenu}</p>
      {window.electron ? <Button
        onClick={() => window.electron.send('ping')}
        content='Ping'
      /> : undefined}
    </Message> : undefined}
    {!saved ? <Message warning>
      <Header size='large'>
        <Icon name='warning circle' />
        {migration ? 'Migrate Trove?' : 'Unsaved'}
      </Header>
      {migration
        ? <p>We've updated your troves to version <strong>{Migrate.appVersion()}</strong> of the app. Make sure everything looks good, and save the new version.</p>
        : <p>We've imported the Trove. Make sure everything looks good, and save the changes.</p>
      }
      <p>We recommend making a backup of the data through the "Develop" menu.</p>
      <Button onClick={handleSave} primary>Looks Good</Button>
      <Button onClick={handleBackup}>
        <Icon name='save' />
        Create Backup
      </Button>
    </Message> : undefined}
  </>
})

export default Messages
