import React from 'react'

type Props = {
  condition: boolean
  wrap: (children: JSX.Element) => JSX.Element;
  wrapAlt?: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

const ConditionalWrapper: React.FunctionComponent<Props> = ({ condition, wrap, wrapAlt, children }) =>
  condition
    ? React.cloneElement(wrap(children))
    : wrapAlt
      ? React.cloneElement(wrapAlt(children))
      : children

export default ConditionalWrapper