import classnames from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { IconProps, List, Ref } from 'semantic-ui-react'
import { TROVE_FILTER_COLORS, TROVE_FILTER_ICONS } from './data'
import { selectItem } from './redux/selector/itemSelector'
import { getParentNodes, getSubCount, selectShowArchived, selectTree } from './redux/selector/troveSelector'
import { TroveType } from "./redux/types/TroveType"
import SubcountView from './SubcountView'
import TroveItemButtons from './TroveItemButtons'
import TroveItemContent from './TroveItemContent'
import TroveItemCrumbs from './TroveItemCrumbs'
import TroveItemFunnelButton from './TroveItemFunnelButton'
import { executeScroll } from './util'

type Props = {
  node: TroveType
  active?: boolean
  ghost?: boolean
  funnel?: boolean
  onClick: (event: React.MouseEvent) => void
}

export const TroveItemFlat = React.memo(({ onClick, node, funnel, ghost, active }: Props) => {
  const item = useSelector(selectItem(node.key))
  const tree = useSelector(selectTree)
  const showArchived = useSelector(selectShowArchived)

  const subCount = useMemo(() =>
    getSubCount({ node, showArchived }),
    [node, showArchived])

  const parents = useMemo(() =>
    getParentNodes(tree, node.key)?.reverse(),
    [tree, node])

  const ref = useRef<HTMLElement>(null)

  // scrolling
  useEffect(() => {
    if (!active) return
    executeScroll(ref, { offset: 100 })
  }, [active, node.key])

  return <Ref innerRef={ref}>
    <List.Item
      active={active}
      className={classnames([
        'trove-item-flat',
        // tree.funnel === undefined && 'inactive',
        ghost && 'ghost',
      ])}
      onClick={onClick}
    // negative={isArchived}
    >
      <List.Content verticalAlign='top' floated='right'>
        <TroveItemButtons
          node={node}
        />
      </List.Content>
      {funnel
        ? <List.Icon verticalAlign='top'>
          <TroveItemFunnelButton
            node={node}
            funnelColor={node.funnel !== undefined ? TROVE_FILTER_COLORS[node.funnel] as IconProps['color'] : undefined}
          />
        </List.Icon>
        : <List.Icon
          name={node.funnel !== undefined ? TROVE_FILTER_ICONS[node.funnel] : 'circle'}
          color={node.funnel !== undefined ? TROVE_FILTER_COLORS[node.funnel] as IconProps['color'] : undefined}
          verticalAlign='top'
        />
      }
      <List.Content verticalAlign='middle'>
        <List.Header>
          <TroveItemContent
            item={item}
            level={0}
            archived={node.archived}
          />
          {subCount ? <SubcountView
            count={subCount + 1}
          /> : undefined}
        </List.Header>
        {parents?.length ? <List.Description>
          <TroveItemCrumbs parents={parents} />
        </List.Description> : undefined}
      </List.Content>

    </List.Item>
  </Ref>
})

export default TroveItemFlat