import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Header, Modal, Table } from 'semantic-ui-react'
import { setModal } from './redux/slice/workspaceSlice'
import { SHORTCUTS } from './shortcuts'

export type Props = {
  open: boolean
  onClose?: () => void
}

const ModalShortcuts = ({ open, onClose }: Props) => {
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(setModal(undefined))
    if (onClose) onClose()
  }, [dispatch, onClose])

  return (
    <Modal onClose={handleClose} open={open} basic size='small'>
      <Header icon='keyboard' content='Shortcuts' />
      <Modal.Content>
        <Table definition>
          <Table.Body>
            {Object.keys(SHORTCUTS).filter(key => !!SHORTCUTS[key].help).map(key => {
              const shortcut = SHORTCUTS[key]
              const display = shortcut.display
              // const keys = Array.isArray(display)
              //   ? display.map(' or ')
              //   : display
              return <Table.Row key={key}>
                <Table.Cell className='kbds'>
                  {display?.map((keys, index) => <span key={index}>
                    {keys.map((key, index2) => <kbd key={`${index}${index2}`}>
                      {key}
                    </kbd>)}
                  </span>)}
                </Table.Cell>
                <Table.Cell>{shortcut.help}</Table.Cell>
              </Table.Row>
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  )
}

export default ModalShortcuts
