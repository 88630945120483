import axios from 'axios'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { isMobile } from "react-device-detect"
import Emoji from 'react-emoji-render'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Dimmer, Divider, Form, Grid, Header, Icon, Image, Message, Modal, Segment } from "semantic-ui-react"
import { Migrate } from './migrate/migrate'
import { selectActiveWorkspace, selectVersionChange, selectDataVersion } from './redux/selector/workspaceSelector'
import { setModal } from './redux/slice/workspaceSlice'
import compareVersions from 'compare-versions'
import ReactGA from 'react-ga'

const Dimmers = React.memo(() => {
  const dispatch = useDispatch()
  const activeWorkspace = useSelector(selectActiveWorkspace)
  const versionChange = useSelector(selectVersionChange)
  const dataVersion = useSelector(selectDataVersion)

  const [welcome, setWelcome] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [code, setCode] = useState('johncleese')
  const [waitlistSuccess, setWaitlistSuccess] = useState(false)
  const [error, setError] = useState<'code' | 'email'>()
  const [loading, setLoading] = useState(false)

  const hasData = useMemo(() =>
    activeWorkspace && !!window.localStorage.getItem(activeWorkspace)
    , [activeWorkspace])

  useEffect(() => {
    setWelcome(!hasData)
    ReactGA.set({ dimension2: hasData ? 'YES' : 'NO' })
  }, [hasData])

  useEffect(() => {
    if (!hasData) return
    if (dataVersion !== undefined && compareVersions(Migrate.appVersion(), dataVersion) > 0) {
      dispatch(setModal('changelog'))
    }
  }, [dataVersion, dispatch, hasData])

  // const handleSkip = (event: React.MouseEvent) => {
  //   event.preventDefault()
  //   confirmDialog({
  //     message: 'Are you sure? Signing up is optional, but we can,
  //     onConfirm: () => {
  //       setWelcome(false)
  //     },
  //   })
  // }

  const handleCode = useCallback(() => {
    if (['johncleese', 'stephenfry'].includes(code.trim().split(' ').join('').toLowerCase())) {
      setWelcome(false)
      setError(undefined)
      ReactGA.set({ dimension2: 'YES' })
    } else {
      setError('code')
    }
  }, [setWelcome, setError, code])

  const handleWaitlist = useCallback(async () => {
    setLoading(true)
    const firstName = name.split(" ")[0].trim()
    const lastName = name.split(" ").slice(1).join(' ').trim()
    try {
      await axios.post('https://api.holygrailapp.com/v1/signup', {
        email, firstName, lastName
      })
      setLoading(false)
      setError(undefined)
      setWaitlistSuccess(true)
    } catch (error) {
      setError('email')
      setLoading(false)
      console.error(error)
    }
  }, [email, name])

  if (versionChange === -1) {
    return <Dimmer active>
      <Message negative>
        <Header>App Outdated</Header>
        <p>Your app is outdated ({Migrate.appVersion()}), you need to update it to continue working with your Troves.</p>
        <Button primary>Update</Button>
      </Message>
    </Dimmer>
  } else if (isMobile) {
    return <Dimmer active>
      <Header icon inverted>
        <Icon name='mobile alternate' />
        Mobile Not Supported Yet
      </Header>
      <p>We hear you! You'd like to use HolyGrail on your mobile device. We don't have a mobile client yet, for now please open HolyGrail on your Desktop.</p>
    </Dimmer>
  } else if (welcome) {
    return <Modal open>
      <Modal.Content style={{ fontSize: '1.3em', textAlign: 'center' }}>
        <Image src='./icon.png' size='small' centered />
        <Divider />
        {/* <Header style={{ textAlign: 'center' }}>
          <Emoji text=':trophy: HolyGrail. Time is precious.' />
        </Header> */}
        {/* <p>Dear HolyGrail’er,</p> */}
        {/* <p>We know you have been looking for the HolyGrail your entire life. Congratulations, you found it. Or did you? In any case, time is precious, so we will be brief. You’re looking at a pre-release version of HolyGrail&nbsp;App, and you’re invited to try “Version 0.1: My Trove”.</p> */}
        <p>You’re looking at a pre-release version of HolyGrail&nbsp;App, and you’re invited to try “Version&nbsp;0.1:&nbsp;My&nbsp;Trove”.</p>
        {/* <p>There’s no need to create an account right now, as all the data will be safely stored locally in your own browser. Note that therefore the app will only work on the same computer. If you want to use it at some point on a different computer, you can download and import a backup file. We advise you to create regular backups, as right now deleted items cannot (yet) be restored. We do ask for your email in order to stay in touch with you in case there are updates.</p> */}
        <p>Don’t be shy to reach out with your thoughts and questions. You can reach us at <a href='mailto:info@holygrailapp.com'>info@holygrailapp.com</a>.</p>
        <Header>
          <Emoji text=':sparkles: Version 0.1: My Trove' />
        </Header>
        <p>We believe that having all of your thoughts organized in one place can be the foundation for feeling organized. What if you had a place that is your own, in which you can freely store all valuable information and thoughts without immediately posing judgement. This place can function as a basis for prioritizing, creating plans and deciding what you’re going to do with your time. This is why the Trove is the cornerstone of HolyGrail&nbsp;App.</p>
        {/* <Header>
          Get Started
        </Header> */}
        {/* <p>We'd love to stay in touch with you, but we like options and won't stop you from using HolyGrail <a href="/#" onClick={handleSkip}>if you don't</a>.</p> */}
        <Segment basic>
          <Button
            onClick={handleCode}
            color='green'
            size='huge'
          >
            Start Troving
          </Button>
        </Segment>
        {/* <Segment basic>
          <Grid columns={2} relaxed padded stackable textAlign='center'>
            <Divider vertical>Or</Divider>
            <Grid.Row verticalAlign='top'>
              <Grid.Column>
                <Header icon>
                  <Icon color='green' name='unlock' />
                  Unlock With Invite Code
                </Header>
                <p>You can find your code in your invite email.</p>
                <Form error={error === 'code'}>
                  <Message
                    error
                    header='Invalid Code'
                    // content='Check your invite email and make sure you have entered it correctly. Did you mean John Cleese?'
                    content='Did you mean John Cleese?'
                  />
                  <Form.Input
                    size='huge'
                    placeholder='Your Invite Code'
                    onChange={e => {
                      setCode(e.target.value)
                      setError(undefined)
                    }}
                    input={{
                      style: { textAlign: 'center' },
                    }}
                    value={code}
                    error={error === 'code'}
                  // error={{
                  //   content: 'Please enter a valid code',
                  //   pointing: 'below',
                  // }}
                  />
                  <Button
                    disabled={!code}
                    onClick={handleCode}
                    color='green'
                    size='huge'
                  >
                    Start Troving
                 </Button>
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Header icon>
                  <Icon color='blue' name='coffee' />
                  No Code? Sign up for Our Waitlist
                </Header>
                <p>We will contact you when the next slots open up.</p>
                <Form noValidate error={error === 'email'} success={waitlistSuccess}>
                  <Message success>
                    <Icon name='check' />
                    You're on the waitlist.
                  </Message>
                  <Message error>
                    Something went wrong, make sure you entered your email correctly or <a href='mailto:info@holygrailapp.com'>contact us</a>.
                  </Message>
                  <Form.Input
                    placeholder='Your Name'
                    onChange={e => {
                      setName(e.target.value)
                      setError(undefined)
                    }}
                    input={{
                      style: { textAlign: 'center' },
                    }}
                    value={name}
                  />
                  <Form.Input
                    type='email'
                    placeholder='Your Email'
                    onChange={e => {
                      setEmail(e.target.value)
                      setError(undefined)
                    }}
                    input={{
                      style: { textAlign: 'center' },
                    }}
                    error={error === 'email'}
                    value={email}
                  />
                  <Button
                    loading={loading}
                    disabled={!email || !name}
                    onClick={handleWaitlist}
                  // color='blue'
                  >
                    Join Waitlist
                    </Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment> */}
      </Modal.Content>
    </Modal>
  } else {
    return null
  }
})

export default Dimmers