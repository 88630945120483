import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Header, Modal, Button } from 'semantic-ui-react'
import ChangelogList from './ChangelogList'
import { setModal } from './redux/slice/workspaceSlice'

export type Props = {
  open: boolean
  onClose?: () => void
}

const ModalChangelog = ({ open, onClose }: Props) => {
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(setModal(undefined))
    if (onClose) onClose()
  }, [dispatch, onClose])

  return (
    <Modal onClose={handleClose} open={open} size='small'>
      <Header icon='trophy' content={`What's New`} />
      <Modal.Content>
        <ChangelogList />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content='Close'
          positive
          onClick={handleClose}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default ModalChangelog
