import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Breadcrumb } from 'semantic-ui-react'
import { selectItems } from './redux/selector/itemSelector'
import { TroveType } from './redux/types/TroveType'
import { notUndefined } from './util'

type Props = {
  parents?: TroveType[]
  single?: boolean
}

export const TroveItemCrumbs = React.memo(({ parents, single }: Props) => {
  const items = useSelector(selectItems)

  const parentItems = useMemo(() => {
    return parents?.slice(1, single ? 2 : undefined)
      .map(p => items[p.key])
      .filter(notUndefined)
  }, [items, parents, single])

  if (!parentItems?.length) return null

  return (
    <Breadcrumb className='muted'>
      {parentItems?.map((item, index) => {
        return <React.Fragment key={item.key}>
          <Breadcrumb.Section className='overflow-trimmed' style={{ maxWidth: 100 }}>
            {item.text || '?'}
          </Breadcrumb.Section>
          {(index + 1) < parentItems.length ? <Breadcrumb.Divider icon='right chevron' /> : undefined}
        </React.Fragment>
      })}
    </Breadcrumb>
  )
})

export default TroveItemCrumbs