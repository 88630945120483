import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DateInput } from 'semantic-ui-calendar-react'
import { Button, Form, Header, Modal } from 'semantic-ui-react'
import { addPlan, PlanUpdates, updatePlanSave } from './redux/slice/planSlice'
import { PlanType } from './redux/types/PlanType'
import { confirmDialog } from './util'

type Props = {
  open: boolean
  onClose: () => void
  edit?: boolean
  plan?: PlanType
  startDate?: string
  endDate?: string
}

const PlanForm = React.memo(({
  open,
  onClose,
  edit,
  plan,
  startDate: initialStartDate,
  endDate: initialEndDate,
}: Props) => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [error, setError] = useState(false)
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (open) {
      inputRef.current?.focus()
      setName(plan?.name || '')
      setStartDate(initialStartDate || plan?.startDate || '')
      setEndDate(initialEndDate || plan?.endDate || '')
    }
  }, [initialEndDate, initialStartDate, open, plan])

  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault()
    const updates: PlanUpdates = { name, startDate, endDate }
    if (plan) {
      dispatch(updatePlanSave({
        key: plan.key,
        updates,
        attributes: ['name', 'startDate', 'endDate']
      }))
    } else {
      dispatch(addPlan({ updates }))
    }
    onClose()
  }, [onClose, dispatch, plan, name, startDate, endDate])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'name') setName(event.target.value)
  }, [])

  const handleDateChange = useCallback((
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DateInput['props']
  ) => {
    // console.log(data)
    const name: keyof PlanType = data.name
    if (name === 'startDate') {
      setStartDate(data.value)
    } else if (name === 'endDate') {
      setEndDate(data.value)
    }
  }, [])

  useEffect(() => {
    // reset end date when startdate is not
    if (!startDate) setEndDate('')
    // set end date to start date if start date is after end date
    if (!endDate || moment(startDate).isAfter(endDate)) {
      setEndDate(startDate)
    }
    setError(
      (!startDate && !!endDate) ||
      moment(startDate).isAfter(endDate)
    )
  }, [startDate, endDate])

  const handleClose = useCallback((event: React.MouseEvent) => {
    confirmDialog({
      message: 'Cancel editing this plan?',
      onConfirm: onClose,
    })
  }, [onClose])

  return (
    <Modal onClose={handleClose} open={open} size='small'>
      <Header icon='clipboard list' content={`${edit ? 'Edit' : 'New'} Checklist`} />
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Name</label>
            <input
              ref={inputRef}
              type='text'
              name='name'
              value={name}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field error={error}>
            <label>Start</label>
            <DateInput
              marked={[moment()]}
              markColor='orange'
              clearable
              maxDate={endDate}
              dateFormat='YYYY-MM-DD'
              name={`startDate`}
              placeholder={`Start Date`}
              value={startDate}
              iconPosition='left'
              onChange={handleDateChange}
            />
          </Form.Field>
          {startDate ? <Form.Field>
            <label>End</label>
            <DateInput
              marked={[moment()]}
              markColor='orange'
              clearable
              minDate={startDate}
              dateFormat='YYYY-MM-DD'
              name={`endDate`}
              placeholder={`End Date`}
              value={endDate}
              iconPosition='left'
              onChange={handleDateChange}
            />
          </Form.Field> : undefined}
          <Button type='button' onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} type='submit' disabled={!name} primary>
            {edit ? 'Update' : 'Create'} Checklist
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  )
})

export default PlanForm
