import React, { useCallback, useRef } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Header, List, Segment, Icon } from 'semantic-ui-react'
import ItemRow from './ItemRow'
import { selectRecentItems } from './redux/selector/itemSelector'
import { setSideKey } from './redux/slice/troveSlice'
import { toggleSideMenu } from './redux/slice/workspaceSlice'

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

const ItemListRecent = React.memo(({ className }: Props) => {
  const dispatch = useDispatch()
  const recentItems = useSelector(selectRecentItems)
  const ref = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    batch(() => {
      dispatch(setSideKey())
      dispatch(toggleSideMenu({}))
    })
  }, [dispatch])

  return <div ref={ref} tabIndex={-1} className={className}>
    <Header>
      <span>
        <Icon color='grey' name='clock' />
        Recents
      </span>
      <Button.Group size='tiny' compact floated='right'>
        <Button
          size='tiny'
          icon='times'
          content='Close'
          onClick={handleClose}
          compact
        />
      </Button.Group>
    </Header>
    <Divider />
    {
      !recentItems.length
        ? <Segment>
          <p>No items here.</p>
        </Segment>
        : <Segment style={{ padding: 0 }}>
          <List
            // size='large'
            divided
            relaxed
            selection
          >
            {recentItems.map(item => {
              return <ItemRow
                key={item.key}
                item={item}
              />

            })}
          </List>
        </Segment>
    }
  </div >
})

export default ItemListRecent