import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Emoji from "react-emoji-render"
import { useDispatch, useSelector } from 'react-redux'
import { Confirm, Dropdown, Icon, Menu } from 'semantic-ui-react'
import GlobalSearchInput from './GlobalSearchInput'
import { Migrate } from './migrate/migrate'
import ModalChangelog from './ModalChangelog'
import ModalPrompt from './ModalPrompt'
import ModalShortcuts from './ModalShortcuts'
import ModalImport from './ModelImport'
import { selectRootKey, selectScopeKey, selectShowArchived } from './redux/selector/troveSelector'
import { selectIsDebug, selectModal, selectSideMenu, selectWorkspace, selectWorkspaces } from './redux/selector/workspaceSelector'
import { addChildNode, foldSubNodes, toggleShowArchived } from './redux/slice/troveSlice'
import { Area, createWorkspace, deleteWorkspace, getFilteredWorkspaceData, switchWorkspace, toggleSideMenu } from './redux/slice/workspaceSlice'
import { confirmDialog, download } from './util'
import SendFeedbackModal from './SendFeedbackModal'

const TopMenu = React.memo(() => {
  const dispatch = useDispatch()
  const workspace = useSelector(selectWorkspace)
  const rootKey = useSelector(selectRootKey)
  const showArchived = useSelector(selectShowArchived)
  const scopeKey = useSelector(selectScopeKey)
  const isDebug = useSelector(selectIsDebug)
  const workspaces = useSelector(selectWorkspaces)
  const sideMenu = useSelector(selectSideMenu)
  const globalModal = useSelector(selectModal)

  const [coffee, setCoffee] = useState(0)
  const [modal, showModal] = useState<'newWorkspace' | 'deleteWorkspace' | 'import' | 'shortcuts' | 'feedback' | 'changelog'>()

  // const secrets = useMemo(() =>
  //   coffee > 3 || !!isDebug,
  //   [coffee, isDebug])
  const secrets = true

  useEffect(() => {
    showModal(globalModal)
  }, [globalModal])

  const handleAdd = useCallback(() => {
    dispatch(addChildNode(scopeKey || rootKey))
  }, [dispatch, scopeKey, rootKey])

  const handleReset = useCallback(() => {
    // window.localStorage.removeItem(activeWorkspace)
    confirmDialog({
      message: 'Are you sure? All existing data will be erased!',
      onConfirm: () => {
        window.localStorage.clear()
        window.location.reload()
      }
    })
  }, [])

  const handleFold = useCallback((fold?: boolean) => {
    dispatch(foldSubNodes({ fold }))
  }, [dispatch])

  const handleToggleShowArchived = useCallback(() => {
    dispatch(toggleShowArchived())
  }, [dispatch])

  const handleSidebarToggle = useCallback((area: Area) => {
    dispatch(toggleSideMenu({ area }))
  }, [dispatch])

  const handleDownload = useCallback((filtered = false) => {
    if (!workspace?.key) return
    const data = filtered
      ? JSON.stringify(dispatch(getFilteredWorkspaceData()))
      : window.localStorage.getItem(workspace.key)
    download(data, workspace, filtered ? 'filtered' : undefined)
  }, [workspace, dispatch])

  const handleCreateWorkspace = (name: string) => {
    if (!name) return
    dispatch(createWorkspace(name))
    showModal(undefined)
  }

  const handleSwitchWorkspace = useCallback((workspace: string) => {
    dispatch(switchWorkspace(workspace))
  }, [dispatch])

  const handleDeleteWorkspace = useCallback(() => {
    if (!workspace?.key) return
    dispatch(deleteWorkspace(workspace.key))
    showModal(undefined)
  }, [dispatch, workspace])

  const handleCoffee = useCallback(() => {
    setCoffee(coffee + 1)
  }, [coffee])

  return <>
    <Menu size='small' color={isDebug ? 'red' : undefined} inverted={isDebug} fixed='top'>
      <Menu.Item header>
        <div onClick={handleCoffee}>
          <Emoji text={`${coffee ? (secrets ? (coffee > 10 ? ':rocket:' : ':racing_car:') : ':coffee:') : ':trophy:'} HolyGrail`} />
        </div>
      </Menu.Item>
      <Menu.Item onClick={handleAdd}>
        <Icon name='add' />
          New Trove
      </Menu.Item>
      <Menu.Item active={showArchived} onClick={handleToggleShowArchived}>
        <Icon name={showArchived ? 'eye' : 'eye slash'} />
        Archived
      </Menu.Item>
      <Menu.Item onClick={() => handleFold(true)}>
        <Icon name='folder' />
        Fold
      </Menu.Item>
      <Menu.Item onClick={() => handleFold(false)}>
        <Icon name='folder open' />
        Unfold
      </Menu.Item>
      <Menu.Item onClick={() => showModal('shortcuts')}>
        <Icon name='keyboard' />
        Shortcuts
      </Menu.Item>
      <Menu.Item onClick={() => showModal('feedback')}>
        <Icon name='envelope' />
        Support
      </Menu.Item>
      <Dropdown item text='Develop'>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => handleDownload()}
            icon='save'
            text='Save Backup'
          />
          <Dropdown.Item
            disabled={!scopeKey}
            onClick={() => handleDownload(true)}
            icon='download'
            text='Download Current Scope'
          />
          <Dropdown.Header>Experimental</Dropdown.Header>
          <Menu.Item
            disabled={!secrets}
            active={sideMenu === 'plan'}
            onClick={() => handleSidebarToggle('plan')}>
            <Icon name='clipboard list' />
            Checklists
          </Menu.Item>
          <Menu.Item
            disabled={!secrets}
            active={sideMenu === 'planning'}
            onClick={() => handleSidebarToggle('planning')}>
            <Icon name='calendar' />
            Planning
          </Menu.Item>
          <Dropdown.Header>Workspaces</Dropdown.Header>
          {workspaces.map(w => (
            <Dropdown.Item
              icon='chess'
              key={w.key}
              onClick={() => handleSwitchWorkspace(w.key)}
              text={w.name || '?'}
              active={workspace?.key === w.key}
            />
          ))}
          <Dropdown.Item
            disabled={!secrets}
            onClick={() => showModal('newWorkspace')}
            text='New Workspace'
            icon='plus'
          />
          <Dropdown.Header>Updates</Dropdown.Header>
          {window.electron ? <>
            <Dropdown.Item
              onClick={() => window.electron.send('check-updates')}
              icon='refresh'
              text='Check for Updates'
            />
            <Dropdown.Item
              onClick={() => window.electron.send('ping')}
              icon='hand paper'
              text='Ping'
            />
          </> : undefined}
          <Dropdown.Item
            onClick={() => showModal('changelog')}
            icon='trophy'
            text={`What's New`}
          />
          <Dropdown.Header><span style={{ color: 'red' }}>Danger</span></Dropdown.Header>
          <Dropdown.Item
            icon='upload'
            text='Import Backup'
            onClick={() => showModal('import')}
          />
          <Dropdown.Item
            disabled={workspace?.key === workspaces[0].key}
            color='red'
            onClick={() => showModal('deleteWorkspace')}
            icon='trash'
            text='Delete Workspace'
          />
          <Dropdown.Item color='red' onClick={handleReset} icon='dont' text='Reset' />
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Menu position='right'>
        <Menu.Item>
          <GlobalSearchInput />
        </Menu.Item>
        <Menu.Item
          content={Migrate.appVersion()}
          onClick={() => showModal('changelog')}
          header
        />
      </Menu.Menu>
    </Menu>
    {/* modals */}
    <ModalChangelog
      open={modal === 'changelog'}
      onClose={() => showModal(undefined)}
    />
    <ModalShortcuts
      open={modal === 'shortcuts'}
      onClose={() => showModal(undefined)}
    />
    <ModalImport
      open={modal === 'import'}
      onClose={() => showModal(undefined)}
    />
    <SendFeedbackModal
      open={modal === 'feedback'}
      onClose={() => showModal(undefined)}
    />
    <ModalPrompt
      open={modal === 'newWorkspace'}
      name='Workspace'
      label='Name'
      icon='chess'
      onSubmit={handleCreateWorkspace}
      onClose={() => showModal(undefined)}
    />
    <Confirm
      content={`Are you sure you want to delete workspace "${workspace?.name}"?`}
      open={modal === 'deleteWorkspace'}
      onConfirm={handleDeleteWorkspace}
      onCancel={() => showModal(undefined)}
    />
  </>
})

export default TopMenu

