import classnames from 'classnames'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Grid, Header, Icon, Ref, Segment } from 'semantic-ui-react'
import PlanButtons from './PlanButtons'
import PlanItemList from './PlanItemList'
import { PlanType } from './redux/types/PlanType'
import { rangeOverlaps } from './util'
import Wrap from './Wrap'
import useInterval from './useInterval'

type Props = {
  plan: PlanType
  active?: boolean
  dropping: boolean
  dragging: boolean
  onClick: (event: React.MouseEvent) => void
  onArchive?: (event?: React.MouseEvent) => void
  onEdit?: (event?: React.MouseEvent) => void
  innerRef: React.RefObject<HTMLElement>
  count?: number
  doneCount?: number
}
const PlanItemListCalendar = ({
  active, plan, dropping, dragging, onClick, onArchive, onEdit, innerRef, count, doneCount
}: Props) => {
  // const [mouseOver, setMouseOver] = useState(false)
  const archived = !!plan.archivedAt
  const completed = !!plan.completedAt
  const done = archived || completed
  const timestamp = useInterval()

  const current = useMemo(() => {
    if (!plan.startDate || !plan.endDate) return false
    const today = moment(timestamp).format('YYYY-MM-DD')
    return rangeOverlaps(
      moment(today).toDate(),
      moment(today).add(1, 'day').toDate(),
      plan.startDate,
      plan.endDate,
      'overlaps')
  }, [plan.endDate, plan.startDate, timestamp])

  return <Ref innerRef={innerRef}>
    <Segment.Group
      // secondary={!active}
      raised={active || dropping}
      // attached={active ? 'top' : false}
      className={classnames({
        'dragging': dragging,
        // 'done': done,
        // 'hover': dropping,
        // 'active': active,
      })}
      color={done ? completed ? 'green' : undefined : undefined}
      // onMouseEnter={() => setMouseOver(true)}
      // onMouseLeave={() => setMouseOver(false)}
      onClick={onClick}
    >
      <Segment style={{ paddingBottom: 0 }}>
        <Header as='h4' style={{ marginBottom: 0 }}>
          <Wrap element='s' active={done}>
            {done ? <Icon name='check' /> : undefined}
            {plan.name}
          </Wrap>
        </Header>
        {count ? <p className='muted'>
          {current ? <>
            <Icon name='sun' color='yellow' />
              &nbsp;
            </> : undefined}
          {count ? <span>{count} open items</span> : undefined}
        </p> : undefined}
        <PlanItemList
          plan={plan}
          calendar
        />
      </Segment>
      {active
        ? <Segment>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <PlanButtons
                  items={['done']}
                  plan={plan}
                  doneCount={doneCount}
                  buttonGroupProps={{
                    // className: 'floated-absolute',
                    size: 'tiny',
                    basic: true,
                    compact: true,
                  }}
                />
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <PlanButtons
                  items={['archive', 'edit']}
                  onArchive={onArchive}
                  onEdit={onEdit}
                  plan={plan}
                  doneCount={doneCount}
                  buttonGroupProps={{
                    // className: 'floated-absolute',
                    size: 'tiny',
                    basic: true,
                    compact: true,
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>


        </Segment>
        : undefined
      }
    </Segment.Group>
  </Ref>
}

export default PlanItemListCalendar