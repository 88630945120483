import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Plan from './Plan'
import { selectFilteredPlans } from './redux/selector/planSelector'
import { rangeOverlaps } from './util'

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className']
  startDate?: Date
  endDate?: Date
  mode?: 'overlaps' | 'contains' | 'unscheduled'
  excludeDayRange?: boolean
  empty?: JSX.Element
  calendar?: boolean
}

const PlanList = ({ calendar, empty, startDate, endDate, mode, excludeDayRange }: Props) => {
  const plans = useSelector(selectFilteredPlans)

  const plansFiltered = useMemo(() => {
    if (mode === 'unscheduled') return plans.filter(plan => {
      return !plan.startDate && !plan.endDate
    })
    if (!startDate || !endDate) return plans
    return plans.filter(plan => {
      if (!plan.startDate || !plan.endDate) return false
      if (excludeDayRange && plan.startDate === plan.endDate) return false
      return rangeOverlaps(startDate, endDate, plan.startDate, plan.endDate, mode)
    })
  }, [endDate, excludeDayRange, mode, plans, startDate])

  return <>
    {plansFiltered.length
      ? plansFiltered.map(plan => <Plan
        key={plan.key}
        calendar={calendar}
        plan={plan}
      />)
      : empty || null
    }
  </>
}

export default PlanList
