import React from 'react'

type WrapProps = {
  element: string
  active?: boolean
}

const Wrap = ({ children, element, active }: React.PropsWithChildren<WrapProps>) => {
  return active ? React.createElement(element, {}, children) : <>{children}</>
}

export default Wrap
