import compareVersions from 'compare-versions'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Header, List } from 'semantic-ui-react'
import { selectDataVersion } from './redux/selector/workspaceSelector'
import { changelog } from './data'

const ChangelogList = () => {
  const [showPrevious, setShowPrevious] = useState(false)
  const dataVersion = useSelector(selectDataVersion)
  
  if (!dataVersion) return null

  const changeVersions = Object.keys(changelog)
  const currentIndex = changeVersions.findIndex(v => compareVersions(dataVersion, v) === 0)
  // compareVersions(currentVersion, dataVersion)
  
  const changelogNew = changeVersions.slice(0, currentIndex)
  const changelogPrevious = changeVersions.slice(currentIndex)

  return (
    <>
      {/* {dataVersion} */}
      {changelogNew.length ? changelogNew.map(version =>
        <ChangelogChanges
          key={version}
          version={version}
          changes={changelog[version]}
        />) : (
          <p>You're on the latest version.</p>
        )}
      {showPrevious ? <>
        <Header>Previous Updates</Header>
        {changelogPrevious.map(version =>
          <ChangelogChanges
            key={version}
            version={version}
            changes={changelog[version]}
          />)}
      </> : (
          <Button
            size='tiny'
            compact
            onClick={() => setShowPrevious(!showPrevious)}
            content='Show Previous Updates'
          />
        )}
    </>

  )
}

type Props = { version: string, changes?: string[] }

const ChangelogChanges = ({ version, changes }: Props) => {
  return <>
    <p><strong>Version {version}</strong></p>
    <List bulleted>
      {changes?.map((change, index) =>
        <List.Item key={index}>{change}</List.Item>
      )}
    </List>
  </>
}

export default ChangelogList
