import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { scopeParentNode, setSearch } from './redux/slice/troveSlice'
import { TroveType } from './redux/types/TroveType'
import TroveItemFlat from './TroveItemFlat'

type Props = {
  node: TroveType
}

const TroveItemSearch = React.memo(({ node }: Props) => {
  const dispatch = useDispatch()

  const handleClick = useCallback((event: React.MouseEvent, node: TroveType) => {
    if (!node.key) return
    event.stopPropagation()
    dispatch(scopeParentNode(node.key))
    dispatch(setSearch(undefined))
  }, [dispatch])


  return <TroveItemFlat
    key={node.key}
    node={node}
    onClick={e => handleClick(e, node)}
  />
})

export default TroveItemSearch