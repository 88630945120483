import classnames from 'classnames'
import React, { useCallback, useMemo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconProps, List, Ref } from 'semantic-ui-react'
import { TROVE_FILTER_COLORS, TROVE_FILTER_ICONS } from './data'
import ItemForm from './ItemForm'
import PlanItemButtons from './PlanItemButtons'
import { RootState } from './redux/reducers'
import { selectItem } from './redux/selector/itemSelector'
import { selectActivePlanItem, selectActivePlanKey, selectEditing } from './redux/selector/planSelector'
import { getNodeByKey, getParentNodes, selectTree } from './redux/selector/troveSelector'
import { selectActiveArea } from './redux/selector/workspaceSelector'
import { dropItem } from './redux/slice/itemSlice'
import { setActivePlan, toggleEditing } from './redux/slice/planSlice'
import { scopeParentNode } from './redux/slice/troveSlice'
import { PlanItemType } from './redux/types/PlanItemType'
import { PlanType } from './redux/types/PlanType'
import TroveItemContent from './TroveItemContent'
import TroveItemCrumbs from './TroveItemCrumbs'
import { ItemDropResult } from './types/ItemDropResult'
import { PlanItemDragType } from './types/PlanItemDragType'
import { TroveItemDragType } from './types/TroveItemDragType'
import { toggleSideMenu } from './redux/slice/workspaceSlice'

type Props = {
  planItem: PlanItemType
  plan: PlanType
  calendar?: boolean
}

const PlanItem = ({ calendar, planItem, plan }: Props) => {
  const dispatch = useDispatch()
  const item = useSelector(selectItem(planItem.key))
  const tree = useSelector(selectTree)
  const planActive = useSelector((state: RootState) =>
    selectActivePlanKey(state) === plan.key)
  const active = useSelector((state: RootState) =>
    selectActivePlanItem(state) === planItem.key
    && selectActivePlanKey(state) === plan.key
    && selectActiveArea(state) === 'plan')
  // const selected = useSelector((state: RootState) =>
  //   selectSelectedPlanItems(state).includes(planItem.key))
  const editing = useSelector((state: RootState) =>
    selectEditing(state)
    && active
  )

  const troveNode = useMemo(() =>
    getNodeByKey(tree, planItem.key),
    [tree, planItem.key])

  const troved = useMemo(() => !!troveNode, [troveNode])

  const parents = useMemo(() =>
    getParentNodes(tree, planItem.key)?.reverse(),
    [tree, planItem.key])

  const completed = !!planItem.completedAt
  const archived = !!planItem.archivedAt || (!!plan.archivedAt && !completed)
  const done = archived || completed
  // const [mouseOver, setMouseOver] = useState(false)

  const ref = useRef<HTMLElement>(null)

  const dragItem: PlanItemDragType = useMemo(() => ({
    type: 'PLAN_ITEM',
    key: planItem.key,
    troved,
    planKey: plan.key,
  }), [troved, planItem.key, plan.key])

  // drag drop stuff
  const [{ dragging }, drag] = useDrag({
    item: dragItem,
    canDrag: () => !done,
    end: (item: PlanItemDragType | undefined, monitor) => {
      if (!item) return
      const dropResult: ItemDropResult | undefined = monitor.getDropResult()
      if (!dropResult) return
      dispatch(dropItem({
        dropResult,
        planKey: plan.key,
        source: planItem.key,
      }))
    },
    collect: monitor => ({
      dragging: !!monitor.isDragging(),
    }),
  })

  const [{ dropping }, drop] = useDrop({
    accept: ['PLAN_ITEM'],
    canDrop: (_, monitor) => {
      const item = monitor.getItem() as TroveItemDragType | PlanItemDragType
      return !done && (item.type === 'TROVE_ITEM' || item.planKey === plan.key)
    },
    drop: (): ItemDropResult => ({
      target: planItem.key,
      area: 'plan',
    }),
    collect: monitor => ({
      dropping: !!monitor.isOver() && monitor.canDrop(),
    }),
  })

  drag(drop(ref))

  const handleScope = useCallback(() => {
    if (calendar) dispatch(toggleSideMenu({}))
  }, [calendar, dispatch])

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    if (event.metaKey) {
      dispatch(scopeParentNode(planItem.key))
    } else {
      dispatch(setActivePlan({ key: plan.key, itemKey: planItem.key }))
      dispatch(toggleEditing(active))
    }
  }, [dispatch, planItem.key, plan.key, active])

  const handleFormComplete = useCallback(() => {
    dispatch(toggleEditing(false))
  }, [dispatch])

  // const handleSelect = useCallback((event: React.MouseEvent) => {
  //   event.stopPropagation()
  //   dispatch(selectPlanItem({ itemKey: planItem.key, key: plan.key }))
  // }, [dispatch, planItem.key, plan.key])

  return (
    <Ref innerRef={ref}>
      <List.Item
        disabled={!!plan.archivedAt}
        // onMouseEnter={() => setMouseOver(true)}
        // onMouseLeave={() => setMouseOver(false)}
        active={active}
        className={classnames('plan-item', {
          // 'selected': selected,
          'dragging': dragging,
          'dropping': dropping,
          'positive': done,
          'editing': editing,
        })}
        onClick={handleClick}
      >
        {active && !editing
          ? <List.Content
            // className='floated-absolute'
            verticalAlign='top'>
            <PlanItemButtons
              buttonGroupProps={{ basic: true, size: 'tiny', floated: 'right' }}
              troved={troved}
              plan={plan}
              planItem={planItem}
              onScope={handleScope}
              items={calendar ? ['scope', 'archive', 'complete'] : ['scope', 'delete']}
            />
          </List.Content> : undefined}
        {planActive && !editing && !calendar
          ? <List.Icon>
            <PlanItemButtons
              buttonGroupProps={{ compact: true, size: 'tiny' }}
              troved={troved}
              plan={plan}
              planItem={planItem}
              items={['archive', 'complete']}
            />
          </List.Icon> : undefined}
        <List.Content>
          {editing
            ? <ItemForm
              itemKey={planItem.key}
              level={0}
              onComplete={handleFormComplete}
            />
            : <>
              <List.Header>
                {troveNode?.funnel !== undefined || troveNode?.archived
                  ? <Icon
                    name={troveNode.archived
                      ? 'archive'
                      : TROVE_FILTER_ICONS[troveNode.funnel || 0]}
                    color={troveNode.archived
                      ? 'grey'
                      : TROVE_FILTER_COLORS[troveNode.funnel || 0] as IconProps['color']}
                  /> : undefined
                }

                <TroveItemContent
                  item={item}
                  archived={archived}
                  completed={completed}
                />
              </List.Header>
            </>
          }
          {parents?.length
            ? <List.Description style={{ marginTop: '0.4em' }}>
              <TroveItemCrumbs parents={parents} single={!(planActive && active)} />
            </List.Description>
            : !troved && planActive && active
              ? <List.Description style={{ marginTop: '0.4em' }}>
                Not Troved
              </List.Description>
              : undefined
          }
        </List.Content>
      </List.Item>
    </Ref>
  )
}

export default PlanItem