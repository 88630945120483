import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, LabelProps, Segment } from 'semantic-ui-react'
import { TROVE_FILTER_COLORS, TROVE_FILTER_TYPES } from './data'
import { selectFilterFunnel, selectScopedItem, selectSearchTerm } from './redux/selector/troveSelector'
import { setScopeKey, setSearch, setFilterFunnel } from './redux/slice/troveSlice'

const TroveMessage = React.memo(() => {
  const dispatch = useDispatch()
  const scopeItem = useSelector(selectScopedItem)
  const filterFunnel = useSelector(selectFilterFunnel)
  const searchTerm = useSelector(selectSearchTerm)

  const handleClearFunnel = useCallback(() => dispatch(setFilterFunnel(undefined)), [dispatch])
  const handleClearSearch = useCallback(() => dispatch(setSearch(undefined)), [dispatch])
  const handleClearScope = useCallback(() => dispatch(setScopeKey(undefined)), [dispatch])

  if (!scopeItem && !searchTerm && filterFunnel === undefined) return null

  return <>
    <Segment color='blue'>
      Showing items
        {searchTerm ? <>
        &nbsp;for
          &nbsp;<Button
          icon='times'
          label={{
            icon: 'search',
            content: searchTerm,
            className: 'overflow-trimmed',
            style: { maxWidth: 150 },
          }}
          compact
          onClick={handleClearSearch}
          size='tiny'
        />
      </> : undefined}
      {/* searchTerm is set, filterFunnel is not applied */}
      {!searchTerm && filterFunnel !== undefined ? <>
        &nbsp;labeled
          &nbsp;<Button
          icon='times'
          label={{
            color: TROVE_FILTER_COLORS[filterFunnel] as LabelProps['color'],
            icon: 'star',
            content: TROVE_FILTER_TYPES[filterFunnel],
          }}
          compact
          onClick={handleClearFunnel}
          size='tiny'
        />
      </> : undefined}
      {scopeItem ? <>
        &nbsp;within
          &nbsp;<Button
          icon='times'
          label={{
            className: 'overflow-trimmed',
            content: scopeItem.text,
            style: { maxWidth: 150 },
          }}
          compact
          onClick={handleClearScope}
          size='tiny'
        />
      </> : undefined}
      {/* <Label size='tiny' color={TROVE_FILTER_COLORS[filterFunnel] as LabelProps['color']}>
          {TROVE_FILTER_TYPES[filterFunnel]}
        </Label> */}
      {/* {filterFunnel + 1 !== TROVE_FILTER_TYPES.length ? ' and higher.' : undefined} */}
    </Segment>
  </>
})

export default TroveMessage