import classnames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { List, Menu, Popup, Ref } from 'semantic-ui-react'
import ItemForm from './ItemForm'
import { RootState } from './redux/reducers'
import { getSubCount, selectActiveItemKey, selectEditing, selectShowArchived } from './redux/selector/troveSelector'
import { selectActiveArea } from './redux/selector/workspaceSelector'
import { dropItem } from './redux/slice/itemSlice'
import { archiveItem, setActiveNode, setScopeKey, toggleEditing } from './redux/slice/troveSlice'
import { TroveType } from "./redux/types/TroveType"
import { SHORTCUTS } from './shortcuts'
import TroveItemRow from './TroveItemRow'
import TroveList from './TroveList'
import { ItemDropResult } from "./types/ItemDropResult"
import { PlanItemDragType } from "./types/PlanItemDragType"
import { TroveItemDragType } from './types/TroveItemDragType'
import { executeScroll } from './util'

type TroveItemPropsI = {
  node: TroveType
  parent?: TroveType
  level: number
  disabled?: boolean
  minimal?: boolean
}

export const TroveItem = React.memo(({ disabled: parentDisabled, parent, node, level, minimal }: TroveItemPropsI) => {
  const dispatch = useDispatch()
  // const isDebug = useSelector(selectIsDebug)
  const showArchived = useSelector(selectShowArchived)
  const active = useSelector((state: RootState) =>
    selectActiveArea(state) === 'trove'
    && selectActiveItemKey(state) === node.key
  )
  const editing = useSelector((state: RootState) =>
    selectEditing(state) && active
  )

  const [contextOpen, setContextOpen] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)

  const ref = useRef<HTMLElement>(null)

  const dragItem: TroveItemDragType = useMemo(() => ({
    type: 'TROVE_ITEM',
    key: node.key,
  }), [node.key])

  // drag drop stuff
  const [{ dragging }, drag] = useDrag({
    item: dragItem,
    end: (item: PlanItemDragType | undefined, monitor) => {
      if (!item) return
      const dropResult: ItemDropResult | undefined = monitor.getDropResult()
      if (!dropResult) return
      dispatch(dropItem({
        dropResult,
        source: node.key,
      }))
    },
    collect: monitor => ({
      dragging: !!monitor.isDragging(),
      // dragOffset: monitor.getDifferenceFromInitialOffset()
    }),
  })

  const [{ dropping, isDropChild }, drop] = useDrop({
    accept: ['TROVE_ITEM', 'PLAN_ITEM'],
    drop: (): ItemDropResult => ({
      target: node.key,
      area: 'trove',
      isDropChild: isDropChild,
    }),
    // canDrop: (_item, monitor) => {
    //   const item = monitor.getItem() as TroveItemDragType | PlanItemDragType
    //   return monitor.getItemType() === 'TROVE_ITEM' || !monitor.getItem().troved
    // },
    collect: monitor => ({
      dropping: !!monitor.isOver() && monitor.canDrop(),
      // canDrop: !!monitor.canDrop(),
      isDropChild: ((monitor.getDifferenceFromInitialOffset() || {}).x || 0) > 30,
      // dropOffset: monitor.getDifferenceFromInitialOffset()
    }),
  })

  drag(drop(ref))

  const disabled = useMemo(() =>
    dragging || parentDisabled,
    [dragging, parentDisabled])

  const subCount = useMemo(() =>
    getSubCount({ node, showArchived }),
    [node, showArchived])

  const handleClick = useCallback((event: React.MouseEvent) => {
    if (!node.key) return
    event.stopPropagation()
    if (event.metaKey) {
      dispatch(setScopeKey(node.key))
    } else {
      dispatch(setActiveNode(node.key))
      dispatch(toggleEditing(active && !node.archived))
    }
  }, [dispatch, active, node.archived, node.key])

  const handleFormComplete = useCallback(() => {
    dispatch(toggleEditing(false))
  }, [dispatch])

  // scrolling
  useEffect(() => {
    if (!active) return
    executeScroll(ref, { offset: 100 })
  }, [active, node.key])

  // context menu
  const handleContextMenu = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    dispatch(setActiveNode(node.key))
    setContextOpen(!contextOpen)
  }, [dispatch, contextOpen, node.key])

  const handlePopupClose = useCallback(() => {
    setContextOpen(false)
  }, [])

  const handleScope = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    dispatch(setScopeKey(node.key))
    setContextOpen(false)
  }, [node.key, dispatch])

  const handleArchive = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    dispatch(archiveItem(node.key, { recursive: true }))
    setContextOpen(false)
  }, [node.key, dispatch])

  useEffect(() => {
    if (!active) setContextOpen(false)
  }, [active])

  if (node.archived && !showArchived && !active) return null

  return <>
    <Ref innerRef={ref}>
      <>
        <List.Item
          onContextMenu={handleContextMenu}
          onMouseEnter={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
          className={classnames([
            'trove-item',
            dropping && 'dropping',
            dropping && isDropChild && 'dropping-child',
            dragging && 'dragging',
            node.funnel !== undefined && 'funnel',
            editing && 'editing',
          ])}
          onClick={handleClick}
          active={active}
          disabled={disabled}
        >
          {!minimal && editing
            ? <ItemForm
              itemKey={node.key}
              level={level}
              onComplete={handleFormComplete}
            />
            : <TroveItemRow
              buttons={mouseOver && !dragging}
              minimal={minimal}
              // active={active}
              // basic={isIrrelevant}
              level={level}
              node={node}
              parent={parent}
              subCount={subCount}
            />
          }
        </List.Item>
        {!minimal && !node.folded ? <>
          <TroveList
            disabled={disabled}
            parent={node}
            level={level + 1}
          />
          {/* {isDebug && active ? <TroveItemAdd
            parent={node}
            level={level + 1}
          /> : undefined} */}
        </> : undefined}
      </>
    </Ref>
    {contextOpen ? <Popup
      position='top center'
      context={ref}
      onClose={handlePopupClose}
      open={contextOpen}
    >
      <Menu
        items={[
          {
            key: 'scope',
            content: <span>
              Scope
              &nbsp;({SHORTCUTS.SCOPE.keyMap[0]})
            </span>,
            icon: 'bullseye',
            onClick: handleScope
          },
          {
            key: 'archive',
            content: <span>
              {node.archived ? 'Unarchive' : 'Archive'}
              &nbsp;({SHORTCUTS.ARCHIVE.keyMap[0]})
            </span>,
            icon: 'archive',
            onClick: handleArchive
          },
        ]}
        secondary
        vertical
      />
    </Popup> : undefined}
  </>
})

export default TroveItem