import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, List, Placeholder, Segment, Message } from 'semantic-ui-react'
import { getSubCount, selectScopedTree, selectShowArchived } from './redux/selector/troveSelector'
import { addNodeToActive } from './redux/slice/troveSlice'
import TroveList from './TroveList'

const TroveListMain = React.memo(() => {
  const dispatch = useDispatch()
  const scopedTree = useSelector(selectScopedTree)
  const showArchived = useSelector(selectShowArchived)

  const subCount = useMemo(() =>
    getSubCount({ node: scopedTree, showArchived }),
    [scopedTree, showArchived])

  const handleAddTrove = useCallback(() => {
    dispatch(addNodeToActive())
  }, [dispatch])

  return !subCount
    ? subCount === undefined
      ? <Segment attached='bottom'>
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      </Segment>
      : <Message info attached='bottom'>
        <p>No items here.</p>
        <Button onClick={handleAddTrove} size='mini' primary>Create One</Button>
      </Message>
    : <Segment attached='bottom' style={{ padding: 0 }}>
      <List
        // size='large'
        divided
        relaxed
        selection
      >
        <TroveList parent={scopedTree} level={0} />
      </List>
    </Segment>
})

export default TroveListMain
