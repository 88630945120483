import React, { useCallback, useRef, useState, useMemo } from 'react'
import { useDrop, useDrag } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import PlanForm from './PlanForm'
import PlanItemListBasic from './PlanItemListBasic'
import PlanItemListCalendar from './PlanItemListCalendar'
import { RootState } from './redux/reducers'
import { selectActivePlanKey } from './redux/selector/planSelector'
import { setActivePlan, toggleArchivePlan, updatePlanSave, PlanUpdates } from './redux/slice/planSlice'
import { PlanType } from './redux/types/PlanType'
import { ItemDropResult } from './types/ItemDropResult'
import { PlanItemDragType } from './types/PlanItemDragType'
import { TroveItemDragType } from './types/TroveItemDragType'
import { PlanDragType } from './types/PlayDragType'
import { PlanningDropResult } from './types/PlanningDropResult'
import moment from 'moment'

type Props = {
  plan: PlanType
  calendar?: boolean
}

const Plan = ({ calendar, plan }: Props) => {
  const dispatch = useDispatch()
  const active = useSelector((state: RootState) =>
    selectActivePlanKey(state) === plan.key)
  const [modal, showModal] = useState<'editPlan'>()
  const ref = useRef<HTMLElement>(null)

  const dragItem: PlanDragType = useMemo(() => ({
    type: 'PLAN',
    key: plan.key,
  }), [plan.key])

  const [{ dragging }, drag] = useDrag({
    item: dragItem,
    canDrag: () => !!calendar,
    end: (item: PlanDragType | undefined, monitor) => {
      if (!item) return
      const dropResult: PlanningDropResult | undefined = monitor.getDropResult()
      if (!dropResult) return
      const updates: PlanUpdates = {
        // startDate: dropResult.startDate,
        // endDate: dropResult.endDate,
        startDate: dropResult.startDate ? moment(dropResult.startDate).format('YYYY-MM-DD') : undefined,
        endDate: dropResult.endDate ? moment(dropResult.endDate).format('YYYY-MM-DD') : undefined,
      }
      dispatch(updatePlanSave({
        key: item.key,
        updates,
        attributes: ['startDate', 'endDate']
      }))
    },
    collect: monitor => ({
      dragging: !!monitor.isDragging(),
    }),
  })

  const [{ dropping }, drop] = useDrop({
    accept: ['PLAN_ITEM', 'TROVE_ITEM'],
    canDrop: (_item, monitor) => {
      return monitor.getItem().planKey !== plan.key
    },
    drop: (item: TroveItemDragType | PlanItemDragType): ItemDropResult => ({
      fromPlanKey: item.type === 'PLAN_ITEM' ? item.planKey : undefined,
      toPlanKey: plan.key,
      area: 'plan',
    }),
    collect: monitor => ({
      dropping: !!monitor.isOver() && monitor.canDrop(),
    })
  })

  drag(drop(ref))

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(setActivePlan({ key: plan.key }))
  }, [dispatch, plan.key])

  const handleArchive = useCallback((event?: React.MouseEvent) => {
    event?.stopPropagation()
    dispatch(toggleArchivePlan(plan.key))
  }, [dispatch, plan.key])

  const handleEdit = useCallback((event?: React.MouseEvent) =>
    event?.stopPropagation && showModal('editPlan'),
    []
  )

  const count = useMemo(() =>
    plan.items.filter(i => !i.archivedAt && !i.completedAt).length,
    [plan.items]
  )

  const doneCount = useMemo(() =>
    plan.items.filter(i => i.completedAt).length,
    [plan.items]
  )

  return <>
    {calendar
      ? <PlanItemListCalendar
        innerRef={ref}
        onClick={handleClick}
        onArchive={handleArchive}
        onEdit={handleEdit}
        plan={plan}
        dropping={dropping}
        dragging={dragging}
        active={active}
        count={count}
        doneCount={doneCount}
      />
      : <PlanItemListBasic
        innerRef={ref}
        onClick={handleClick}
        onArchive={handleArchive}
        onEdit={handleEdit}
        plan={plan}
        dropping={dropping}
        active={active}
        count={count}
        doneCount={doneCount}
      />}
    <PlanForm
      plan={plan}
      edit
      open={modal === 'editPlan'}
      onClose={() => showModal(undefined)}
    />

  </>

}

export default Plan
