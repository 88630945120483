import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Header, Icon, Label, Message, Ref, Segment } from 'semantic-ui-react'
import { momentCalendarFormatDate } from './data'
import PlanButtons from './PlanButtons'
import PlanItemList from './PlanItemList'
import { selectShowItemsArchived } from './redux/selector/planSelector'
import { toggleShowArchived } from './redux/slice/planSlice'
import { PlanType } from './redux/types/PlanType'
import { rangeOverlaps } from './util'
import Wrap from './Wrap'
import useInterval from './useInterval'

const moment = extendMoment(Moment);

type Props = {
  plan: PlanType
  dropping: boolean
  active?: boolean
  onClick: (event: React.MouseEvent) => void
  onArchive: (event?: React.MouseEvent) => void
  onEdit: (event?: React.MouseEvent) => void
  innerRef: React.RefObject<HTMLElement>
  count?: number
  doneCount?: number
}

const PlanItemListBasic = ({
  plan, dropping, onArchive, onEdit, active, onClick, innerRef, count
}: Props) => {
  const dispatch = useDispatch()
  const showArchived = useSelector(selectShowItemsArchived)
  const timestamp = useInterval()

  const archived = !!plan.archivedAt
  const completed = !!plan.completedAt
  const done = archived || completed

  // const handleFreshStart = useCallback((name: string) => {
  //   dispatch(addPlan({ name, from: plan.key }))
  //   showModal(undefined)
  // }, [dispatch, plan.key])

  const handleToggleShowArchived = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(toggleShowArchived({ items: true }))
  }, [dispatch])

  // const count = useMemo(() =>
  //   plan.items.filter(i => !i.archivedAt && !i.completedAt).length,
  //   [plan.items]
  // )

  const current = useMemo(() => {
    if (!plan.startDate || !plan.endDate) return false
    const today = moment(timestamp).format('YYYY-MM-DD')
    return rangeOverlaps(
      moment(today).toDate(),
      moment(today).add(1, 'day').toDate(),
      plan.startDate,
      plan.endDate,
      'overlaps')
  }, [plan.endDate, plan.startDate, timestamp])

  const countCompleted = useMemo(() =>
    plan.items.filter(i => i.archivedAt || i.completedAt).length,
    [plan.items]
  )

  const calendarColor = current ? done ? 'green' : 'orange' : undefined

  return <Ref innerRef={innerRef}>
    <Segment
      onClick={onClick}
      color={calendarColor}
      raised={active || dropping}
      secondary={!active && !dropping}>
      <Header
        color={done ? completed ? 'green' : 'grey' : undefined}
      >
        {active ? <>
          {countCompleted ? <Button.Group
            size='tiny'
            color='green'
            compact
            floated='right'
          >
            <Button
              style={{ marginLeft: '0.3em' }}
              size='tiny'
              active={showArchived}
              compact
              label={{
                color: 'green',
                content: countCompleted,
              }}
              labelPosition='right'
              icon={showArchived ? 'eye' : 'eye slash'}
              title='Show/Hide Checked'
              onClick={handleToggleShowArchived}
            />
          </Button.Group> : undefined}
          <PlanButtons
            items={['edit', 'archive', 'close']}
            onArchive={onArchive}
            onEdit={onEdit}
            plan={plan}
            buttonGroupProps={{
              size: 'tiny',
              basic: true,
              compact: true,
              floated: 'right',
            }}
          />
        </> : undefined}
        <Wrap element='s' active={done}>
          {done ? <Icon name='check' /> : undefined}
          {plan.name}
        </Wrap>
        {count ? <Label
          content={count}
          size='tiny'
          // floating
          circular
        /> : undefined}
      </Header>
      {active && archived
        ? <Message size='small' info>
          <p>Checklist marked as completed</p>
          <Button
            size='tiny'
            compact
            color='blue'
            onClick={onArchive}
            content='Undo Complete'
          />
        </Message>
        : plan.startDate && plan.endDate
          ? <>
            <Divider />
            <div style={{ textAlign: 'center' }}>
              <Label basic color={calendarColor}>
                {moment(plan.startDate).calendar(null, momentCalendarFormatDate)}
                {plan.startDate !== plan.endDate ? <>
                  &nbsp;–&nbsp;
                {moment(plan.endDate).calendar(null, momentCalendarFormatDate)}
                </> : undefined}
              </Label>
            </div>
          </> : undefined
      }
      {active
        ? <PlanItemList
          plan={plan}
        /> : undefined
      }
    </Segment>
  </Ref>
}

export default PlanItemListBasic