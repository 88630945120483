import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, GridProps, Modal } from 'semantic-ui-react'
import PlanCalendarDay from './PlanCalendarDay'
import { showAnyArchived } from './redux/selector/planSelector'
import { setActivePlan, toggleShowArchived } from './redux/slice/planSlice'
import { toggleSideMenu } from './redux/slice/workspaceSlice'
import useInterval from './useInterval'

type Props = {
  open?: boolean
}

const PlanCalendar = ({ open }: Props) => {
  const dispatch = useDispatch()
  const [showWeek, setShowWeek] = useState(true)
  const [showNextWeek, setShowNextWeek] = useState(true)
  const [showUnscheduled, setShowUnscheduled] = useState(false)
  const [daySpan, setDaySpan] = useState(3)
  const [dayOffset, setDayOffset] = useState(0)
  const showArchived = useSelector(showAnyArchived)
  const timestamp = useInterval()

  const startDayString = useMemo(() =>
    moment(timestamp).startOf('week').add(dayOffset, 'days').format('YYYY-MM-DD'),
    [dayOffset, timestamp])

  const todayOffset = useMemo(() => moment(timestamp).diff(moment(timestamp).startOf('week'), 'days'), [timestamp])

  useEffect(() => {
    setDayOffset(todayOffset)
  }, [todayOffset])

  const handleToday = useCallback(() => {
    setDayOffset(daySpan === 7 ? 0 : todayOffset)
  }, [daySpan, todayOffset])

  const weekDates = useMemo(() =>
    new Array(daySpan).fill(undefined).map((_, index) => {
      return [
        moment(startDayString).add(index, 'days').toDate(),
        moment(startDayString).add(index + 1, 'day').toDate(),
      ]
    }), [daySpan, startDayString])

  const weekStartDate = moment(weekDates[0][0]).startOf('week').toDate()
  const weekEndDate = moment(weekDates[0][0]).endOf('week').toDate()
  const nextWeekStartDate = moment(weekStartDate).add(1, 'week').toDate()
  const nextWeekEndDate = moment(weekEndDate).add(1, 'week').toDate()

  const handleClose = useCallback(() => {
    dispatch(toggleSideMenu({}))
  }, [dispatch])

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(setActivePlan())
  }, [dispatch])

  const handleToggleShowArchived = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(toggleShowArchived({ plans: true, items: true, archived: !showArchived }))
  }, [dispatch, showArchived])

  const columns = useMemo(() => {
    return daySpan +
      (showWeek ? 1 : 0) +
      (showNextWeek ? 1 : 0) +
      (showUnscheduled ? 1 : 0)
  }, [daySpan, showNextWeek, showUnscheduled, showWeek])

  return <Modal
    style={{
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    }}
    open={open}
    onClick={handleClick}
    onClose={handleClose}
    size='fullscreen'>
    <Modal.Header>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={4}>
            {moment(weekDates[0][0]).format('MMMM YYYY')}
          </Grid.Column>
          <Grid.Column textAlign='center' width={8}>
            <Button.Group
              compact
              basic
              size='tiny'
            >
              <Button
                icon='chevron left'
                onClick={() => setDayOffset(dayOffset - 1)}
              />
              <Button
                active={daySpan === 1}
                content='1'
                onClick={() => setDaySpan(1)}
              />
              <Button
                active={daySpan === 3}
                content='3'
                onClick={() => setDaySpan(3)}
              />
              <Button
                active={daySpan === 7}
                content='7'
                onClick={() => setDaySpan(7)}
              />
              <Button
                icon='chevron right'
                onClick={() => setDayOffset(dayOffset + 1)}
              />
              <Button
                content='Today'
                onClick={handleToday}
              />
            </Button.Group>
          </Grid.Column>
          <Grid.Column textAlign='right' width={4}>
            <Button.Group
              compact
              basic
              size='tiny'
            >
              <Button
                // compact
                icon='close'
                content='Close'
                onClick={handleClose}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column textAlign='center'>
            <Button.Group
              compact
              basic
              size='tiny'
            >
              <Button
                active={showWeek}
                content='This Week'
                icon={showWeek ? 'eye' : 'eye slash'}
                onClick={() => setShowWeek(!showWeek)}
              />
              <Button
                active={showNextWeek}
                content='Next Week'
                icon={showNextWeek ? 'eye' : 'eye slash'}
                onClick={() => setShowNextWeek(!showNextWeek)}
              />
              <Button
                active={showUnscheduled}
                content='Unscheduled'
                icon={showUnscheduled ? 'eye' : 'eye slash'}
                onClick={() => setShowUnscheduled(!showUnscheduled)}
              />
              <Button
                active={showArchived}
                content='Completed'
                icon={showArchived ? 'eye' : 'eye slash'}
                onClick={handleToggleShowArchived}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal.Header>
    <Modal.Content style={{ flex: 1, clear: 'both' }}>
      <Grid verticalAlign='top' divided columns={columns as GridProps['columns']}>
        <Grid.Row>
          {showUnscheduled ? <PlanCalendarDay
            mode='unscheduled'
          /> : undefined}
          {showWeek ? <PlanCalendarDay
            startDate={weekStartDate}
            endDate={weekEndDate}
            mode='week'
          /> : undefined}
          {weekDates.map(([startDate, endDate], index) => {
            return <PlanCalendarDay
              key={index}
              startDate={startDate}
              endDate={endDate}
              mode='day'
            />
          })}
          {showNextWeek ? <PlanCalendarDay
            startDate={nextWeekStartDate}
            endDate={nextWeekEndDate}
            mode='week'
          /> : undefined}
        </Grid.Row>
      </Grid>
    </Modal.Content>
  </Modal>
}

export default PlanCalendar